import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import ModeSwitcher from 'components/Header/ModeSwitcher'

class UserDropdown extends React.Component {
  state = {
    showDropdown: false,
  }
  componentWillUnmount() {
    this.ref = null
  }

  showDropdown = () => {
    document.addEventListener('click', this.offClickHandler, true)
    this.setState({ showDropdown: !this.state.showDropdown })
  }
  offClickHandler = e => {
    if (this.ref && !this.ref.contains(e.target)) {
      this.setState({ showDropdown: false })
      document.removeEventListener('click', this.offClickHandler, true)
    }
  }
  logout = () => this.props.UserStore.logout()

  render() {
    const { showDropdown } = this.state
    const { fullName, t, onChangeLanguage, mode, modes } = this.props
    const { language } = this.props.UiCtrl

    return (
      <div
        onClick={this.showDropdown}
        ref={ref => {
          this.ref = ref
        }}
        className="nav-link"
      >
        <span className="dropdown-toggle text-primary">
          {modes.length > 1 && (
            <i
              className={classNames('fa', {
                'fa-desktop': mode === 'insurer',
                'fa-briefcase': mode !== 'insurer',
              })}
            />
          )}{' '}
          {fullName}
        </span>

        <div className="dropdown-menu" style={{ display: showDropdown ? 'block' : 'none' }}>
          <div className="dropdown-item">
            {t('header.navbar.userDropdown.languages')}:
            <span
              className={classNames('language-option', { active: language === 'fr-FR' })}
              onClick={onChangeLanguage.bind(this, 'fr-FR')}
            >
              FR
            </span>
            <span
              className={classNames('language-option', { active: language === 'en-US' })}
              onClick={onChangeLanguage.bind(this, 'en-US')}
            >
              EN
            </span>
            <span
              className={classNames('language-option', { active: language === 'nl-NL' })}
              onClick={onChangeLanguage.bind(this, 'nl-NL')}
            >
              NL
            </span>
            <span
              className={classNames('language-option', { active: language === 'de-DE' })}
              onClick={onChangeLanguage.bind(this, 'de-DE')}
            >
              DE
            </span>
          </div>

          <div className="dropdown-item parameters">
            <NavLink to="/parameters/generalInformation">
              {t('header.navbar.userDropdown.parameters')} <i className="fa fa-wrench" />
            </NavLink>
          </div>

          <ModeSwitcher />

          <div className="dropdown-divider" />
          <div className="dropdown-item logout" onClick={this.logout}>
            <div>
              {t('header.navbar.userDropdown.logout')} <i className="fa fa-sign-out" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('UiCtrl', 'UserStore')(withTranslation()(observer(UserDropdown)))
