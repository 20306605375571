import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import './Dashboard.css'
import PageLayout from 'components/PageLayout/PageLayout'
import { DashboardDisplayStyle } from 'stores/Common/view/DashboardCtrl'
import DashboardWidget from 'components/DashboardWidget/DashboardWidget'
import Loader from 'components/Loader/Loader'
import i18next from 'i18next'

const DashboardPage = ({
  DashboardCtrl: {
    loading,
    widgets,
    fetchDashboard,
    displayStyle,
    setProperty,
    retrieveNewClaimFiles,
    dashboardId,
  },
  UiCtrl: { isMobile },
}) => {
  useEffect(() => {
    fetchDashboard()
  }, [fetchDashboard])

  useEffect(() => {
    const timer = setInterval(() => {
      retrieveNewClaimFiles()
    }, 90000)

    return () => {
      clearInterval(timer)
    }
  })

  if (loading) return <Loader />

  if (isMobile && displayStyle === DashboardDisplayStyle.LIST_TABLE)
    setProperty('displayStyle', DashboardDisplayStyle.LIST_CARD)

  return (
    <div className="container dashboard-page">
      {(!widgets || widgets.length === 0) && (
        <div className={'card-mission text-center mt-2 p-5'}>
          <span>{i18next.t('dashboard.noWidgetsFound')}</span>
        </div>
      )}

      {widgets &&
        widgets.map(widgetId => (
          <div key={widgetId}>
            <DashboardWidget widgetId={widgetId} />
          </div>
        ))}
    </div>
  )
}

export default compose(PageLayout, inject('UiCtrl', 'DashboardCtrl'), observer)(DashboardPage)
