import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { baseUrl } from 'services/utils'
import './DashboardWidgetListTable.css'
import Filters from 'components/DashboardWidget/components/Filters'
import List from 'components/DashboardWidget/components/List'
import Pagination from 'components/DashboardWidget/components/Pagination'
import Header from 'components/DashboardWidget/components/Header'
import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'
import { DashboardDisplayStyle } from 'stores/Common/view/DashboardCtrl'
import { compose } from 'recompose'

const DashboardWidgetListTable = ({
  DashboardWidgetCtrl: {
    loading,
    data,
    configuration,
    resetMissionsToPrint,
    missionsToPrint,
    formatMissionsPrint,
  },
  DashboardCtrl: { displayStyle, setProperty },
  UserStore: { isExpert, token, isClaimManager },
  UiCtrl: { isMobile },
}) => {
  const { t } = useTranslation()

  const [isSetDefaultFilter, setIsSetDefaultFilter] = useState(false)

  useEffect(() => {
    resetMissionsToPrint()
  }, [resetMissionsToPrint])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSetDefaultFilter(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  if (!isSetDefaultFilter || loading) return <Loader />

  if (isMobile && displayStyle === DashboardDisplayStyle.LIST_TABLE)
    setProperty('displayStyle', DashboardDisplayStyle.LIST_CARD)

  return (
    <div className="container dashboard-page">
      <Header />
      <Filters />
      <List />
      <div className="d-flex d-flex justify-content-between">
        <div className="d-flex justify-content-center flex-grow-1">
          {configuration.enablePagination && <Pagination />}
        </div>
        {!!data.length && !isClaimManager && (
          <div className="d-flex align-items-center" disabled>
            {!!missionsToPrint.length ? (
              <div>
                <a
                  className="btn btn-primary btn-sm px-5k"
                  href={`${baseUrl()}/claimFiles/v1/${
                    isExpert ? 'claimFileAssessor/' : ''
                  }print-etiquette-pdf?${formatMissionsPrint}&access_token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common.downloadEtiquette')}
                </a>{' '}
                <a
                  className="btn btn-primary btn-sm px-5k"
                  href={`${baseUrl()}/claimFiles/v1/${
                    isExpert ? 'claimFileAssessor/' : ''
                  }print-pdf?${formatMissionsPrint}&access_token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common.downloadMission')}
                </a>
              </div>
            ) : (
              <div>
                <Button className="btn btn-primary btn-sm px-5k" disabled={true}>
                  {t('common.downloadEtiquette')}
                </Button>{' '}
                <Button className="btn btn-primary btn-sm px-5k" disabled={true}>
                  {t('common.downloadMission')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default compose(
  inject('DashboardWidgetCtrl', 'UiCtrl', 'DashboardCtrl', 'UserStore', 'CommonStore'),
  observer,
)(DashboardWidgetListTable)
