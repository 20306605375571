import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isNil, add } from 'ramda'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import PropertyEmbellishment from './PropertyEmbellishment'
import Furniture from './Furniture'
import RelatedCost from './RelatedCost'
import LeakSearch from 'pages/EasyEstimation/LeakSearch'
import SaveEstimation from 'components/SaveEstimation'
import { euro } from 'utils/currency'
import { furnituresPrice, relatedCostsPrice, calculTotalPackages } from 'utils/cart'
import AlertCompensatory from 'pages/ManagerClaim/pages/Instruction/Tables/AlertCompensatory'
import Tooltip from 'components/Tooltip/Tooltip'
import Button from 'components/Button/Button'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const EasyEstimation = ({
  store,
  CartStore: {
    loading,
    setProperty,
    propertyPackages,
    embellishmentPackages,
    furnitures,
    relatedCostPackages,
    leakPackages,
    handleKonamiCode,
    isAllSelfRepair,
    isAllCataloguePrice,
    isAllRen,
    changeAllPackageTo,
    travelRCPPackage,
    asp,
    emptyCart,
  },
  ManagerClaimStore: { isClaimClosed, cfmCallBackUrl, cfmCallingCallBack, cfmSendCallback },
  MissionStore: { isMissionClosed, cfaCallBackUrl, cfaCallingCallBack, cfaSendCallback },
  UserStore: { isClaimManager, hasContract },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed
  const callbackUrl = type === 'mission' ? cfaCallBackUrl : cfmCallBackUrl
  const callingCallBack = type === 'mission' ? cfaCallingCallBack : cfmCallingCallBack
  const sendCallback = type === 'mission' ? cfaSendCallback : cfmSendCallback

  useEffect(() => {
    window.scrollTo(0, 0)
    document.addEventListener('keydown', handleKonamiCode)
    return () => {
      PropertyEmbellishmentCtrl.resetCoveringForm()
      document.removeEventListener('keydown', handleKonamiCode)
    }
    // eslint-disable-next-line
  }, [])

  if (loading && !isNil(store)) {
    return (
      <CardLayout>
        <Loader />
      </CardLayout>
    )
  }

  const total =
    [...propertyPackages, ...embellishmentPackages].reduce(
      (acc, data) => add(acc, data.finalPrice),
      0,
    ) +
    furnituresPrice(furnitures) +
    relatedCostsPrice(relatedCostPackages, 'relatedCost') +
    calculTotalPackages({ packages: leakPackages, key: 'finalPriceCatalog', selfRepair: false }) +
    travelRCPPackage.immediate +
    travelRCPPackage.deffered

  const changeAsp = () => {
    if (!asp) {
      changeAllPackageTo('selfRepair', false)
      changeAllPackageTo('ren', false)
      setProperty('asp', true)
      emptyCart('furnitures')
    } else {
      setProperty('asp', false)
    }
  }

  return (
    <CardLayout>
      <div className="easy-estimation container pl-0 pr-0">
        <SaveEstimation className="btn btn-danger mb-3" />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <button
            className={classNames('btn btn-primary mb-3', { 'not-allowed': isClosed })}
            onClick={() => {
              setProperty('easyEstimation', false)
            }}
            disabled={isClosed}
          >
            {t('mission.easyEstimation.transformToCalculation')}
          </button>

          {!isClosed && (
            <div className="col-3 d-flex justify-content-around">
              <Tooltip
                className="tooltip-action-bar"
                text={t('mission.calculation.cart.allSelfRepair')}
              >
                <button
                  className={classNames('btn rounded-circle ', {
                    'btn-primary': isAllSelfRepair && !isAllRen,
                    'btn-outline-primary': !isAllSelfRepair || isAllRen,
                  })}
                  onClick={() => {
                    changeAllPackageTo('selfRepair', true)
                    setProperty('asp', false)
                  }}
                >
                  G
                </button>
              </Tooltip>

              <Tooltip
                className="tooltip-action-bar"
                text={t('mission.calculation.cart.allCataloguePrice')}
              >
                <button
                  className={classNames('btn rounded-circle ', {
                    'btn-primary': isAllCataloguePrice && !isAllRen,
                    'btn-outline-primary': !isAllCataloguePrice || isAllRen,
                  })}
                  onClick={() => {
                    changeAllPackageTo('selfRepair', false)
                    setProperty('asp', false)
                  }}
                >
                  P
                </button>
              </Tooltip>

              <Tooltip className="tooltip-action-bar" text={t('mission.calculation.cart.allRen')}>
                <button
                  className={classNames('btn rounded-circle ', {
                    'btn-primary': isAllRen,
                    'btn-outline-primary': !isAllRen,
                  })}
                  onClick={() => {
                    changeAllPackageTo('ren', true)
                    setProperty('asp', false)
                  }}
                >
                  R
                </button>
              </Tooltip>

              {isClaimManager && hasContract('ASP') && (
                <Tooltip className="tooltip-action-bar" text={t('mission.calculation.cart.asp')}>
                  {!asp && furnitures.length > 0 && (
                    <ButtonWithConfirmation
                      className={classNames('btn rounded-circle ', {
                        'btn-danger': asp,
                        'btn-outline-danger': !asp,
                      })}
                      onClick={changeAsp}
                      confirmText={t('mission.calculation.cart.changeAsp')}
                      text="A"
                    />
                  )}

                  {(asp || furnitures.length === 0) && (
                    <button
                      className={classNames('btn rounded-circle ', {
                        'btn-danger': asp,
                        'btn-outline-danger': !asp,
                      })}
                      onClick={changeAsp}
                    >
                      A
                    </button>
                  )}
                </Tooltip>
              )}
            </div>
          )}

          <AlertCompensatory />
          <div className="d-flex flex-column align-items-end">
            <div className="font-weight-bold">
              {t('mission.calculation.cart.totalDamages')} : {euro(total)}
            </div>
          </div>
        </div>

        <PropertyEmbellishment store={store} className="mb-4" />

        {!asp && <Furniture className="mb-4" />}

        <RelatedCost />

        {store.isWaterDamage && <LeakSearch className="mt-4" />}

        {callbackUrl && (
          <Button
            className="btn btn-primary pull-right"
            loading={callingCallBack}
            onClick={() => sendCallback()}
          >
            Envoyer le chiffrage
          </Button>
        )}
      </div>
    </CardLayout>
  )
}

EasyEstimation.defaultProps = {
  store: null,
}

EasyEstimation.propTypes = {
  store: PropTypes.object.isRequired,
}

export default inject(
  'UserStore',
  'CartStore',
  'MissionStore',
  'ManagerClaimStore',
)(observer(EasyEstimation))
