import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

import useDebounce from 'hooks/useDebounce'
import DashboardWidgetCtrl, {
  DashboardWidgetFilterKeyConditions,
  DashboardWidgetFilterTypeConditions,
} from 'stores/Common/view/DashboardWidgetCtrl'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'

const SearchInput = ({ DashboardWidgetCtrl: { currentFilter }, t }) => {
  const initialValue = ''
  const [initializedState, setInitializedState] = useState(false)
  const [searchState, setSearchState] = useState(initialValue)
  const [debouncedSearch, setDebouncedSearch] = useDebounce(initialValue, 500)

  const onChange = e => {
    e.preventDefault()
    const value = e.target.value
    if (value !== undefined) {
      setInitializedState(true)
      setSearchState(value)
    }
  }

  useEffect(() => {
    if (
      currentFilter.searchCondition !== undefined &&
      currentFilter.searchCondition.value !== null &&
      currentFilter.searchCondition.value !== undefined
    ) {
      setSearchState(currentFilter.searchCondition.value)
    }
  }, [currentFilter.searchCondition])

  useEffect(() => {
    if (searchState !== undefined) {
      setDebouncedSearch(searchState)
    }
    // eslint-disable-next-line
  }, [searchState])

  useEffect(() => {
    let searchCondition
    if (debouncedSearch === initialValue && initializedState) {
      searchCondition = new DashboardWidgetFilterCondition({
        key: DashboardWidgetFilterKeyConditions.KEY_SEARCH,
        value: null,
        type: DashboardWidgetFilterTypeConditions.STRING,
      })
    }
    if (debouncedSearch !== initialValue) {
      searchCondition = new DashboardWidgetFilterCondition({
        key: DashboardWidgetFilterKeyConditions.KEY_SEARCH,
        value: debouncedSearch,
        type: DashboardWidgetFilterTypeConditions.STRING,
      })
    }
    if (searchCondition) {
      DashboardWidgetCtrl.addCurrentFilterCondition(searchCondition)
    }
    // eslint-disable-next-line
  }, [debouncedSearch])

  return (
    <div className="input-group">
      <input
        type="text"
        name="search-dashboard"
        className="form-control"
        placeholder={t('common.search')}
        value={searchState}
        onChange={onChange}
        maxLength="64"
        autoComplete="off"
      />
    </div>
  )
}

export default compose(inject('DashboardWidgetCtrl'), withTranslation(), observer)(SearchInput)
