import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { isNil } from 'ramda'

import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'
import EmbeddedFormFields from './EmbeddedFormFields'

const EmbeddedCollection = ({
  collectionData: {
    fetchingCollectionModel,
    name,
    collection,
    add,
    remove,
    reachCollectionLimit,
    maxLength,
    required,
  },
  t,
}) => {
  if (fetchingCollectionModel) {
    return (
      <div className="border p-2">
        <Loader />
      </div>
    )
  }

  return (
    <div className="">
      <div className="d-flex justify-content-start align-items-center mb-3">
        <span className="mr-1">{t(`mission.dynform.embeddedCollection.${name}`)} </span>
        {required && <span className="text-danger font-weight-bold mr-1">*</span>}

        {!isNil(maxLength) && (
          <span className="mr-1">
            ({collection.length}/{maxLength})
          </span>
        )}

        <Button
          className="btn btn-primary rounded-circle"
          onClick={add}
          disabled={reachCollectionLimit}
        >
          <i className="fa fa-plus" />
        </Button>
      </div>
      {collection.map(embedded => {
        return (
          <div
            key={`embedded-${embedded.name}-${embedded.id}`}
            className="d-flex justify-content-between align-items-center mb-3"
          >
            <EmbeddedFormFields inputs={embedded.inputs} />

            <Button
              className="btn btn-primary rounded-circle"
              onClick={remove.bind(this, embedded)}
            >
              <i className="fa fa-minus" />
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default compose(withTranslation(), observer)(EmbeddedCollection)
