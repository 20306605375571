export const MATMUT_THEME = ['demo-expertis', 'expertis']
export const CARDIF_THEME = ['demo-cardif', 'cardif']
export const BALOISE_THEME = ['demo-baloise', 'baloise']
export const WAN_LENGTH = 13
export const MISSION_TYPE_ID = 'MISS'
export const MISSION_WAIT_TYPE_ID = 'WAIT'
export const MISSION_REMINDER_TYPE_ID = 'REMI'
export const GOOGLE_MAP_API_KEY = Object.freeze({
  key: 'AIzaSyDj77pdwbZsCkwHGjbJU-FytossrJ1DOCg',
})
