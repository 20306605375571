import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import Loader from 'components/Loader/Loader'
import { DashboardDisplayStyle } from 'stores/Common/view/DashboardCtrl'
import DashboardWidgetListCard from './widgets/DashboardWidgetListCard/DashboardWidgetListCard'
import DashboardWidgetListTable from './widgets/DashboardWidgetListTable/DashboardWidgetListTable'

const DashboardWidget = ({
  DashboardCtrl: { displayStyle },
  DashboardWidgetCtrl: { loading, getDashboardWidget },
  widgetId,
}) => {
  useEffect(() => {
    if (widgetId) {
      getDashboardWidget(widgetId, [])
    }
  }, [widgetId, getDashboardWidget])

  if (loading === true) {
    return <Loader />
  }

  if (displayStyle === DashboardDisplayStyle.LIST_CARD) {
    return <DashboardWidgetListCard />
  }

  if (displayStyle === DashboardDisplayStyle.LIST_TABLE) {
    return <DashboardWidgetListTable />
  }

  return null
}

export default compose(inject('DashboardWidgetCtrl', 'DashboardCtrl'), observer)(DashboardWidget)
