import request from 'services/httpRequest'

// claim_file.claim_file_assessor.retrieve-documents
export const retrieveRemoteDocuments = async wan => {
  const data = {
    data: {
      type: 'retrieveDocuments',
      attributes: {
        cfaWan: wan
      }
    }
  }
  try {
    return await request({
      method: 'post',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/remote-assessment/retrieve-documents`,
      data
    })
  } catch (error) {
    console.warn(error)
  }
}

export const sendRemoteAssessmentInvitation = async (wan, phoneNumber, email, locale) => {
  const data = {
    data: {
      type: 'remoteAssessment',
      attributes: {
        phoneNumber: phoneNumber,
        email: email,
        locale: locale,
      },
    },
  }

  try {
    return await request({
      method: 'post',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/remote-assessment/send-invitation`,
      data,
    })
  } catch (error) {
    console.warn(error)
  }
}

export const remoteAssessment = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/remote-assessment/`,
    })
    return res
  } catch (error) {
    console.warn(error)
  }
}
