import React from 'react'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'
import { runInAction } from 'mobx'

import ParametersDesktop from './SideNavDesktop'
import ParametersMobile from './SideNavMobile'

class SideNavParameters extends React.Component {
  customerConfigKey = 'ParametersMenuOptions'

  defaultMenuValues = {
    Settings: [
      'GeneralInformation',
      'PartiesInvolved',
      'UserManagement',
      'ShiftManagement',
      'ExternalExpertiseFirm',
    ],
    Network: ['NetworkManagement'],
    Application: ['ApplicationVersion'],
    Help: ['DownloadTeamViewer'],
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      values: this.defaultMenuValues,
    }
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      this.setState({ loading: true })
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, key)

      runInAction(() => {
        const newValues = CustomerConfigStore.getValue(key)
        if (newValues !== undefined) this.setState({ values: newValues, loading: false })
      })
    } catch (err) {
      this.setState({ values: this.defaultMenuValues, loading: false })
    }
  }

  componentDidMount() {
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }

  render() {
    const sideNavMobile = this.props.sideNavMobile
    const userStore = [
      this.props.UserStore.isAllowedToPartyInvolvedDB,
      this.props.UserStore.isAllowedToUserManagement,
      this.props.UserStore.isAllowedToTourConfiguration,
      this.props.UserStore.isAllowedToImpersonate,
      this.props.UserStore.isAllowedToCoverageExecutionTime,
    ]

    const shouldShowExternalExpertiseFirm = this.props.UserStore.customer.theme === 'matmut'
    const menuValues = shouldShowExternalExpertiseFirm
      ? this.state.values
      : {
          ...this.state.values,
          Settings: this.state.values.Settings.filter(item => item !== 'ExternalExpertiseFirm'),
        }

    return sideNavMobile ? (
      <ParametersMobile menu={menuValues} userStore={userStore} />
    ) : (
      <ParametersDesktop menu={menuValues} userStore={userStore} />
    )
  }
}

export default compose(inject('UserStore'), observer)(SideNavParameters)
