import { observable, action, computed, decorate } from 'mobx'
import i18next from 'i18next'

import { uid } from 'utils'
import {
  DashboardWidgetFilterKeyConditions,
  DashboardWidgetFilterType,
  DashboardWidgetSortingKeyConditions,
} from '../view/DashboardWidgetCtrl'

import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import DashboardWidgetSortingCondition from 'stores/Common/domain/DashboardWidgetSortingCondition'

class DashboardWidgetFilter {
  id = uid(10)
  name = 'allMissions'

  filterConditions = []
  sortingConditions = []
  type = DashboardWidgetFilterType.TYPE_DEFAULT

  constructor(data = null) {
    if (data) {
      this.id = data.id !== null && data.id !== undefined ? data.id : uid(10)
      this.name = data.name
      this.type = data.type

      if (data.filterConditions && data.filterConditions.length > 0) {
        this.filterConditions = data.filterConditions.map(filterCondition => {
          return new DashboardWidgetFilterCondition(filterCondition)
        })
      }

      if (data.sortingConditions && data.sortingConditions.length > 0) {
        this.sortingConditions = data.sortingConditions.map(sortingCondition => {
          return new DashboardWidgetSortingCondition(sortingCondition)
        })
      }

      this.sortingConditions = data.sortingConditions
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  get nameDisplayed() {
    if (this.isCustomFilter) {
      return this.name
    }
    if (this.isDefaultFilter) {
      if (this.name !== null) {
        return i18next.t(`dashboard.filter.${this.name}`)
      }
    }
    return null
  }

  getFilterConditionValueFromKey = conditionKey => {
    if (this.hasFilterConditions === false) {
      return null
    }

    const condition = this.getFilterConditionByKey(conditionKey)
    if (condition !== undefined && condition !== null) {
      return condition.value
    }
    return null
  }

  getSortingConditionValueFromKey = conditionKey => {
    if (this.hasSortingConditions === false) {
      return null
    }

    const condition = this.getSortingConditionByKey(conditionKey)
    if (condition !== undefined && condition !== null) {
      return condition.value
    }
    return null
  }

  get search() {
    return this.getFilterConditionValueFromKey(DashboardWidgetFilterKeyConditions.KEY_SEARCH)
  }

  get status() {
    return this.getFilterConditionValueFromKey(DashboardWidgetFilterKeyConditions.KEY_STATUS)
  }

  get assigneeUserId() {
    return this.getFilterConditionValueFromKey(
      DashboardWidgetFilterKeyConditions.KEY_ASSIGNEE_USER_ID,
    )
  }

  get missionType() {
    return this.getFilterConditionValueFromKey(DashboardWidgetFilterKeyConditions.KEY_MISSION_TYPE)
  }

  get sortedBy() {
    return this.getSortingConditionValueFromKey(DashboardWidgetSortingKeyConditions.KEY_DEFAULT)
  }

  get hasFilterConditions() {
    return this.filterConditions !== null && this.filterConditions !== undefined
  }

  get hasSortingConditions() {
    return this.sortingConditions !== null && this.sortingConditions !== undefined
  }

  get hasStatusFilterCondition() {
    return this.hasFilterCondition(DashboardWidgetFilterKeyConditions.KEY_STATUS)
  }

  get hasSearchFilterCondition() {
    return this.hasFilterCondition(DashboardWidgetFilterKeyConditions.KEY_SEARCH)
  }

  get hasMissionTypeFilterCondition() {
    return this.hasFilterCondition(DashboardWidgetFilterKeyConditions.KEY_MISSION_TYPE)
  }

  get hasAssigneeUserIdFilterCondition() {
    return this.hasFilterCondition(DashboardWidgetFilterKeyConditions.KEY_ASSIGNEE_USER_ID)
  }

  get hasDefaultSortingCondition() {
    return this.hasSortingCondition(DashboardWidgetSortingKeyConditions.KEY_DEFAULT)
  }

  searchCondition() {
    if (this.hasSearchFilterCondition) {
      return this.getFilterConditionByKey(DashboardWidgetFilterKeyConditions.KEY_SEARCH)
    }
    return null
  }

  statusCondition() {
    if (this.hasStatusFilterCondition) {
      return this.getFilterConditionByKey(DashboardWidgetFilterKeyConditions.KEY_STATUS)
    }
    return null
  }

  assigneeUserIdCondition() {
    if (this.hasAssigneeUserIdFilterCondition) {
      return this.getFilterConditionByKey(DashboardWidgetFilterKeyConditions.KEY_ASSIGNEE_USER_ID)
    }
    return null
  }

  missionTypeCondition() {
    if (this.hasMissionTypeFilterCondition) {
      return this.getFilterConditionByKey(DashboardWidgetFilterKeyConditions.KEY_MISSION_TYPE)
    }
    return null
  }

  sortedByCondition() {
    //Only one sorting condition for now...
    if (this.sortingConditions.length > 0) {
      return this.sortingConditions[0]
    }
    return null
  }

  get isDefaultFilter() {
    return this.type === DashboardWidgetFilterType.TYPE_DEFAULT
  }

  get isCustomFilter() {
    return this.type === DashboardWidgetFilterType.TYPE_CUSTOM
  }

  hasFilterCondition = conditionKey => {
    return this.filterConditions.map(condition => {
      return condition.key === conditionKey
    })
  }

  hasSortingCondition = conditionKey => {
    return this.sortingConditions.map(condition => {
      return condition.key === conditionKey
    })
  }

  getFilterConditionByKey = conditionKey => {
    return this.filterConditions.find(condition => condition.key === conditionKey)
  }

  getSortingConditionByKey = conditionKey => {
    return this.sortingConditions.find(condition => condition.key === conditionKey)
  }

  addFilterCondition = condition => {
    if (this.hasFilterCondition(condition.key)) {
      let existingCondition = this.getFilterConditionByKey(condition.key)
      if (existingCondition) {
        existingCondition.value = condition.value
        existingCondition.type = condition.type
        existingCondition.name = condition.name
        return
      }
    }

    this.filterConditions.push(condition)
  }

  removeFilterCondition = conditionKey => {
    if (this.hasFilterCondition(conditionKey) === false) {
      return
    }
    this.filterConditions = this.filterConditions.filter(condition => {
      return condition.key !== conditionKey
    })
  }

  addSortingCondition = condition => {
    //Only one sorting condition by now...
    if (this.sortingConditions.length > 0) {
      this.sortingConditions[0] = condition
      return
    }

    this.sortingConditions.push(condition)
  }

  removeSortingCondition = conditionKey => {
    //Only one sorting condition for now...
    this.sortingConditions = []
  }
}

const DecoratedDashboardFilter = decorate(DashboardWidgetFilter, {
  name: observable,
  filterConditions: observable,
  sortingConditions: observable,
  type: observable,

  nameDisplayed: computed,
  assigneeUserId: computed,
  missionType: computed,
  search: computed,
  status: computed,

  isDefaultFilter: computed,
  isCustomFilter: computed,

  hasStatusFilterCondition: computed,
  hasSearchFilterCondition: computed,
  hasMissionTypeFilterCondition: computed,
  hasAssigneeUserIdFilterCondition: computed,
  hasDefaultSortingCondition: computed,

  searchCondition: action,
  statusCondition: action,
  assigneeUserIdCondition: action,
  missionTypeCondition: action,

  sortedByCondition: action,

  setProperty: action,
  hasFilterCondition: action,
  getFilterConditionByKey: action,
  getFilterConditionValueFromKey: action,

  addFilterCondition: action,
  addSortingCondition: action,
  removeFilterCondition: action,
  removeSortingCondition: action,
})

export default DecoratedDashboardFilter
