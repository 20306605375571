import React, { useState } from 'react'
import '../../components/ExternalExpertiseFirm.scss'

const TableRow = ({ tbodyData, handleDeleteFirm, handleViewFirm, handleEditFirm, filterOn, t }) => {
  const [filter, setFilter] = useState({
    customer: '',
    firmName: '',
    id: '',
    country: '',
    speciality: '',
    darvacode: '',
    irdcode: '',
    gtacode: '',
  })

  const handleFilterChange = e => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    })
  }

  const filteredData = tbodyData.filter(firm => {
    return (
      firm.customer.toLowerCase().includes(filter.customer.toLowerCase()) &&
      firm.firmName.toLowerCase().includes(filter.firmName.toLowerCase()) &&
      firm.country.toLowerCase().includes(filter.country.toLowerCase()) &&
      firm.speciality.toLowerCase().includes(filter.speciality.toLowerCase()) &&
      firm.darvaCode.toLowerCase().includes(filter.darvacode.toLowerCase()) &&
      firm.collaboratorCode.toLowerCase().includes(filter.irdcode.toLowerCase()) &&
      firm.entity.toLowerCase().includes(filter.gtacode.toLowerCase())
    )
  })

  return (
    <>
      <tr>
        {filterOn ? (
          <>
            <th>
              <input
                type="search"
                name="customer"
                placeholder={t('externalExpertiseFirm.table.customer')}
                value={filter.customer}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="firmName"
                placeholder={t('externalExpertiseFirm.table.firmName')}
                value={filter.firmName}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="id"
                placeholder={t('externalExpertiseFirm.table.id')}
                value={filter.id}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="country"
                placeholder={t('externalExpertiseFirm.table.country')}
                value={filter.country}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="speciality"
                placeholder={t('externalExpertiseFirm.table.specialty')}
                value={filter.speciality}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="darvacode"
                placeholder={t('externalExpertiseFirm.table.darvaCode')}
                value={filter.darvacode}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="irdcode"
                placeholder={t('externalExpertiseFirm.table.collaboratorCode')}
                value={filter.irdcode}
                onChange={handleFilterChange}
              />
            </th>
            <th>
              <input
                type="search"
                name="gtacode"
                placeholder={t('externalExpertiseFirm.table.entity')}
                value={filter.gtacode}
                onChange={handleFilterChange}
              />
            </th>
          </>
        ) : (
          ''
        )}
      </tr>
      {filteredData &&
        filteredData.map(firm => (
          <tr key={firm.id}>
            <td>{firm.customer}</td>
            <td className="long-field">{firm.firmName}</td>
            <td className="long-field">{firm.id}</td>
            <td>{firm.country}</td>
            <td>{firm.speciality}</td>
            <td>{firm.darvaCode}</td>
            <td>{firm.collaboratorCode}</td>
            <td>{firm.entity}</td>
            <td className="px-1">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn-sm btn btn-outline-primary mr-1 mt-1"
                  onClick={() => handleViewFirm(firm.id)}
                >
                  {t('externalExpertiseFirm.button.view')}
                </button>
                <button
                  type="button"
                  className="btn-sm btn btn-outline-success mr-1 mt-1"
                  onClick={() => handleEditFirm(firm.id)}
                >
                  {t('externalExpertiseFirm.button.edit')}
                </button>
                <button
                  type="button"
                  className="btn-sm btn btn-outline-danger mt-1"
                  onClick={() => handleDeleteFirm(firm.id)}
                >
                  {t('externalExpertiseFirm.button.delete')}
                </button>
              </div>
            </td>
          </tr>
        ))}
    </>
  )
}

export default TableRow
