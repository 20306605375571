import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import Button from 'components/Button/Button'
import FilterMissionStatus from './FilterMissionStatus'
import Input from 'components/Forms/Input'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import FieldFilter from './FieldFilter'
import DashboardWidgetCtrl, {
  DashboardWidgetFilterKeyConditions,
  DashboardWidgetFilterType,
  DashboardWidgetFilterTypeConditions,
  DashboardWidgetSortingValues,
  DashboardWidgetSortingKeyConditions,
} from 'stores/Common/view/DashboardWidgetCtrl'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import DashboardWidgetSortingCondition from 'stores/Common/domain/DashboardWidgetSortingCondition'

class FiltersForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterName: this.currentFilterNameDisplayed(),
      filterChanging: false, //when the user is typing the name of the new filter
      filterChanged: false, //when the user end typing but didn't press the save button
    }
  }

  initialState = () => {
    this.setState({
      filterChanging: false,
      filterChanged: false,
      filterName: this.currentFilterNameDisplayed(),
    })
  }

  currentFilterNameDisplayed() {
    const { currentFilter } = DashboardWidgetCtrl
    if (currentFilter.isCustomFilter) {
      return currentFilter.nameDisplayed
    }
    return ''
  }

  changeCurrentFilter = e => {
    this.setState({
      filterName: e.target.value,
      filterChanged: e.target.value !== this.state.filterName,
    })
  }

  changeAssigneeUserIdCondition = e => {
    const condition = new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_ASSIGNEE_USER_ID,
      value: e.target.value,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
    DashboardWidgetCtrl.addCurrentFilterCondition(condition)
  }

  changeMissionTypeCondition = e => {
    const condition = new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_MISSION_TYPE,
      value: e.target.value,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
    DashboardWidgetCtrl.addCurrentFilterCondition(condition)
  }

  changeSortCondition = e => {
    const conditionData = this.getConditionFromValue(e.target.value)
    const condition = new DashboardWidgetSortingCondition(conditionData)
    DashboardWidgetCtrl.addCurrentFilterSortingCondition(condition)
  }

  filterData() {
    return {
      name: this.state.filterName,
      filterConditions: DashboardWidgetCtrl.currentFilter.filterConditions,
      sortingConditions: DashboardWidgetCtrl.currentFilter.sortingConditions,
      type: DashboardWidgetFilterType.TYPE_CUSTOM,
    }
  }

  saveFilter = () => {
    const { dashboardId } = DashboardCtrl
    const filterData = this.filterData()
    DashboardWidgetCtrl.saveDashboardWidgetFilter(filterData, dashboardId)
    this.initialState()
  }

  deleteFilter = () => {
    const { dashboardId } = DashboardCtrl
    const filterData = this.filterData()
    DashboardWidgetCtrl.removeDashboardWidgetFilter(filterData, dashboardId)
    this.initialState()
  }

  onBlurInputName = e => {
    this.setState({
      filterChanging: false,
    })
  }

  onFocusInputName = e => {
    this.setState({
      filterChanging: true,
    })
  }

  generateSortSelectOptions = () => {
    const { t } = this.props
    const result = []

    DashboardWidgetCtrl.headersForSortList.forEach(header => {
      result.push({
        value: `${DashboardWidgetSortingValues.ASCENDING}_${header.type}_${header.field}`,
        label: `${header.label} ${t('dashboard.sorting.ascending')}`,
      })

      result.push({
        value: `${DashboardWidgetSortingValues.DESCENDING}_${header.type}_${header.field}`,
        label: `${header.label} ${t('dashboard.sorting.descending')}`,
      })
    })

    result.push({
      value: `${DashboardWidgetSortingValues.DESCENDING}_${DashboardWidgetFilterTypeConditions.DATE}_${DashboardWidgetSortingKeyConditions.KEY_DEFAULT}`,
      label: `${t('dashboard.sorting.default')} ${t('dashboard.sorting.descending')}`,
    })
    return result
  }

  getValueFromCondition = condition => {
    if (condition) {
      return `${condition.value}_${condition.type}_${condition.key}`
    }
  }

  getConditionFromValue = value => {
    const parts = value.split('_')
    if (parts.length === 3) {
      const [value, type, key] = parts
      return {
        key,
        type,
        value,
      }
    }
    return null
  }

  canDisplayDeleteButton = () => {
    const { canDeleteCurrentFilter } = DashboardWidgetCtrl
    return (
      canDeleteCurrentFilter &&
      this.state.filterChanging === false &&
      this.state.filterChanged === false
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.filterChanging && !this.state.filterChanged) {
      const updatedFilterName = this.currentFilterNameDisplayed()

      if (prevState.filterName !== updatedFilterName) {
        this.setState({ filterName: updatedFilterName })
      }
    }
  }

  render() {
    const {
      t,
      UserStore: {
        customer: { id },
        isExpert,
        hasRole,
      },
      CommonStore: { missionTypesForSelectInput },
    } = this.props
    const { displayFilterForm, currentFilter, headersForSortList } = DashboardWidgetCtrl
    const customersOptions = [
      {
        value: 'all',
        label: t('dashboard.filter.form.allAssignments'),
      },
      {
        value: id,
        label: t('dashboard.filter.form.onlyMyAssignments'),
      },
    ]

    if (displayFilterForm === false) return null

    return (
      <div className="row no-gutters mt-2">
        <div className="col-12">
          <div className="row small-gutters">
            {!hasRole('ROLE_INTERNAL_EXPERT') && (
              <div className="form-group col-12 col-md-6 col-lg-3">
                <label>{t('dashboard.filter.form.missionsAffectedToMe')} : </label>
                <ResponsiveSelect
                  options={customersOptions}
                  name="assigneeUserId"
                  onChange={this.changeAssigneeUserIdCondition}
                  value={currentFilter.assigneeUserId}
                />
              </div>
            )}
            {isExpert && (
              <div className="form-group col-12 col-md-6 col-lg-3">
                <label>{t('dashboard.filter.form.missionType')}</label>
                <ResponsiveSelect
                  name="missionType"
                  value={currentFilter.missionType}
                  onChange={this.changeMissionTypeCondition}
                  options={missionTypesForSelectInput}
                />
              </div>
            )}

            <div className="form-group col-12 col-md-6 col-lg-3">
              <label>{t('dashboard.filter.form.missionStatus')}</label>
              <FilterMissionStatus />
            </div>

            <div className="form-group col-12 col-md-6 col-lg-3">
              <label>{t('dashboard.filter.form.sortedBy')}</label>
              <ResponsiveSelect
                name="sortedBy"
                value={this.getValueFromCondition(currentFilter.sortedByCondition())}
                onChange={this.changeSortCondition}
                options={this.generateSortSelectOptions()}
              />
            </div>
          </div>

          {/* Filters for every header of the widget */}
          <div className="row small-gutters">
            {headersForSortList.map(header => (
              <FieldFilter header={header} key={header.id} />
            ))}
          </div>

          <div className="card-footer card-footer-inside-body">
            <div className="row small-gutters align-items-end">
              <div className="form-group col-12 col-md-6">
                <Label required>{t('dashboard.filter.form.save')}</Label>
                <Input
                  className="form-control mb-2"
                  name="name"
                  value={this.state.filterName}
                  placeholder={t('dashboard.filter.form.filterName')}
                  onChange={this.changeCurrentFilter}
                  onBlur={this.onBlurInputName}
                  onFocus={this.onFocusInputName}
                  maxLength={55}
                />
              </div>
              <div className="form-group col-12 col-md-6 text-right">
                {this.canDisplayDeleteButton() && (
                  <Button className="btn btn-danger mr-2 mb-2" onClick={this.deleteFilter}>
                    <i className="fa fa-trash-o" /> {t('common.delete')}
                  </Button>
                )}

                <Button
                  className="btn btn-primary mb-2"
                  onClick={this.saveFilter}
                  disabled={this.state.filterName.length === 0}
                >
                  <i className="fa fa-save" /> {t('common.submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('UserStore', 'CommonStore')(withTranslation()(observer(FiltersForm)))
