import React, { useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'

import DateFilter from './DateFilter'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import { DashboardWidgetFilterTypeConditions } from 'stores/Common/view/DashboardWidgetCtrl'
import useDebounce from 'hooks/useDebounce'

const DashboardWidgetHeaderTypes = {
  TYPE_DATE: 'date',
  TYPE_TEXT: 'string',
  TYPE_SELECT: 'select',
}

const FieldFilter = ({ DashboardWidgetCtrl: { currentFilter, setCurrentFilter }, header }) => {
  const initialValue = {
    id: '',
    label: '',
    field: '',
    class: '',
    disable: [],
    hidden: false,
    sortable: false,
    searchable: false,
    choosable: false,
    type: '',
    choices: [],
    translators: [],
  }
  const [currentHeader, setCurrentHeader] = useState(initialValue)
  const [debouncedText, setDebouncedText] = useDebounce(initialValue.field, 1000)
  const onChangeFilter = (header, e) => {
    updateFilterCondition(header, e.target.value)
  }

  const onChangeDebouncedFilter = (header, e) => {
    setHeaderValue(header, e.target.value)
    setCurrentHeader(header)

    if (e.target.value.length === 0) {
      removeFilterCondition(header)
      setDebouncedText(e.target.value)
      return
    }

    setDebouncedText(e.target.value)
  }

  const updateFilterCondition = (header, value) => {
    const fieldCondition = new DashboardWidgetFilterCondition({
      key: header.field,
      value: value,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
    currentFilter.addFilterCondition(fieldCondition)
    setCurrentFilter(currentFilter, false)
  }

  const removeFilterCondition = header => {
    currentFilter.removeFilterCondition(header.field)
    setCurrentFilter(currentFilter, false)
  }

  const hasChoices = header => {
    if (header.choices === null || header.choices === undefined) {
      return false
    }
    return header.choices.length > 0
  }

  const getHeaderType = header => {
    if (header.type === DashboardWidgetHeaderTypes.TYPE_DATE) {
      return DashboardWidgetHeaderTypes.TYPE_DATE
    }
    if (hasChoices(header)) {
      return DashboardWidgetHeaderTypes.TYPE_SELECT
    }
    return DashboardWidgetHeaderTypes.TYPE_TEXT
  }

  const setHeaderValue = (header, value) => {
    if (value === initialValue.field) {
      const existingFilterCondition = currentFilter.getFilterConditionByKey(header.field)
      if (existingFilterCondition !== null && existingFilterCondition !== undefined) {
        currentFilter.removeFilterCondition(existingFilterCondition.key)
      }
      return
    }

    const filterCondition = new DashboardWidgetFilterCondition({
      key: header.field,
      value: value,
      type: getHeaderType(header),
    })
    currentFilter.addFilterCondition(filterCondition)
  }

  const getHeaderValue = header => {
    const condition = currentFilter.getFilterConditionByKey(header.field)
    if (condition !== undefined && condition !== null) {
      if (condition.type === DashboardWidgetFilterTypeConditions.DATE) {
        return {
          key: condition.value,
        }
      }

      if (condition.type === DashboardWidgetFilterTypeConditions.DATE_RANGE) {
        return JSON.parse(condition.value)
      }

      return condition.value
    }
    return ''
  }

  useEffect(() => {
    if (debouncedText !== initialValue.field) {
      const fieldCondition = new DashboardWidgetFilterCondition({
        key: currentHeader.field,
        value: debouncedText,
        type: DashboardWidgetFilterTypeConditions.STRING,
      })
      currentFilter.addFilterCondition(fieldCondition)
      setCurrentFilter(currentFilter, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText])

  return (
    <div className="form-group col-12 col-md-6 col-lg-3 align-self-end">
      <label>{header.label}</label>

      {getHeaderType(header) === DashboardWidgetHeaderTypes.TYPE_DATE && (
        <DateFilter name={header.field} value={getHeaderValue(header)} />
      )}

      {getHeaderType(header) === DashboardWidgetHeaderTypes.TYPE_SELECT && (
        <ResponsiveSelect
          name={header.field}
          value={getHeaderValue(header)}
          onChange={onChangeFilter.bind(this, header)}
          options={header.choices}
          nullable={true}
        />
      )}

      {getHeaderType(header) === DashboardWidgetHeaderTypes.TYPE_TEXT && (
        <Input
          className="form-control"
          style={{ height: '38px' }}
          name={header.field}
          value={getHeaderValue(header)}
          onChange={onChangeDebouncedFilter.bind(this, header)}
        />
      )}
    </div>
  )
}

export default inject(
  'DashboardWidgetCtrl',
  'DashboardWidgetFilter',
  'DashboardWidgetFilterCondition',
)(observer(FieldFilter))
