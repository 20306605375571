import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { compose } from 'recompose'

import TableFields from 'components/Table/TableFields'
import { formatPhone, concatNames, isInspectionMission } from 'utils'
import CardLayout from 'components/CardLayout/CardLayout'
import Field from 'components/Field'
import FieldsBloc from 'components/FieldsBloc'
import { concatAddress, formatDate } from 'utils'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'

const Policy = ({
  t,
  UserStore: { isExpert },
  MissionStore,
  MissionStore: { loading: missionLoading },
  ClaimStore,
  ClaimStore: { claim: clm, loading: claimLoading },
}) => {
  if (missionLoading || claimLoading) return null

  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const store = isExpert ? MissionStore : ClaimStore
  const isIME = isExpert ? MissionStore.isIME : ClaimStore.isIME
  const dataSampleContact = [
    {
      key: t('mission.policy.insuredInformation.quality'),
      val: path(['insuredInformation', 'insured', 'qualifying'], claim) || '-',
    },
    {
      key: t('mission.contact.address'),
      val: concatAddress(path(['insuredInformation', 'insured', 'address'], claim)) || '-',
    },
    {
      key: t('mission.contact.phoneNumber'),
      val: formatPhone(path(['insuredInformation', 'insured', 'phoneNumber'], claim)) || '-',
    },
    {
      key: t('mission.contact.email'),
      val: path(['insuredInformation', 'insured', 'email'], claim) || '-',
    },
  ]
  return (
    <CardLayout className="policy">
      <Field
        name={t('mission.policy.insuranceCompany')}
        value={`${path(['contract', 'insurer', 'code'], claim) || ''} ${path(
          ['contract', 'insurer', 'companyName'],
          claim,
        ) || ''}`}
      />
      <Field
        name={t('mission.policy.characteristicContract.membershipNumber')}
        value={path(['contract', 'insurer', 'policyNumber'], claim)}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.policy.insured')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.policy.fullName')}
        rowValue={concatNames(
          path(['insuredInformation', 'insured', 'lastName'], claim),
          path(['insuredInformation', 'insured', 'firstName'], claim),
        )}
        rows={dataSampleContact}
      />
      <Field
        name={t('mission.policy.characteristicContract.subscriptionDate')}
        value={
          store.isIME
            ? formatDate(path(['contract', 'lastAmendmentDate'], claim))
            : formatDate(path(['contract', 'insurer', 'policyEffectDate'], claim)) ||
              formatDate(path(['insuredInformation', 'subscriptionDate'], claim))
        }
      />
      <Field
        name={t('mission.policy.characteristicContract.membershipNumber')}
        value={path(['insuredInformation', 'insuredReference'], claim)}
      />
      {isIME && (
        <FieldsBloc
          noTopDivider={true}
          fields={[
            {
              name: t('mission.policy.particularyInformation'),
              value: path(['claimInformation', 'thirdPartyInvolvedInformation'], claim),
            },
            {
              name: t('mission.policy.observation'),
              value: path(['claimInformation', 'comment'], claim),
            },
          ]}
        />
      )}
      {!isInspectionMission(claim) && (
        <FieldsBloc
          title={t('mission.policy.characteristicContract.title')}
          fields={[
            {
              name: t('mission.policy.characteristicContract.contractName'),
              value: path(['contract', 'typeOfContract', 'value'], claim)
                ? path(['contract', 'typeOfContract', 'value'], claim)
                : path(['contract', 'typeOfContractString'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.contractFormula'),
              value: path(['contract', 'formulaContract', 'value'], claim)
                ? path(['contract', 'formulaContract', 'value'], claim)
                : path(['contract', 'formulaContractString'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.isSocle'),
              value:
                claim.gta === '418'
                  ? path(['contract', 'isSocle'], claim)
                    ? t('common.yes')
                    : t('common.no')
                  : null,
            },
            {
              name: t('mission.policy.characteristicContract.regulationIndex'),
              value: path(['contract', 'regulationIndex'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.effectiveDateOfTheContract'),
              value: store.isIME
                ? formatDate(path(['contract', 'insurer', 'policyEffectDate'], claim))
                : formatDate(path(['contract', 'lastAmendmentDate'], claim)),
            },
            {
              name: t('mission.policy.characteristicContract.riskAddress'),
              value: path(['contract', 'addressOfRisk'], claim)
                ? concatAddress(path(['contract', 'addressOfRisk'], claim))
                : null,
            },
            {
              name: t('mission.policy.characteristicContract.contractualFranchise'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.realEstateCapital'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'property', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'property', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.capitalOfMovables'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'furniture', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'furniture', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.ofWhichPreciousObjects'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'precious', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeAmounts', 'precious', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.qualityOfTheInsured'),
              value: path(['contract', 'qualityOfInsured'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.weightedFranchise'),
              value: isIME
                ? null
                : path(
                    ['contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'amount'],
                    claim,
                  ) !== 0
                ? formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'currency'],
                      claim,
                    ),
                  )
                : t('common.noCap'),
            },
            {
              name: t('mission.policy.characteristicContract.propertyInsuredRisk'),
              value: isIME ? null : path(['contract', 'insuredPostRiskComment'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.feature'),
              value: path(['contract', 'riskDescription', 'particularity'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.monument'),
              value: path(['contract', 'riskDescription', 'historicalMonument', 'value'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.roomRental'),
              value:
                isIME || isInspectionMission(claim)
                  ? null
                  : path(['contract', 'riskDescription', 'roomRental'], claim) !== 0
                  ? path(['contract', 'riskDescription', 'roomRental'], claim)
                  : t('common.noCap'),
            },
            {
              name: t('mission.policy.characteristicContract.covert'),
              value:
                isIME || isInspectionMission(claim)
                  ? null
                  : path(['contract', 'riskDescription', 'covertHome'], claim)
                  ? t('common.yes')
                  : t('common.no'),
            },
            {
              name: t('mission.policy.characteristicContract.otherDeclaredActivity'),
              value:
                isIME || isInspectionMission(claim)
                  ? null
                  : path(['contract', 'riskDescription', 'otherDeclaredActivity'], claim)
                  ? t('common.yes')
                  : t('common.no'),
            },
          ]}
        />
      )}
      {isIME && (
        <Fragment>
          <FieldsBloc
            title={
              path(['contract', 'guarantee', 'guarantee', 'value'], claim) ||
              path(['contract', 'guarantee', 'guaranteeStringName'], claim)
            }
            noTopDivider={true}
            fields={[
              {
                name: t('mission.policy.characteristicContract.specialGuaranteeAppendixPrecision'),
                value: path(['claimInformation', 'specialGuaranteeAppendix'], claim),
              },
            ]}
          />
          <div className="row">
            <div className="col-6">
              <TableFields
                tableName={t('mission.policy.characteristicContract.guaranteeDeductible.title')}
                column="amount"
                headersPath="mission.policy.characteristicContract.guaranteeDeductible"
                data={path(['contract', 'guarantee', 'guaranteeDeductible'], claim)}
              />
            </div>
            <div className="col-6">
              <TableFields
                tableName={t('mission.policy.characteristicContract.guaranteeAmounts.title')}
                column="amount"
                headersPath="mission.policy.characteristicContract.guaranteeAmounts"
                data={path(['contract', 'guarantee', 'guaranteeAmounts'], claim)}
              />
            </div>
          </div>
        </Fragment>
      )}
      {isInspectionMission(claim) && (
        <FieldsBloc
          title={t('mission.policy.characteristicContract.title')}
          fields={[
            {
              name: t('mission.policy.branche'),
              value: path(['branche', 'value'], claim)
                ? path(['branche', 'value'], claim)
                : path(['matmutOutOfDarva', 'branche'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.contractName'),
              value: path(['contract', 'typeOfContract', 'value'], claim)
                ? path(['contract', 'typeOfContract', 'value'], claim)
                : path(['contract', 'typeOfContractString'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.contractFormula'),
              value: path(['contract', 'formulaContract', 'value'], claim)
                ? path(['contract', 'formulaContract', 'value'], claim)
                : path(['contract', 'formulaContractString'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.regulationIndex'),
              value: path(['contract', 'regulationIndex'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.effectiveDateOfTheContract'),
              value: formatDate(path(['contract', 'lastAmendmentDate'], claim)),
            },
            {
              name: t('mission.policy.characteristicContract.riskAddress'),
              value: path(['contract', 'addressOfRisk'], claim)
                ? concatAddress(path(['contract', 'addressOfRisk'], claim))
                : null,
            },
            {
              name: t('mission.policy.characteristicContract.qualityOfTheInsured'),
              value: path(['contract', 'qualityOfInsured'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.subscriptionDate'),
              value: formatDate(path(['contract', 'insurer', 'policyEffectDate'], claim)),
            },
          ]}
        />
      )}
      <FieldsBloc
        title={t('mission.policy.characteristicRisk.title')}
        fields={[
          {
            name: isIME
              ? t('mission.policy.characteristicRisk.typeOfBatiment')
              : t('mission.policy.characteristicRisk.typeOfDwelling'),
            value: path(['claimInformation', 'riskType', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.buildingYear'),
            value: path(['claimInformation', 'buildingYear', 'value'], claim),
          },
          {
            name: isIME
              ? t('mission.policy.characteristicRisk.insuredSquare')
              : t('mission.policy.characteristicRisk.squareArea'),
            value: path(['contract', 'riskDescription', 'squareArea'], claim),
            unit: 'm²',
          },
          {
            name: t('mission.policy.characteristicRisk.habitableArea'),
            value: path(['contract', 'riskDescription', 'primaryRiskSquareArea'], claim),
            unit: 'm²',
          },
          {
            name: t('mission.policy.characteristicRisk.nbMainRooms'),
            value: path(['contract', 'riskDescription', 'nbMainRooms'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.floorNumber'),
            value: path(['claimInformation', 'floor', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.homeBasedActivity'),
            value: path(['contract', 'riskDescription', 'homeBasedActivity', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.usage'),
            value: isIME ? path(['contract', 'riskDescription', 'riskUsage'], claim) : null,
          },
          {
            name: t('mission.claim.descriptionProtection'),
            value: isIME ? path(['claimInformation', 'descriptionProtection'], claim) : null,
          },
          {
            name: t('mission.policy.characteristicRisk.other'),
            value: isIME ? path(['contract', 'riskDescription', 'other'], claim) : null,
          },
          {
            name: t('mission.policy.characteristicRisk.informationContract'),
            value: isIME ? path(['contract', 'insuredPostRiskComment'], claim) : null,
          },
        ]}
      />
      {!isInspectionMission(claim) && (
        <FieldsBloc
          title={t('mission.policy.dependency.title')}
          fields={[
            {
              name: t('mission.policy.dependency.dependencyAddress'),
              value: path(['contract', 'hasSameAddressDependency'], claim) ? t('common.yes') : null,
            },
            {
              name: t('mission.policy.dependency.NumberOfBuilding'),
              value: path(['contract', 'dependency', 'number'], claim),
            },
            {
              name: t('mission.policy.dependency.surfaceDependencies'),
              value: path(['contract', 'dependency', 'surface'], claim),
            },
            {
              name: t('mission.policy.dependency.dependencyOtherAddress'),
              value: path(['contract', 'dependency', 'other_address'], claim)
                ? concatAddress(claim.contract.dependency.other_address)
                : null,
            },
            {
              name: t('mission.policy.dependency.addressDependency'),
              value: path(['contract', 'dependency', 'address'], claim)
                ? concatAddress(claim.contract.dependency.address)
                : null,
            },
            {
              name: t('mission.policy.dependency.feature'),
              value: path(['contract', 'dependency', 'feature'], claim),
            },
            {
              name: t('mission.policy.dependency.monument'),
              value: path(['contract', 'dependency', 'monument'], claim),
            },
            {
              name: t('mission.policy.dependency.dependencyAddress'),
              value: path(['contract', 'hasOtherAddressDependency'], claim)
                ? t('common.yes')
                : null,
            },
            {
              name: t('mission.policy.dependency.NumberOfBuilding'),
              value: path(['contract', 'otherAddressDependency', 'number'], claim),
            },
            {
              name: t('mission.policy.dependency.surfaceDependencies'),
              value: path(['contract', 'otherAddressDependency', 'surface'], claim),
            },
            {
              name: t('mission.policy.dependency.dependencyOtherAddress'),
              value: path(['contract', 'otherAddressDependency', 'other_address'], claim)
                ? concatAddress(claim.contract.dependency.other_address)
                : null,
            },
            {
              name: t('mission.policy.dependency.addressDependency'),
              value: path(['contract', 'otherAddressDependency', 'address'], claim)
                ? concatAddress(claim.contract.dependency.address)
                : null,
            },
            {
              name: t('mission.policy.dependency.feature'),
              value: path(['contract', 'otherAddressDependency', 'feature'], claim),
            },
            {
              name: t('mission.policy.dependency.monument'),
              value: path(['contract', 'otherAddressDependency', 'monument'], claim),
            },
          ]}
        />
      )}

      <FieldsBloc
        title={t('mission.policy.options.titleAmounts')}
        fields={[
          {
            name: t('mission.policy.dependency.guaranteeCelingForAllDependencies'),
            value: formatCurrency(
              path(['contract', 'guarantee', 'guaranteeAmounts', 'dependencies', 'amount'], claim),
              path(
                ['contract', 'guarantee', 'guaranteeAmounts', 'dependencies', 'currency'],
                claim,
              ),
            ),
          },
        ]}
      />

      <TableFields
        title={t('mission.policy.options.titleOptions')}
        column="amount"
        data={path(['contract', 'guarantee', 'guaranteeOptional'], claim)}
        classNames="mx-4"
      />
    </CardLayout>
  )
}

export default compose(
  inject('ClaimStore', 'MissionStore', 'UserStore'),
  withTranslation(),
  observer,
)(Policy)
