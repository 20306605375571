import { observable, action, computed, runInAction, decorate } from 'mobx'
import { check, watch } from 'is-offline'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'

import { getLocale, setDataLocal, getDataLocal } from 'stores/localStorage'
import { getStatus } from 'services/status'
import { environments } from 'services/environment'

class UiCtrl {
  language = 'fr-FR'
  theme = 'default'
  loading = true
  navbarOpen = null
  drawerOpen = false
  screenWindow = null
  windowWidth = null
  isOffLine = false
  displayDebugToolbar = false
  apiVersion = null
  showOnlyCard = false

  apiStatus = 200

  constructor() {
    this.language = getLocale() || 'fr-FR'
    this.showOnlyCard = getDataLocal('showOnlyCard') || false

    if (typeof window === 'object') {
      this.screenWindow = window
      this.windowWidth = this.screenWindow.innerWidth
      this.screenWindow.addEventListener('resize', this.handleWindowWidthChange)
      this.screenWindow.addEventListener('orientationchange', this.handleWindowWidthChange)
    }
    const isOffLine = action(bool => {
      this.isOffLine = bool
    })
    check().then(isOffLine)
    watch(isOffLine)
  }

  get hasDebugToolbar() {
    return process.env.REACT_APP_SOLERA_ENV !== environments.PRODUCTION
  }

  get deviceType() {
    return this.windowWidth < 992 ? 'mobile' : 'desktop'
  }

  get isMobile() {
    return this.windowWidth <= 576
  }

  get sideNavMobile() {
    return this.windowWidth < 992
  }

  get isLandscape() {
    return this.windowWidth >= 576
  }

  get country() {
    const country = this.language.split('-')
    return country[1]
  }

  get locale() {
    return this.language[0] + this.language[1]
  }

  get dateLocale() {
    const locale = {
      fr,
      en,
      nl,
    }
    return locale[this.locale]
  }

  handleWindowWidthChange = () => {
    this.windowWidth = this.screenWindow.innerWidth
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  checkApiStatus = async () => {
    this.loading = true
    try {
      const data = await getStatus()
      runInAction(() => {
        this.apiVersion = data.apiVersion
        this.apiStatus = data.status
      })
      return data.status
    } catch (error) {
      console.warn(error)
      return error
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  changeLanguage = lng => {
    this.language = lng
    setDataLocal('locale', lng)
  }

  toggleNavbar(navbarState) {
    this.navbarOpen = !navbarState
  }

  setNavbar(navbarState) {
    this.navbarOpen = navbarState
  }

  toggleSideNav(value) {
    this.drawerOpen = value
  }
}

const DecoratedUiCtrl = decorate(UiCtrl, {
  language: observable,
  theme: observable,
  loading: observable,
  navbarOpen: observable,
  drawerOpen: observable,
  screenWindow: observable,
  windowWidth: observable,
  isOffLine: observable,
  displayDebugToolbar: observable,
  apiStatus: observable,
  apiVersion: observable,
  showOnlyCard: observable,

  hasDebugToolbar: computed,
  deviceType: computed,
  isMobile: computed,
  sideNavMobile: computed,
  isLandscape: computed,
  country: computed,
  locale: computed,
  dateLocale: computed,

  handleWindowWidthChange: action,
  setProperty: action,
  checkApiStatus: action,
  changeLanguage: action,
  toggleNavbar: action,
  setNavbar: action,
  toggleSideNav: action.bound,
})

export default new DecoratedUiCtrl()
