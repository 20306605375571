import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { euro } from 'utils/currency'
import { formatArea } from 'utils'
import { getPackageConfig } from 'services/calculation'

const Area = ({ area, unit }) => {
  if (!area) return null

  return (
    <React.Fragment>
      {`(${formatArea(area)}`}&nbsp;{`${unit === 'M2' ? 'm²' : 'ml'})`}
    </React.Fragment>
  )
}

class CartPackage extends React.Component {
  mounted = false

  packageConfigData = {
    cfWan: null,
    packageId: null,
    calculationId: null,
    type: null,
    packageType: null,
  }

  getPackageConfigData = props => {
    this.packageConfigData.packageId = props.data.id
    this.packageConfigData.calculationId = props.CartStore.id
    this.packageConfigData.packageType = props.data.packageType

    if (props.MissionStore.id !== null) {
      this.packageConfigData.cfWan = props.MissionStore.id
      this.packageConfigData.type = 'cfa'
    }
    if (props.ManagerClaimStore.id !== null) {
      this.packageConfigData.cfWan = props.ManagerClaimStore.id
      this.packageConfigData.type = 'cfm'
    }
  }

  constructor(props) {
    super(props)
    this.getPackageConfigData(props)
    this.state = {
      loading: false,
      packageConfig: {
        canSelfRepair: true,
      },
    }
  }

  showPackage = packageData => {
    const {
      history,
      match: {
        params: { id, type },
      },
    } = this.props

    if (packageData.priceType === 'custom') {
      CreatePackageCtrl.setPackage(packageData)
      if (packageData.location === 'outdoor') {
        history.push(`/${type}/${id}/calculation/createOutdoorPackage`)
      } else {
        history.push(`/${type}/${id}/calculation/createPackage`)
      }
    } else {
      DetailPackageCtrl.setPackage(packageData)
      if (packageData.location === 'outdoor') {
        history.push(`/${type}/${id}/calculation/detailOutdoorPackage`)
      } else {
        history.push(`/${type}/${id}/calculation/detailPackage`)
      }
    }
  }
  removePackage = id => {
    this.props.CartStore.removePackage(id)
  }
  selectPriceType = ({ dataSelfRepair, selfRepairButton }) => {
    const {
      match: {
        params: { type },
      },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

    if (isClosed) return
    else if (dataSelfRepair !== selfRepairButton)
      this.props.data.setProperty('selfRepair', !dataSelfRepair)
    else return
  }

  async getPackageConfig() {
    try {
      //Call the package config endpoint only for DAT_LEAK packages
      //This way we reduce the endpoint overload
      if (this.mounted && this.packageConfigData.packageType === 'DAT_LEAK') {
        this.setState({ loading: true })
        await getPackageConfig(this.packageConfigData)
          .then(response => {
            if (this.mounted) {
              this.setState({
                packageConfig: {
                  canSelfRepair: response.canSelfRepair,
                },
                loading: false,
              })
            }
          })
          .catch(err => {
            console.error(err)
            this.setState({
              packageConfig: {
                canSelfRepair: true,
              },
              loading: false,
            })
          })
      }
    } catch (err) {
      this.setState({
        packageConfig: {
          canSelfRepair: true,
        },
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.mounted = true
    this.getPackageConfig()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const {
      t,
      data,
      data: {
        id,
        area,
        unit,
        quantity,
        label,
        selfRepair,
        isPackageOverrode,
        priceListLabel,
        finalPriceCatalog,
        finalPriceSelfRepair,
      },
      match: { params: type },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props

    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed
    const loadingSpinner = <span className={classNames('fa fa-spinner fa-spin')} />

    if (this.state.loading === true) {
      return loadingSpinner
    }

    if (this.state.packageConfig !== undefined) {
      data.setCanSelfRepair(this.state.packageConfig.canSelfRepair)
    }

    return (
      <div className="row no-gutters align-items-center mb-2">
        <div
          className="package-title col-12 col-lg-7 pl-4"
          onClick={this.showPackage.bind(this, data)}
        >
          {priceListLabel ? priceListLabel + ' - ' : ''} {label}
          <Area area={area} unit={unit} />
          {quantity > 1 ? ` (x${quantity})` : ''}
        </div>

        <div className="col-6 col-sm-5 col-lg-2 pl-4 pl-lg-0 text-left text-lg-right">
          <button
            className={classNames('btn btn-outline-primary', {
              'selected-price': !selfRepair,
              'disabled-price': selfRepair,
            })}
            onClick={this.selectPriceType.bind(this, {
              dataSelfRepair: selfRepair,
              selfRepairButton: false,
            })}
          >
            <i className="fa fa-book d-inline-block d-lg-none" aria-hidden="true" />{' '}
            {euro(finalPriceCatalog)}
            {isPackageOverrode ? '*' : ''}
          </button>
        </div>

        {data.canSelfRepair ? (
          <div className="col-6 col-sm-5 col-lg-2 text-right">
            <button
              className={classNames('btn btn-outline-primary', {
                'selected-price': selfRepair,
                'disabled-price': !selfRepair,
              })}
              onClick={this.selectPriceType.bind(this, {
                dataSelfRepair: selfRepair,
                selfRepairButton: true,
              })}
            >
              <i className="fa fa-user d-inline-block d-lg-none" aria-hidden="true" />{' '}
              {euro(finalPriceSelfRepair)}
              {isPackageOverrode ? '*' : ''}
            </button>
          </div>
        ) : (
          <div className="col-6 col-sm-5 col-lg-2" />
        )}

        <div className="col-sm-2 col-lg-1 d-none d-sm-inline-block text-right">
          {!isClosed && (
            <ButtonWithConfirmation
              className="btn btn-outline-primary rounded-circle"
              icon
              iconClassName="fa fa-trash"
              onClick={this.removePackage.bind(this, id)}
              confirmText={t('mission.calculation.cart.confirmDelete')}
            />
          )}
        </div>
      </div>
    )
  }
}

export default compose(
  inject('CartStore', 'MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(CartPackage)
