import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import InvolvedPartyName from './InvolvedPartyName'
import TableDescriptionPrejudices from './TableDescriptionPrejudices'
import TableDescriptionFurnitures from './TableDescriptionFurnitures'

const Ventilation = ({
  CartStore: { ventilationIPsData },
  MissionStore: { insuredInformationId, IRSIInvolvedPartyWithName },
}) => {
  const involvedPartyIds = IRSIInvolvedPartyWithName.map(({ id }) => id)

  const filteredVentilationIPsData = ventilationIPsData.filter(({ involvedPartyId }) =>
    involvedPartyIds.includes(involvedPartyId),
  )
  return (
    <Fragment>
      {filteredVentilationIPsData.map(({ involvedPartyId, prejudices, furnitures }) => (
        <React.Fragment key={`ventilated-${involvedPartyId}`}>
          {involvedPartyId && <InvolvedPartyName involvedPartyId={involvedPartyId} />}
          {prejudices && (
            <TableDescriptionPrejudices
              involvedPartyId={prejudices.involvedPartyId}
              vans={prejudices.vans}
              obs={prejudices.obs}
              returns={prejudices.returns}
              noReturns={prejudices.noReturns}
              withOBWithoutVAT={prejudices.withOBWithoutVAT}
              withOBWithVAT={prejudices.withOBWithVAT}
              finalPrice={prejudices.finalPrice}
              showPrejudiceStore={prejudices.involvedPartyId === insuredInformationId}
            />
          )}

          {furnitures && (
            <TableDescriptionFurnitures
              involvedPartyId={furnitures.involvedPartyId}
              furnituresPerCategory={furnitures.computations}
            />
          )}
        </React.Fragment>
      ))}
    </Fragment>
  )
}

export default compose(inject('CartStore', 'MissionStore'), observer)(Ventilation)
