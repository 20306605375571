import React from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import './DetailPackage.css'
import CardLayout from 'components/CardLayout/CardLayout'
import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import CustomItemCtrl from 'stores/Mission/view/CustomItemCtrl'
import Recap from './Recap'
import QualityMaterials from './QualityMaterials'
import Item from './Item'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import Area from './Area'
import SupportingDocumentLite from 'pages/Mission/pages/Calculation/SupportingDocumentLite'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import UnitPrice from './UnitPrice'
import SelectInvolvedParty from 'pages/Mission/pages/Calculation/SelectInvolvedParty'
import PackageOptionsLayout from './PackageOptionsLayout'
import CustomItemForm from './CustomItemForm'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'

class DetailPackage extends React.Component {
  mounted = false

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  goToCart = () => {
    const {
      history,
      match: {
        params: { id, type },
      },
    } = this.props
    history.push(`/${type}/${id}/calculation/listPackage`)
  }

  async waitToCart() {
    if (this.mounted) {
      this.setState({ loading: true })
      await this.props.CartStore.save('cart', false).then(() => {
        if (!this.props.CartStore.saving) {
          this.setState({ loading: false })
        }
      })
    }
  }

  initComponent = () => {
    window.scrollTo(0, 0)
    if (DetailPackageCtrl.packageData && DetailPackageCtrl.packageData.fromList === 'listSearch') {
      this.addPackage(DetailPackageCtrl.packageData)
    }
  }

  addPackage = packageData => this.props.CartStore.addPackage(packageData)
  addSupportingDocument = id => DetailPackageCtrl.packageData.addSupportingDocument(id)
  onChange = e => {
    DetailPackageCtrl.packageData.setProperty(e.target.name, e.target.value)
  }

  onChangeRoomTypeLabel = e => {
    DetailPackageCtrl.packageData.setRoomTypeLabel(e.target.value)
  }
  resetRoomTypeLabel = e => {
    if (e.target.value === '') DetailPackageCtrl.packageData.setRoomTypeLabel(null)
  }

  componentDidMount() {
    this.mounted = true
    this.initComponent()
    if (DetailPackageCtrl.packageData.packageType === 'DAT_LEAK') {
      this.waitToCart()
    }
  }

  componentWillUnmount() {
    this.mounted = false
    DetailPackageCtrl.setPackage(null)
  }

  render() {
    const {
      t,
      match: {
        params: { id, type },
      },
      MissionStore: { isMissionClosed },
      CartStore: { isVentilationIPAvailable },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const { packageData } = DetailPackageCtrl
    const { setNewCustomItem, customItem } = CustomItemCtrl
    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

    if (packageData === null) {
      if (isClosed) return <Redirect to={`/${type}/${id}/calculation/listPackage`} />
      return <Redirect to={`/${type}/${id}/calculation/searchPackage`} />
    }

    const {
      packageData: {
        priceListLabel,
        selfRepair,
        allowedLocalisations,
        localisation,
        roomType,
        packageType,
        label,
        involvedParty,
        qualityType,
        materialPresent,
        setProperty,
      },
    } = DetailPackageCtrl

    const packageTypeOptions = [
      { label: t('mission.calculation.cart.titleProperty'), value: 'DAT_PROP' },
      { label: t('mission.calculation.cart.titleEmbellishment'), value: 'DAT_EMB' },
    ]

    const localisationOptions = []
    allowedLocalisations.forEach(localisation => {
      localisationOptions.push({
        label: t('mission.calculation.common.localisation.' + localisation),
        value: localisation,
      })
    })

    return (
      <CardLayout className="detail-package">
        <div className="container pl-0 pr-0">
          <div className="row no-gutters mb-3 align-items-center">
            <div className="col-12">
              <span className="font-weight-bold">
                {priceListLabel
                  ? t('mission.calculation.searchPackage.partner') + ' ' + priceListLabel
                  : t('mission.calculation.common.catalogSolera')}
                {' - '}
                {!selfRepair
                  ? t('mission.calculation.common.catalogPrice')
                  : t('mission.calculation.common.selfRepair')}
              </span>
            </div>
          </div>

          <div className="row no-gutters mb-3 align-items-center">
            <div className="col-10 col-sm-10 col-md-9">
              <Breadcrumb />
            </div>

            <div className="col-2 col-md-3 text-right">
              {this.state.loading && <Spinner />}

              {!this.state.loading && (
                <button className="btn btn-outline-primary" onClick={this.goToCart}>
                  <i className="fa fa-calculator" />
                  <span className="d-none d-none-sm d-none-md d-lg-inline">
                    &nbsp;
                    {t('mission.calculation.detailPackage.backToCart')}
                  </span>
                </button>
              )}
            </div>
          </div>

          <PackageOptionsLayout
            col1={t('mission.calculation.detailPackage.roomTypeLabel')}
            col2={
              <Input
                className="form-control"
                onChange={this.onChangeRoomTypeLabel}
                onBlur={this.resetRoomTypeLabel}
                value={
                  roomType.override && roomType.override.key
                    ? roomType.override.label
                    : roomType.label
                }
              />
            }
            col3={packageType !== 'DAT_LEAK' ? <Area /> : null}
          />

          {packageType !== 'DAT_LEAK' && (
            <PackageOptionsLayout
              col1={t('mission.calculation.detailPackage.damageTypeLabel')}
              col2={
                <ResponsiveSelect
                  name="packageType"
                  onChange={this.onChange}
                  options={packageTypeOptions}
                  value={packageType}
                />
              }
              col3={
                <QualityMaterials
                  qualityType={qualityType}
                  materialPresent={materialPresent}
                  changeQuality={e => setProperty(e.target.name, e.target.value)}
                />
              }
            />
          )}

          {packageType !== 'DAT_LEAK' && (
            <PackageOptionsLayout
              col1={
                localisationOptions.length > 0
                  ? t('mission.calculation.detailPackage.localisation')
                  : null
              }
              col2={
                localisationOptions.length > 0 ? (
                  <ResponsiveSelect
                    name="localisation"
                    onChange={this.onChange}
                    options={localisationOptions}
                    value={localisation}
                  />
                ) : null
              }
              col3={<UnitPrice />}
            />
          )}

          {isVentilationIPAvailable && (
            <div
              className={classNames('row mb-2', {
                'justify-content-end': packageType !== 'DAT_LEAK',
              })}
            >
              <div className="col-md-12 col-lg-6">
                <div className="row no-gutters align-items-center">
                  <div className="col-6">
                    <span className="font-weight-bold">
                      {t('mission.calculation.involvedParty.concerned')}
                    </span>
                  </div>
                  <div className="col-6">
                    <SelectInvolvedParty onChange={this.onChange} value={involvedParty} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row align-items-center mt-3">
            <div className="col-12">
              <h6 className="font-weight-bold mb-0">{label}</h6>
            </div>
          </div>

          <div className="row no-gutters mb-4">
            <div className="col-12">
              <p className="font-italic text-justify mb-0">{packageData.description}</p>
            </div>
          </div>

          <div className="list-item">
            {packageData.items.map(item => {
              if (
                packageData.isCatalog ||
                !item.isCustom ||
                customItem === null ||
                customItem.cde !== item.cde
              )
                return <Item key={`item-${item.cde}`} itemData={item} packageData={packageData} />
              else
                return (
                  <CustomItemForm
                    priceType="priceList"
                    packageData={packageData}
                    key={`item-${item.cde}`}
                  />
                )
            })}
          </div>

          {!packageData.isCatalog && customItem && !customItem.cde && (
            <CustomItemForm priceType="priceList" packageData={packageData} />
          )}
          {!packageData.isCatalog && !customItem && (
            <div className="row no-gutters mt-3">
              <div className="col-12">
                <Button className="btn btn-primary" onClick={() => setNewCustomItem(packageData)}>
                  <i className="fa fa-plus" /> {t('mission.calculation.createCustomItem.add')}
                </Button>
              </div>
            </div>
          )}

          <div className="row no-gutters mt-3">
            <div className="col-12 col-lg-5">
              <SupportingDocumentLite
                supportingDocuments={packageData.supportingDocuments}
                addSupportingDocument={this.addSupportingDocument}
              />
            </div>
            <Recap
              packageData={packageData}
              onChange={this.onChange}
              controller={DetailPackageCtrl}
              packageLayout
            />
          </div>
        </div>

        <LastUpdatedAt />
      </CardLayout>
    )
  }
}

export default compose(
  inject('MissionStore', 'CartStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(DetailPackage)
