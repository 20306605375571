import { observable, action, computed, decorate, runInAction, toJS } from 'mobx'
import {
  setMinutes,
  setHours,
  addDays,
  subDays,
  addWeeks,
  subWeeks,
  format,
  startOfWeek,
  endOfWeek,
  getISOWeek,
  addMonths,
  subMonths,
  startOfMonth,
} from 'date-fns'

import { flatten, differenceWith, isNil } from 'ramda'

import { getDataLocal, setDataLocal } from 'stores/localStorage'
import { fetchUsers } from 'services/userManagement'
import UiCtrl from 'stores/Common/view/UiCtrl'
import CalendarExpert from './CalendarExpert'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

const compare = (a, b) => (a.value || a.id) === (b.value || b.id)

class CalendarExpertStore {
  min = setMinutes(setHours(new Date(), 8), 0)
  max = setMinutes(setHours(new Date(), 19), 0)
  view = 'week'
  selectedExperts = []
  experts = []
  loadingExperts = true
  day = new Date()
  loadingCalendar = false
  fromPlanner = false

  constructor() {
    const view = getDataLocal('calendarViewFilter')
    // const day = getDataLocal('calendarDayFilter')
    const expand = getDataLocal('calendarExpand')
    if (view) this.view = view
    // if (day) this.day = new Date(day)
    if (expand) {
      this.min = setMinutes(setHours(new Date(), expand.min ? 0 : 8), 0)
      this.max = setMinutes(setHours(new Date(), expand.max ? 23 : 19), expand.max ? 59 : 0)
    }
    this.day = new Date()
  }

  get expertOptions() {
    return this.experts.map(expert => expert.optionsFormat)
  }

  get expertValue() {
    return this.selectedExperts.map(expert => expert.optionsFormat)
  }

  get weekNumber() {
    return getISOWeek(this.day)
  }

  get currentWeek() {
    const locale = UiCtrl.dateLocale
    const startWeek = startOfWeek(this.day, { weekStartsOn: 1 })
    const start = format(startWeek, 'd MMMM', { locale })

    const end =
      this.view === 'week'
        ? format(endOfWeek(this.day, { weekStartsOn: 1 }), 'd MMMM', { locale })
        : format(addDays(startWeek, 4), 'd MMMM', { locale })
    return `${start} - ${end}`
  }

  get viewType() {
    if (this.view === 'week' || this.view === 'work_week') return 'week'
    else if (this.view === 'day') return this.view
    else if (this.view === 'month') return this.view
    else {
      console.warn('Calendar unknowned view type : ', this.view)
      return 'week'
    }
  }

  get expertsCalendar() {
    return flatten(this.selectedExperts.map(expert => expert.calendar))
  }

  async getExperts(customerId) {
    this.loadingExperts = true
    try {
      const experts = await fetchUsers({
        customerId,
        filterOnlyExperts: true,
        filterOnlyActive: true,
      })
      runInAction(() => {
        this.experts = experts.map(
          expert =>
            new CalendarExpert({
              id: expert.id,
              firstName: expert.firstName,
              lastName: expert.lastName,
              email: expert.email,
              customerId: expert.customer.id,
            }),
        )
        const expertsLocal = getDataLocal('calendarExpertsFilter')
        if (expertsLocal) {
          this.selectedExperts = expertsLocal.map(expertLocal => {
            return this.experts.find(expert => expert.uid === expertLocal.id)
          })
          this.selectedExperts = this.selectedExperts.filter(expert => !isNil(expert))
          // NO FETCH CALENDAR
          this.selectedExperts.forEach((expert, index) => {
            expert.setProperty('position', index)
            expert.getCalendar(this.day, this.view)
          })
        }

        this.loadingExperts = false
      })
    } catch (err) {
      console.log(err)
    }
  }

  getExpert(expert) {
    this.loadingExperts = true
    this.selectedExperts = [new CalendarExpert(expert)]
    this.loadingExperts = false
  }

  async getSchedules() {
    this.loadingCalendar = true

    try {
      await Promise.all(this.selectedExperts.map(expert => expert.getCalendar(this.day, this.view)))
    } catch (error) {
      console.warn(error)
    } finally {
      runInAction(() => {
        this.loadingCalendar = false
      })
    }
  }

  changeView(view) {
    this.view = view
    setDataLocal('calendarViewFilter', view)
  }

  calendarNavigationGoOnDay = day => {
    this.view = 'day'
    this.day = day
  }

  calendarNavigation = ({ direction, type = 'day' }) => {
    if (direction === 'next') {
      switch (type) {
        case 'day':
          this.day = addDays(this.day, 1)
          break
        case 'week':
          this.day = addWeeks(this.day, 1)
          break
        case 'month':
          this.day = addMonths(startOfMonth(this.day), 1)
          break
        default:
          this.day = new Date()
      }
    } else if (direction === 'prev') {
      switch (type) {
        case 'day':
          this.day = subDays(this.day, 1)
          break
        case 'week':
          this.day = subWeeks(this.day, 1)
          break
        case 'month':
          this.day = subMonths(startOfMonth(this.day), 1)
          break
        default:
          this.day = new Date()
      }
    } else {
      this.day = new Date()
    }
  }

  setExpert(value) {
    if (value === null) this.selectedExperts = []
    else {
      const newExpertToFetch = differenceWith(compare, value, toJS(this.selectedExperts))
      const expertToRemove = differenceWith(compare, toJS(this.selectedExperts), value)
      if (newExpertToFetch.length + this.selectedExperts.length > 12) {
        AlertCtrl.alert('warning', 'calendar.filters.expertLimitWarning')
        return
      }
      if (newExpertToFetch.length === 1) {
        const newExpert = this.experts.find(expert => expert.id === newExpertToFetch[0].value)
        this.selectedExperts.push(newExpert)
        newExpert.getCalendar(this.day, this.view)
      } else if (expertToRemove.length === 1)
        this.selectedExperts = this.selectedExperts.filter(
          expert => expert.id !== expertToRemove[0].id,
        )
      else this.selectedExperts = []

      this.selectedExperts.forEach((expert, index) => {
        expert.setProperty('position', index)
      })
    }

    setDataLocal(
      'calendarExpertsFilter',
      this.selectedExperts.map(expert => ({
        id: expert.uid,
      })),
    )
  }

  expand(changingHour) {
    const minHourCondition = format(this.min, 'k') === '8'
    const maxHourCondition = format(this.max, 'k') === '19'

    if (changingHour === 'min')
      this.min = setMinutes(setHours(new Date(), minHourCondition ? 0 : 8), 0)
    else
      this.max = setMinutes(
        setHours(new Date(), maxHourCondition ? 23 : 19),
        maxHourCondition ? 59 : 0,
      )

    setDataLocal('calendarExpand', {
      min: format(this.min, 'k') !== '8',
      max: format(this.max, 'k') !== '19',
    })
  }

  setProperty(key, value) {
    this[key] = value
  }
}

decorate(CalendarExpertStore, {
  min: observable,
  max: observable,
  view: observable,
  experts: observable,
  selectedExperts: observable,
  day: observable,
  loadingExperts: observable,
  loadingCalendar: observable,
  fromPlanner: observable,

  expertOptions: computed,
  expertValue: computed,
  weekNumber: computed,
  viewType: computed,
  expertsCalendar: computed,
  currentWeek: computed,

  getExperts: action.bound,
  getExpert: action.bound,
  getSchedules: action.bound,
  changeView: action.bound,
  setExpert: action.bound,
  calendarNavigation: action.bound,
  calendarNavigationGoOnDay: action.bound,
  setProperty: action.bound,
  expand: action.bound,
})

export default new CalendarExpertStore()
