import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { GOOGLE_MAP_API_KEY } from 'utils/constants'

const GoogleMapWrapper = withGoogleMap(({ children, defaultCenter, markers }) => {
  const bounds = new window.google.maps.LatLngBounds()
  bounds.extend(new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng))
  markers.forEach(marker =>
    bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng)),
  )

  return (
    <GoogleMap
      ref={ref => {
        ref && ref.fitBounds(bounds)
      }}
      defaultZoom={14}
      defaultCenter={defaultCenter}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY.key}&v=3.exp&libraries=geometry,drawing,places`}
      isMarkerShown={true}
    >
      {children}
    </GoogleMap>
  )
})

const PhotoLocationMap = ({ t, siniterPosition, photoLocation }) => {
  const siniter = {
    id: 'sinister-position',
    name: t('claim.overrun.assignmentPosition'),
    position: siniterPosition,
  }

  const photo = {
    id: 'photo-position',
    name: 'Lieu pris de la photo',
    position: photoLocation,
  }

  let markers = [siniter, photo]

  return (
    <GoogleMapWrapper
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      defaultCenter={siniter.position}
      markers={markers}
    >
      <Marker
        title="Sinistre"
        position={siniter.position}
        label={t('claim.overrun.assignmentPosition')}
        draggable={false}
        cursor="not-allowed"
      />

      <Marker
        title="Photo"
        position={photo.position}
        label={'Position de la Photo'}
        draggable={false}
        cursor="not-allowed"
      />
    </GoogleMapWrapper>
  )
}

export default compose(withTranslation(), observer)(PhotoLocationMap)
