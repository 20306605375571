import React from 'react'
import { inject } from 'mobx-react'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'
import { runInAction } from 'mobx'
import ClaimMobile from './SideNavMobile'
import ClaimDesktop from './SideNavDesktop'
import { compose } from 'recompose'

class SideNavClaims extends React.Component {
  customerConfigKey = 'ManagerMenuOptions'

  defaultMenuValues = {
    // Manager default menu
    MissionData: ['MissionInformation', 'ClaimInformation', 'ContractInformation'],
    Annexes: ['PartiesInvolved', 'SupportingDocuments'],
    Mission: ['Notepad', 'Qualification', 'Planner', 'RequestMissionCreation', 'Overran'],
    Communication: ['Messaging', 'Events'],
  }
  menu = {}

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      values: this.defaultMenuValues,
    }
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      this.setState({ loading: true })
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, key)

      runInAction(() => {
        const newValues = CustomerConfigStore.getValue(key)
        if (newValues !== undefined) this.setState({ values: newValues, loading: false })
      })
    } catch (err) {
      this.setState({ values: this.defaultMenuValues, loading: false })
    }
  }
  componentDidMount() {
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }
  render() {
    const {
      sideNavMobile,
      ClaimStore: { isClaimOverran, claimCorporel },
    } = this.props

    const customerId = this.props.UserStore.customer.id
    const shouldHidePlannerAndQualification = customerId === 'LU00000700A'

    Object.keys(this.state.values).forEach(group => {
      this.menu[group] = []
      Object.keys(this.state.values[group]).forEach(i => {
        const option = this.state.values[group][i]

        if (
          shouldHidePlannerAndQualification &&
          (option === 'Planner' || option === 'Qualification')
        ) {
          return
        }

        switch (option) {
          case 'RequestMissionCreation':
            if (claimCorporel) this.menu[group].push(option)
            break
          case 'Overran':
            if (isClaimOverran) this.menu[group].push(option)
            break
          default:
            this.menu[group].push(option)
            break
        }
      })
    })
    return sideNavMobile ? <ClaimMobile menu={this.menu} /> : <ClaimDesktop menu={this.menu} />
  }
}

export default compose(inject('ClaimStore', 'MessagingStore', 'UserStore'))(SideNavClaims)
