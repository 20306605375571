import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { compose } from 'recompose'

import ClaimMap from 'components/AppointmentMap/ClaimMap'
import MapCtrl from 'stores/Mission/view/MapCtrl'
import { formatPhone, concatNames, isInspectionMission } from 'utils'
import CardLayout from 'components/CardLayout/CardLayout'
import Field from 'components/Field'
import FieldsBloc from 'components/FieldsBloc'
import { concatAddress, formatDate } from 'utils'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'

const Policy = ({
  t,
  UserStore: { isExpert },
  MissionStore,
  MissionStore: { loading: missionLoading },
  ClaimStore,
  ClaimStore: { claim: clm, loading: claimLoading },
}) => {
  const [showStreetView, setShowStreetView] = useState(false)

  const streetViewButon = !showStreetView
    ? t('mission.infoMission.street-view')
    : t('mission.infoMission.google-map')
  const setView = () => {
    if (MapCtrl.collapsed === true) {
      MapCtrl.toggleCollapsed()
    }
    setTimeout(function() {
      setShowStreetView(showStreetView => !showStreetView)
    }, 1000)
  }
  if (missionLoading || claimLoading) return null

  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const store = isExpert ? MissionStore : ClaimStore
  const isIME = isExpert ? MissionStore.isIME : ClaimStore.isIME
  const dataSampleContact = [
    {
      key: t('mission.policy.insuredInformation.quality'),
      val: path(['insuredInformation', 'insured', 'qualifying'], claim) || '-',
    },
    {
      key: t('mission.contact.address'),
      val: concatAddress(path(['insuredInformation', 'insured', 'address'], claim)) || '-',
    },
    {
      key: t('mission.contact.phoneNumber'),
      val: formatPhone(path(['insuredInformation', 'insured', 'phoneNumber'], claim)) || '-',
    },
    {
      key: t('mission.contact.email'),
      val: path(['insuredInformation', 'insured', 'email'], claim) || '-',
    },
  ]
  return (
    <CardLayout className="policy">
      <Field
        name={t('mission.policy.insuranceCompany')}
        value={`${path(['contract', 'insurer', 'code'], claim) || ''} ${path(
          ['contract', 'insurer', 'companyName'],
          claim,
        ) || ''}`}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.policy.insured')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.policy.fullName')}
        rowValue={concatNames(
          path(['insuredInformation', 'insured', 'lastName'], claim),
          path(['insuredInformation', 'insured', 'firstName'], claim),
        )}
        rows={dataSampleContact}
      />
      {isIME && (
        <FieldsBloc
          noTopDivider={true}
          fields={[
            {
              name: t('mission.policy.particularyInformation'),
              value: path(['claimInformation', 'thirdPartyInvolvedInformation'], claim),
            },
            {
              name: t('mission.policy.observation'),
              value: path(['claimInformation', 'comment'], claim),
            },
          ]}
        />
      )}
      {!isInspectionMission(claim) && (
        <FieldsBloc
          title={t('mission.policy.characteristicContract.title')}
          fields={[
            {
              name: t('mission.policy.characteristicContract.contractNumber'),
              value: path(['contract', 'policyNumber'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.referenceCG'),
              value: path(['contract', 'referenceGeneralTermsAndConditions', 'value'], claim)
                ? path(['contract', 'referenceGeneralTermsAndConditions', 'value'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.optionGarantie'),
              value: path(['contract', 'warrantyOption'], claim)
                ? path(['contract', 'warrantyOption'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.qualityOfTheInsured'),
              value: path(['contract', 'qualityOfInsured'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.effectiveDateOfTheContract'),
              value: store.isIME
                ? formatDate(path(['contract', 'insurer', 'policyEffectDate'], claim))
                : formatDate(path(['contract', 'lastAmendmentDate'], claim)),
            },
            {
              name: t('mission.policy.characteristicContract.contractualFranchise'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.tableauLimites'),
              value: path(['contract', 'tableauLimites', 'value'], claim) //TODO missing to create the value in backend
                ? path(['contract', 'tableauLimites', 'value'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.sommesAssurees'),
              value: path(['contract', 'insuredAmount', 'value'], claim)
                ? path(['contract', 'insuredAmount', 'value'], claim)
                : '-',
            },
          ]}
        />
      )}
      {isInspectionMission(claim) && (
        <FieldsBloc
          title={t('mission.policy.characteristicContract.title')}
          fields={[
            {
              name: t('mission.policy.characteristicContract.contractNumber'),
              value: path(['contract', 'policyNumber'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.referenceCG'),
              value: path(['contract', 'referenceGeneralTermsAndConditions', 'value'], claim)
                ? path(['contract', 'referenceGeneralTermsAndConditions', 'value'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.optionGarantie'),
              value: path(['contract', 'warrantyOption'], claim)
                ? path(['contract', 'warrantyOption'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.qualityOfTheInsured'),
              value: path(['contract', 'qualityOfInsured'], claim),
            },
            {
              name: t('mission.policy.characteristicContract.effectiveDateOfTheContract'),
              value: store.isIME
                ? formatDate(path(['contract', 'insurer', 'policyEffectDate'], claim))
                : formatDate(path(['contract', 'lastAmendmentDate'], claim)),
            },
            {
              name: t('mission.policy.characteristicContract.contractualFranchise'),
              value: isIME
                ? null
                : formatCurrency(
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'amount'],
                      claim,
                    ),
                    path(
                      ['contract', 'guarantee', 'guaranteeDeductible', 'relative', 'currency'],
                      claim,
                    ),
                  ),
            },
            {
              name: t('mission.policy.characteristicContract.tableauLimites'),
              value: path(['contract', 'tableauLimites', 'value'], claim) //TODO missing to create the value in backend
                ? path(['contract', 'tableauLimites', 'value'], claim)
                : '-',
            },
            {
              name: t('mission.policy.characteristicContract.sommesAssurees'),
              value: path(['contract', 'insuredAmount', 'value'], claim)
                ? path(['contract', 'insuredAmount', 'value'], claim)
                : '-',
            },
          ]}
        />
      )}
      <Field
        name={t('mission.policy.characteristicContract.riskAddress')}
        value={
          path(['contract', 'addressOfRisk'], claim)
            ? concatAddress(path(['contract', 'addressOfRisk'], claim))
            : null
        }
      />
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">
          <button className="btn btn-primary" onClick={setView}>
            {streetViewButon}
          </button>
        </div>
      </div>

      {!!path(['claimInformation', 'addressOfLoss', 'geometry', 'lat'], claim) ? (
        <ClaimMap
          destination={path(['claimInformation', 'addressOfLoss', 'geometry'], claim)}
          streetView={showStreetView}
        />
      ) : (
        <div className="noGeometry col-8 col-sm-9 col-md-9 col-lg-9 offset-4 offset-sm-3 offset-md-3 offset-lg-3">
          {t('mission.information.map.noGeometry')}
        </div>
      )}

      <FieldsBloc
        title={t('mission.policy.characteristicRisk.title')}
        fields={[
          {
            name: isIME
              ? t('mission.policy.characteristicRisk.typeOfBatiment')
              : t('mission.policy.characteristicRisk.typeOfDwelling'),
            value: path(['claimInformation', 'riskType', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.buildingYear'),
            value: path(['claimInformation', 'buildingYear', 'value'], claim),
          },
          {
            name: isIME
              ? t('mission.policy.characteristicRisk.insuredSquare')
              : t('mission.policy.characteristicRisk.squareArea'),
            value: path(['contract', 'riskDescription', 'squareArea'], claim),
            unit: 'm²',
          },
          {
            name: t('mission.policy.characteristicRisk.habitableArea'),
            value: path(['contract', 'riskDescription', 'primaryRiskSquareArea'], claim),
            unit: 'm²',
          },
        ]}
      />
    </CardLayout>
  )
}

export default compose(
  inject('ClaimStore', 'MissionStore', 'UserStore'),
  withTranslation(),
  observer,
)(Policy)
