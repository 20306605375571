import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { compose } from 'recompose'
import Tooltip from 'components/Tooltip/Tooltip'
import { path } from 'ramda'
import Row from './Row'

const Card = ({
  data: { wan },
  UserStore: { isExpert, isClaimManager },
  data,
  DashboardWidgetCtrl: { headersField, headersInfo, missionsToPrint, addRemoveMissionPrint },
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const goTo = () => {
    if (isExpert) {
      history.push(`/mission/${wan}/missionInformation`)
    } else if (isClaimManager) {
      history.push(`/managerClaim/${wan}/calculation`)
    } else {
      history.push(`/claim/${wan}/missionInformation`)
    }
  }

  const progress = []
  const dataHeaderFiled = headersField.map(field => {
    const dataField = {
      key: field,
      label: headersInfo[field].label,
      type: headersInfo[field].type,
    }
    if (headersInfo[field].visible) dataField.visible = headersInfo[field].visible
    else dataField.visible = '-'
    if (data[field]) dataField.value = data[field]
    else dataField.value = '-'
    return dataField
  })

  for (let i = 1; i <= data['step.total']; i++) {
    if (i <= data['step.current']) {
      progress.push({ orange: true })
    } else {
      progress.push({ orange: false })
    }
  }

  const expertiseKey = isExpert
    ? path(['expertiseType.key'], data)
    : path(['mission.missionInsurerInformation.expertiseType.key'], data)
  const expertiseValue = isExpert
    ? path(['expertiseType.value'], data)
    : path(['mission.missionInsurerInformation.expertiseType.value'], data)

  return (
    <div className="card-mission clickable mt-2 p-2" onClick={goTo}>
      <div className="row no-gutters">
        {data['tags'] && data['tags'].includes('NEW') ? (
          data['tags'].includes('FAKE') ? (
            <div className="fake-tag">{t('dashboard.new-fake')}</div>
          ) : (
            <div className="new-tag">{t('dashboard.new')}</div>
          )
        ) : null}
        <div className="col-12 col-md-2 col-lg-1 text-left mt-3">
          <div className="row no-gutters justify-content-center">
            {progress.map((rowData, index) => (
              <i
                key={index}
                className={classNames('fa', {
                  'fa-circle': rowData.orange,
                  'fa-circle-o': !rowData.orange,
                })}
              />
            ))}
            <br />
          </div>
          {data['tags'] && data['tags'].includes('URGENT') ? (
            <div className="row no-gutters justify-content-center mt-2">
              <i className={classNames('fa fa-warning')} />
            </div>
          ) : null}
          {data['tags'] && data['tags'].includes('WORK_CFA') ? (
            <div className="row no-gutters justify-content-center mt-2">
              <i className={classNames('fa fa-briefcase')} />
            </div>
          ) : null}
          {data['matmutOutOfDarva.natureBlessure'] &&
          data['matmutOutOfDarva.natureBlessure'] === 'DC' ? (
            //For natureBlessure (DC: deces)
            <div className="row no-gutters justify-content-center mt-2 injury-tag">
              {t('dashboard.deathInjury')}
            </div>
          ) : null}
          {data['matmutOutOfDarva.natureBlessure'] &&
          data['matmutOutOfDarva.natureBlessure'] !== 'DC' ? (
            //For natureBlessure (INC: Blessures inconnues,MOD: Blessures modérées,GRV: Blessures graves)
            <div className="row no-gutters justify-content-center mt-2 injury-tag">
              {t('dashboard.injured')}
            </div>
          ) : null}
          {data['hasLinkToPreviousMission'] && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text="Dossier lié">
                <i className="fa fa-link" />
              </Tooltip>
            </div>
          )}
          {expertiseKey && expertiseKey === 'GROUND_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-home')} />
              </Tooltip>
            </div>
          )}
          {expertiseKey && expertiseKey === 'TELE_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-mobile')} />
              </Tooltip>
            </div>
          )}
          {expertiseKey && expertiseKey === 'VISIO_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-video-camera')} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="col-12 col-md-10 col-lg-11">
          <div className="row no-gutters">
            {dataHeaderFiled.map((rowData, index) => (
              <Row key={`row-${wan}-${index}`} data={rowData} />
            ))}
          </div>
        </div>
      </div>
      <input
        type="checkbox"
        className="form-check-input print-mission mr-4"
        checked={missionsToPrint.some(id => id === wan)}
        onChange={e => addRemoveMissionPrint(wan, e.target.checked)}
        onClick={e => e.stopPropagation()}
        disabled={data['tags'] && data['tags'].includes('WORK_CFA')}
      ></input>
    </div>
  )
}

export default compose(inject('UserStore', 'DashboardWidgetCtrl'), observer)(Card)
