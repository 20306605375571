import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { formatWan } from 'utils'
import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import ClaimButtons from 'pages/Claim/components/ClaimButtons'
import { compose } from 'recompose'
import { DynamicMenuUrlConverter } from 'components/SideNav/Converters/DynamicMenuUrlConverter'

class ClaimDesktop extends React.Component {
  render() {
    const {
      menu,
      t,
      ClaimStore: {
        validMissions,
        isQualified,
        isToQualify,
        isClaimOverran,
        claimCorporel,
        id = useParams(),
      },
      MessagingStore: { notifications },
    } = this.props
    return (
      <Fragment>
        {Object.keys(menu).map((group, i) => (
          <div key={group}>
            <h5 className="mt-4 mb-4">{t(`mission.sideNav.title${group}`)}</h5>
            <div className="nav flex-column nav-pills">
              {menu[group].map(option => {
                const translationPath = ['Qualification', 'RequestMissionCreationClaim'].includes(
                  option,
                )
                  ? `claim.sideNav.missions.${option}`
                  : `mission.sideNav.${option}`

                if (option === 'Planner') {
                  return (
                    <ProtectedLink
                      className="nav-link"
                      activeClassName="active"
                      to={DynamicMenuUrlConverter.Claim.Mission.Planner(id)}
                      condition={!isQualified}
                      key={option}
                    >
                      {t(`claim.sideNav.missions.${option}`)}
                    </ProtectedLink>
                  )
                } else if (option === 'Qualification') {
                  return (
                    <ProtectedLink
                      className="nav-link"
                      activeClassName="active"
                      to={DynamicMenuUrlConverter.Claim.Mission.Qualification(id)}
                      condition={!isToQualify}
                      key={option}
                    >
                      {t(`claim.sideNav.missions.${option}`)}
                    </ProtectedLink>
                  )
                } else if (option === 'RequestMissionCreationClaim' && claimCorporel) {
                  return (
                    <ProtectedLink
                      className="nav-link"
                      activeClassName="active"
                      to={DynamicMenuUrlConverter.Claim.Mission.RequestMissionCreation(id)}
                      key={option}
                    >
                      {t(`claim.sideNav.missions.${option}`)}
                    </ProtectedLink>
                  )
                } else if (option === 'Overran' && isClaimOverran) {
                  return (
                    <div className="nav-link protected" key={option}>
                      {t('claim.sideNav.missions.overran')}
                    </div>
                  )
                } else if (
                  group === 'MissionData' ||
                  group === 'Annexes' ||
                  group === 'Mission' ||
                  group === 'Communication'
                ) {
                  return (
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={DynamicMenuUrlConverter.Claim[group][option](id)}
                      key={option}
                    >
                      {t(translationPath)}
                      {option === 'Messaging' && notifications > 0 && (
                        <span className="badge badge-danger ml-1" key={group}>
                          {notifications}
                        </span>
                      )}
                    </NavLink>
                  )
                } else {
                  return ''
                }
              })}
              {group === 'Mission' &&
                validMissions.map(mission => (
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to={`/claim/${id}/currentMission/${mission.cfaWan}`}
                    key={mission.cfaWan}
                  >
                    {formatWan(mission.cfaWan)}
                  </NavLink>
                ))}
            </div>
          </div>
        ))}
        <div className="mt-4 justify-content-between">
          <ClaimButtons />
        </div>
      </Fragment>
    )
  }
}

export default compose(
  inject('ClaimStore', 'MessagingStore'),
  withTranslation(),
)(observer(ClaimDesktop))
