import { observable, action, runInAction, decorate, computed } from 'mobx'
import { path } from 'ramda'

import ClaimStore from 'stores/Claim/domain/ClaimStore'
import UserStore from 'stores/Common/domain/UserStore'
import { fetchFirms, assignFirm } from 'services/overrun'

class OverrunStore {
  firms = []
  loading = false
  selectedFirm = ''
  assigning = false
  showConfirmFirmModal = false
  assignationComment = ''

  get markers() {
    const markers = this.firms.map(firm => ({
      id: firm.id,
      name: firm.name,
      position: {
        lat: path(['address', 'latitude'], firm),
        lng: path(['address', 'longitude'], firm),
      },
    }))
    return markers.filter(firm => firm.position.lat && firm.position.lng)
  }

  get selectedFirmObj() {
    if (this.selectedFirm === '') return null
    return this.firms.find(firm => firm.id === this.selectedFirm)
  }

  get expertType() {
    if (ClaimStore.isMPJ) return '02'
    else if (ClaimStore.isCoverageFire) return '01'
    else return '99'
  }

  loadData = async cfiWan => {
    this.loading = true
    try {
      const res = await fetchFirms(cfiWan)
      runInAction(() => {
        this.firms = res
      })
    } catch (error) {
      console.warn(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  selectFirm = firmId => {
    if (this.selectedFirm === firmId) this.selectedFirm = ''
    else this.selectedFirm = firmId
  }

  assign = async cfiWan => {
    this.assigning = true

    try {
      if (!this.selectedFirmObj) {
        throw new Error('Firm not selected')
      }

      await assignFirm({
        cfiWan,
        firm: this.selectedFirmObj,
        expertName: `${path(['firstName'], UserStore)} ${path(['lastName'], UserStore)}`,
        expertPhoneNumber: path(['claim', 'assigneeCustomer', 'phoneNumber'], ClaimStore),
        comment: this.assignationComment,
        expertType: this.expertType,
      })
    } catch (error) {
      throw error
    } finally {
      runInAction(() => {
        this.assigning = false
      })
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  reset = () => {
    this.loading = false
    this.assigning = false
    this.showConfirmFirmModal = false
    this.selectedFirm = ''
    this.assignationComment = ''
  }
}

const DecoratedOverrunStore = decorate(OverrunStore, {
  firms: observable,
  loading: observable,
  selectedFirm: observable,
  assigning: observable,
  showConfirmFirmModal: observable,
  assignationComment: observable,

  markers: computed,
  selectedFirmObj: computed,
  expertType: computed,

  loadData: action,
  selectFirm: action,
  assign: action,
  setProperty: action,
  reset: action,
})

export default new DecoratedOverrunStore()
