import React from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'

import PageLayout from 'components/PageLayout/PageLayout'
import NewFirmForm from '../components/NewFirmForm'

const NewExternalExpertiseFirm = () => {
  return (
    <div>
      <NewFirmForm />
    </div>
  )
}

export default compose(PageLayout, observer)(NewExternalExpertiseFirm)
