import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { BreakPoints, DashboardDisplayStyle } from 'stores/Common/view/DashboardCtrl'
import Loader from 'components/Loader/Loader'
import { formatWan, formatDateHour } from 'utils'

const List = ({
  UserStore: { isExpert, isClaimManager },
  DashboardWidgetCtrl: {
    loading,
    headers,
    data,
    currentFilter,
    missionsToPrint,
    addRemoveMissionPrint,
  },
  DashboardCtrl: { displayStyle },
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const goToMission = id => history.push(`/mission/${id}/missionInformation`)
  const goToClaim = id => history.push(`/claim/${id}/claimInformation`)
  const goToManagerClaim = id => history.push(`/managerClaim/${id}/claim`)

  const setVisibleColumns = header => {
    if (header['hidden']) {
      return 'd-none'
    }

    let arrOfClassNames = ['d-table-cell', 'd-sm-table-cell', 'd-md-table-cell', 'd-lg-table-cell']
    if (header['disable']) {
      arrOfClassNames = header.disable.reduce((arr, breakpoint) => {
        switch (breakpoint) {
          case BreakPoints.XS:
            arr.splice(arr.indexOf('d-table-cell'), 1)
            arr.push('d-none')
            break
          case BreakPoints.SM:
            arr.splice(arr.indexOf('d-sm-table-cell'), 1)
            arr.push('d-sm-none')
            break
          case BreakPoints.MD:
            arr.splice(arr.indexOf('d-md-table-cell'), 1)
            arr.push('d-md-none')
            break
          default:
            arr.splice(arr.indexOf('d-lg-table-cell'), 1)
            arr.push('d-lg-none')
            break
        }
        return arr
      }, arrOfClassNames)
    }
    return arrOfClassNames.join(' ')
  }

  const renderTableHead = () => {
    return (
      <>
        {headers.map((header, index) => (
          <th key={`header-${index}`} scope="col" className={setVisibleColumns(header)}>
            {header.label}
          </th>
        ))}
        <th className="checkbox-header"></th>
      </>
    )
  }

  const renderMissionsCells = (value, type, name) => {
    if (type === 'date') {
      // HOTFIX back end is not consistant with date
      if (typeof value === 'object') {
        return <span>{formatDateHour({ date: value.date })}</span>
      }
      return <span>{formatDateHour({ date: value })}</span>
    } else if (name === 'cfiWan') {
      return <span>{formatWan(value)}</span>
    } else {
      return <span>{value}</span>
    }
  }

  const renderMissionsRows = data => {
    const goTo = isExpert ? goToMission : isClaimManager ? goToManagerClaim : goToClaim

    return data.map((mission, index) => {
      return (
        <tr
          key={`mission-${index}-${mission.wan}`}
          className={classNames({
            'mission-new': mission.tags && mission.tags.includes('NEW'),
          })}
        >
          {headers.map((header, i) => {
            const value = mission[header.field]
              ? renderMissionsCells(mission[header.field], header.type, header.field)
              : '-'
            return (
              <Fragment key={`mission-${mission.wan}-${header.field}`}>
                <td
                  onClick={goTo.bind(this, mission.wan)}
                  className={classNames(
                    { 'text-danger': mission.tags && mission.tags.includes('URGENT') },
                    setVisibleColumns(header),
                  )}
                >
                  {value}
                </td>
                {i === headers.length - 1 && (
                  <td className="px-3 align-middle">
                    <input
                      className="my_checkbox"
                      type="checkbox"
                      checked={missionsToPrint.some(id => id === mission.wan)}
                      onChange={e => addRemoveMissionPrint(mission.wan, e.target.checked)}
                      disabled={mission.tags && mission.tags.includes('WORK_CFA')}
                    ></input>
                  </td>
                )}
              </Fragment>
            )
          })}
        </tr>
      )
    })
  }

  const renderTable = (data, currentFilter) => {
    const show = displayStyle === DashboardDisplayStyle.LIST_TABLE ? 'd-block' : 'd-none'

    if (loading) {
      return (
        <div className={classNames('dashboard-list card text-center mt-2 p-5', show)}>
          <Loader />
        </div>
      )
    }

    if (data.length === 0) {
      return (
        <div className={classNames('dashboard-list card text-center mt-2 p-5', show)}>
          <span>{t(`dashboard.filter.noResult${currentFilter.status}`)}</span>
        </div>
      )
    }

    return (
      <div className={classNames('dashboard-list mt-2', show)}>
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <table className="table table-bordered table-sm">
              <thead className="thead-light">
                <tr>{renderTableHead()}</tr>
              </thead>
              <tbody>{renderMissionsRows(data)}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return renderTable(data, currentFilter)
}

export default inject('UserStore', 'DashboardCtrl', 'DashboardWidgetCtrl')(observer(List))
