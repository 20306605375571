import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { isNil, path } from 'ramda'
import { compose } from 'recompose'
import { useHistory } from 'react-router-dom'

import Field from 'components/Field'
import { concatAddress, formatDateHour, formatPhone } from 'utils'
import Button from 'components/Button/Button'
import './MissionInformation.css'
import { prop } from 'ramda'
import SelectInvolvedPartyModal from 'pages/Mission/SelectInvolvedPartyModal'

const MissionInformationBaloise = ({
  MissionStore: {
    cfa,
    claim,
    insuredInformation,
    assignerCustomer,
    sendingSms,
    canSendConfirmationBySms,
    confirmSmsDate,
    sendConfirmationBySms,
    showSmsModal,
    setProperty,
    smsSelectedIP,
    phoneNumber,
  },
  PartyInvolvedStore: { asSelectOptions, findMobilePhone },
  UserStore: { customer },
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const lossAddress = path(['addressOfLoss'], claim)
    ? concatAddress(claim.addressOfLoss).trim()
    : ''

  if (isNil(cfa)) {
    history.push('/')
    return
  }

  const smsReceiverNumber = path(['mission', 'smsReceiverNumber'], cfa)

  const onChangeIP = e => {
    const phoneNumber = findMobilePhone(e.target.value)
    setProperty('phoneNumber', phoneNumber ? phoneNumber : '')
    setProperty('smsSelectedIP', e.target.value)
  }

  return (
    <Fragment>
      <Field
        name={t('mission.policy.characteristicContract.referenceCG')}
        value={path(['contract', 'referenceGeneralTermsAndConditions', 'value'], claim) || '-'}
      />
      <Field
        name={t('mission.infoMission.missionDuration')}
        value={path(['cfi', 'mission', 'missionInsurerInformation', 'coverageExecutionTime'], cfa)}
        unit="minute(s)"
      />
      <Field
        name={t('mission.titleSinister')}
        value={`${path(['insured', 'firstName'], insuredInformation) || ''} ${path(
          ['insured', 'lastName'],
          insuredInformation,
        ) || ''}`}
      />
      <Field name="" value={lossAddress} />
      <Field
        name={t('mission.information.AppointmentAt')}
        value={formatDateHour({ date: cfa.mission.appointmentDate })}
      >
        {smsReceiverNumber ? ` - Contact : ${formatPhone(smsReceiverNumber)} ` : ' '}
        {customer.hasContract('SMS') && canSendConfirmationBySms && (
          <Fragment>
            <SelectInvolvedPartyModal
              emailOptionAvailable={false}
              showModal={showSmsModal}
              onClose={() => {
                setProperty('showSmsModal', false)
                setProperty('phoneNumber', '')
                setProperty('smsSelectedIP', null)
              }}
              onChange={e => {
                setProperty(e.target.name, e.target.value)
              }}
              onValid={sendConfirmationBySms}
              onChangeIP={onChangeIP}
              optionsIP={asSelectOptions}
              selectedIP={smsSelectedIP}
              involvedPartyInputName="smsSelectedIP"
              phoneNumber={phoneNumber}
              loading={sendingSms}
            />
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('showSmsModal', true)
              }}
              disabled={path(['mission', 'smsSent'], cfa)}
            >
              <i className="fa fa-commenting-o"></i>
            </Button>
          </Fragment>
        )}
      </Field>
      {confirmSmsDate && (
        <Field
          name={t('mission.information.lastConfirmBySms')}
          value={formatDateHour({ date: confirmSmsDate })}
        ></Field>
      )}
      <Field
        name={t('mission.infoMission.expertise')}
        value={
          (path(['expertiseType', 'value'], cfa) || '-') +
          (assignerCustomer ? ' ' + t('common.for') + ' ' + prop('name', assignerCustomer) : '')
        }
      />
    </Fragment>
  )
}

export default compose(
  inject('MissionStore', 'UserStore', 'PartyInvolvedStore'),
  observer,
)(MissionInformationBaloise)
