import React from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'

const Pagination = ({
  DashboardWidgetCtrl: { navigatePage, data, setPageNumber, meta, links },
}) => {
  let currentPage = meta['current-page-number']
  let pageNumbers = meta['total-pages']

  const handleUpNavigation = () => {
    if (currentPage < pageNumbers && links.next) {
      navigatePage(links.next)
    }
  }

  const handleDownNavigation = () => {
    if (currentPage > 1 && links.prev) {
      navigatePage(links.prev)
    }
  }

  const renderPageItems = totalPage => {
    let items = []
    let range = 1

    // Calculate the limits of the range of pages to display
    let leftLimit = Math.max(currentPage - range, 1)
    let rightLimit = Math.min(currentPage + range, totalPage)

    // Adjust the limits to always maintain 4 elements
    if (currentPage - leftLimit < range) {
      rightLimit = Math.min(rightLimit + (range - (currentPage - leftLimit)), totalPage)
    }
    if (rightLimit - currentPage < range) {
      leftLimit = Math.max(leftLimit - (range - (rightLimit - currentPage)), 1)
    }

    // Ensure no more than 4 page numbers are shown
    if (rightLimit - leftLimit > 3) {
      if (currentPage - leftLimit < rightLimit - currentPage) {
        rightLimit = leftLimit + 3
      } else {
        leftLimit = rightLimit - 3
      }
    }

    // Always show the first page and ellipsis if necessary
    if (leftLimit > 1) {
      items.push(
        <li key="1" className="page-item">
          <button className="page-link" onClick={() => setPageNumber(1)}>
            1
          </button>
        </li>,
      )
      if (leftLimit > 2) {
        items.push(
          <li key="left-ellipsis" className="page-item">
            <button className="page-link disabled">...</button>
          </li>,
        )
      }
    }

    // Range of pages around the current page
    for (let number = leftLimit; number <= rightLimit; number++) {
      items.push(
        <li key={number} className={classNames('page-item', { active: currentPage === number })}>
          <button className="page-link" onClick={() => setPageNumber(number)}>
            {number}
          </button>
        </li>,
      )
    }

    // Always show the last page and ellipsis if necessary
    if (rightLimit < totalPage) {
      if (rightLimit < totalPage - 1) {
        items.push(
          <li key="right-ellipsis" className="page-item">
            <button className="page-link disabled">...</button>
          </li>,
        )
      }
      items.push(
        <li key={totalPage} className="page-item">
          <button className="page-link" onClick={() => setPageNumber(totalPage)}>
            {totalPage}
          </button>
        </li>,
      )
    }

    return items
  }

  return (
    !!data.length && (
      <div className="row">
        <div className="col-12">
          <nav aria-label="Dashboard pagination" className="pagination-navbar">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className={classNames('page-link', { disabled: currentPage === 1 })}
                  aria-label="Previous"
                  onClick={handleDownNavigation}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {renderPageItems(pageNumbers)}
              <li className="page-item">
                <button
                  className={classNames('page-link', { disabled: currentPage === pageNumbers })}
                  aria-label="Next"
                  onClick={handleUpNavigation}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  )
}
export default inject('DashboardCtrl', 'DashboardWidgetCtrl')(observer(Pagination))
