import React, { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { withRouter, Prompt } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import StickyNoteStore from 'stores/Claim/StickyNoteStore'
import Button from 'components/Button/Button'
import { updateStickyNote } from 'services/stickyNote'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { format, parseISO } from 'date-fns'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import ConfirmBox from 'components/ConfirmBox/ConfirmBox'

import './StickyNote.css'

const StickyNote = ({ cfa }) => {
  // CONFIG
  const MAX_LENGTH = 150

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const editorRef = useRef(null)
  const [stickyNoteStore, setStickyNoteStore] = useState([])
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const currentCount = editorState.getCurrentContent().getPlainText().length
  const [showConfirmDiscard, setShowConfirmDiscard] = useState(false)

  //Editor init
  const loadStickyNoteStore = async wan => {
    setLoading(true)
    await StickyNoteStore.loadData(wan)
  }

  useEffect(() => {
    loadStickyNoteStore(cfa.wan).then(() => {
      setStickyNoteStore(StickyNoteStore)
      if (stickyNoteStore.content !== null && stickyNoteStore.content !== undefined) {
        const contentBlock = htmlToDraft(stickyNoteStore.content)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }
      setLoading(false)
      setShowConfirmDiscard(false)
    })
  }, [cfa, setLoading, stickyNoteStore])

  /**
   * Click function of the validate button
   */
  const validateButtonClick = () => {
    if (stickyNoteStore.wan !== undefined) {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      updateStickyNote(stickyNoteStore.wan, {
        content: content,
      }).then(updatedStickyNote => {
        setStickyNoteStore(updatedStickyNote)
        AlertCtrl.alert('success', 'mission.stickyNote.saved')
      })
    }
  }

  /**
   * Click function of the leave button
   */
  const leaveButtonClick = () => {
    if (stickyNoteChanged()) {
      setShowConfirmDiscard(true)
    } else {
      setShowConfirmDiscard(false)
    }
  }

  const discardChanges = userConfirmation => {
    if (userConfirmation === true) {
      if (stickyNoteStore.content !== undefined) {
        const contentBlock = htmlToDraft(stickyNoteStore.content)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
    setShowConfirmDiscard(false)
  }

  const stickyNoteChanged = () => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    return !(stickyNoteStore.content === content)
  }

  //Editor callbacks
  //------------------------------------------------------------------------

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  const __handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = _getLengthOfSelectedText()

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.warn('you can type max 150 characters')

      return 'handled'
    }
    return 'not-handled'
  }

  const _removeSelection = () => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range')
      setEditorState(tempEditorState)
      return tempEditorState
    }
    return editorState
  }

  const _addPastedContent = (input, editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length
    let remainingLength = MAX_LENGTH - inputLength

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength),
    )
    setEditorState(EditorState.push(editorState, newContent, 'insert-characters'))
  }
  const __handlePastedText = pastedText => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = _getLengthOfSelectedText()

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      const selection = editorState.getSelection()
      const isCollapsed = selection.isCollapsed()
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState
      _addPastedContent(pastedText, tempEditorState)

      return 'handled'
    }
    return 'not-handled'
  }

  const __handleStateChange = async state => {
    setEditorState(state)
  }

  //------------------------------------------------------------------------

  return (
    <div className="container pl-0 pr-0">
      <Prompt when={stickyNoteChanged()} message={t('common.quitWithoutSave')} />
      <CardLayout className="sticky-note">
        {cfa.cfi.id === null || loading === true ? (
          <Loader />
        ) : (
          <React.Fragment>
            <h4 className="font-weight-normal">{t('mission.stickyNote.title')}</h4>
            <div className="">
              <div className="">
                <Editor
                  ref={editorRef}
                  key={'texteditor'}
                  readOnly={false}
                  placeholder={t('messaging.form.text')}
                  editorState={editorState}
                  toolbarClassName="wysiwyg-toolbar"
                  wrapperClassName="wysiwyg-wrapper"
                  editorClassName="wysiwyg-editor"
                  handleBeforeInput={__handleBeforeInput}
                  handlePastedText={__handlePastedText}
                  onEditorStateChange={__handleStateChange}
                  toolbar={{
                    options: [
                      'inline',
                      // 'blockType',
                      // 'fontSize',
                      'list',
                      // 'textAlign',
                      // 'colorPicker',
                      // 'link',
                      // 'history',
                    ],
                    inline: {
                      options: [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'superscript',
                        'subscript',
                      ],
                    },
                  }}
                />
              </div>
              {stickyNoteStore.updatedAt !== undefined && stickyNoteStore.updatedAt !== null && (
                <div className="mt-0 text-left text-sm">
                  {t('common.lastUpdatedAtWithDate')}{' '}
                  {format(parseISO(stickyNoteStore.updatedAt), t('common.datetimeFormat'))}
                </div>
              )}
              <div className="mt-0 text-right text-sm">
                {currentCount}/{MAX_LENGTH} {t('messaging.form.character')}
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <Button className="btn btn-primary mr-3" onClick={validateButtonClick}>
                  {t('common.validate')}
                </Button>
                <Button className="btn btn-secondary" onClick={leaveButtonClick}>
                  {t('common.leave')}
                </Button>
                {showConfirmDiscard && (
                  <ConfirmBox
                    isHidden={!showConfirmDiscard}
                    message={t('mission.stickyNote.confirmDelete')}
                    callback={userConfirmation => discardChanges(userConfirmation)}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </CardLayout>
    </div>
  )
}

export default withRouter(withTranslation()(observer(StickyNote)))
