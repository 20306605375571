import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import request from 'services/httpRequest'
import Table from '../components/Table/Table'
import Loader from 'components/Loader/Loader'
import '../components/ExternalExpertiseFirm.scss'

const ExternalExpertiseFirm = () => {
  const history = useHistory()
  const [tbodyData, setTbodyData] = useState()
  const [loading, setLoading] = useState(true)
  const [filterOn, setFilterOn] = useState(false)

  const { t } = useTranslation()

  const theadData = [
    t('externalExpertiseFirm.table.customer'),
    t('externalExpertiseFirm.table.firmName'),
    t('externalExpertiseFirm.table.id'),
    t('externalExpertiseFirm.table.country'),
    t('externalExpertiseFirm.table.specialty'),
    t('externalExpertiseFirm.table.darvaCode'),
    t('externalExpertiseFirm.table.collaboratorCode'),
    t('externalExpertiseFirm.table.entity'),
  ]

  useEffect(() => {
    const getExternalExpertiseFirm = async () => {
      try {
        const response = await request({
          method: 'get',
          url: '/external-expertise-firm',
        })
        const result = response.data.attributes.map(item => {
          return {
            customer: item.attributes.customer.id,
            firmName: item.attributes.firmName,
            id: item.identifier,
            country: item.attributes.address.country,
            speciality: item.attributes.specialty,
            darvaCode: item.attributes.darvaCode,
            collaboratorCode: item.attributes.irdCode,
            entity: item.attributes.gta,
            city: item.attributes.address.city,
            addresse: item.attributes.address.addressLine1,
            phone: item.attributes.customer.phoneNumber,
            dptoIntervention: item.attributes.departmentOfIntervention,
          }
        })
        setTbodyData(result)
        setLoading(false)
      } catch (error) {
        console.warn(error)
      }
    }
    getExternalExpertiseFirm()
  }, [])

  const handleNweFirm = () => {
    history.push('/newexternalexpertisefirm')
  }

  const handleViewFirm = id => {
    history.push(`/externalexpertisefirm/${id}`)
  }

  const handleEditFirm = id => {
    history.push(`/editexternalexpertisefirm/${id}`)
  }

  const handleDeleteFirm = async id => {
    const confirmed = window.confirm(`${t('externalExpertiseFirm.deleteConfirmation')}`)
    if (confirmed) {
      try {
        const response = await request({
          method: 'delete',
          url: `external-expertise-firm/${id}`,
        })
        console.log(response)
        setTbodyData(tbodyData.filter(firm => firm.id !== id))
      } catch (error) {
        console.warn(error)
      }
    }
  }

  return (
    <div className="tour-configuration-page">
      <div className="row no-gutters">
        <h5 className="mb-4">{t('parameters.sideNav.ExternalExpertiseFirm')}</h5>
      </div>
      <th>
        <div className="btn-group" role="group" aria-label="Basic outlined example">
          <button
            type="button"
            onClick={() => setFilterOn(!filterOn)}
            className={filterOn ? 'btn btn-danger' : 'btn btn-primary'}
          >
            {t('externalExpertiseFirm.button.filter')}
          </button>
          <button
            type="button"
            onClick={() => setFilterOn(!filterOn)}
            className={filterOn ? 'btn btn-outline-danger' : 'btn btn-outline-primary'}
          >
            +
          </button>
        </div>
      </th>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            theadData={theadData}
            tbodyData={tbodyData}
            handleDeleteFirm={handleDeleteFirm}
            handleViewFirm={handleViewFirm}
            handleEditFirm={handleEditFirm}
            handleNweFirm={handleNweFirm}
            filterOn={filterOn}
            t={t}
          />
        </>
      )}
    </div>
  )
}

export default compose(observer)(ExternalExpertiseFirm)
