import { observable, action, computed, runInAction, decorate } from 'mobx'

import UiCtrl from 'stores/Common/view/UiCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

import { getDataLocal, setDataLocal, getLocale } from 'stores/localStorage'
import { getDashboard } from 'services/dashboard'

export const BreakPoints = Object.freeze({
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
})

export const BreakPointsWidth = Object.freeze({
  xs: {
    min: 0,
    max: 575.98,
  },
  sm: {
    min: 576,
    max: 768,
  },
  md: {
    min: 768,
    max: 991.98,
  },
  lg: {
    min: 992,
    max: 4000,
  },
})

export const DashboardServices = Object.freeze({
  SERVICE_EXPERT_LIST_CARD: 'expert_list_card',
  SERVICE_EXPERT_LIST_TABLE: 'expert_list_table',
  SERVICE_MANAGER_LIST_CARD: 'manager_list_card',
  SERVICE_MANAGER_LIST_TABLE: 'manager_list_table',
  SERVICE_GESTIONNAIRE_LIST_CARD: 'gestionnaire_list_card',
  SERVICE_GESTIONNAIRE_LIST_TABLE: 'gestionnaire_list_table',
})

export const DashboardDisplayStyle = Object.freeze({
  LIST_CARD: 'list_card',
  LIST_TABLE: 'list_table',
})

class DashboardCtrl {
  loading = true

  dashboardId = null
  displayStyle = DashboardDisplayStyle.LIST_CARD
  widgets = []

  statistics = {}

  constructor() {
    const displayStyle = getDataLocal('displayStyle')
    if (displayStyle) {
      this.displayStyle = displayStyle
    } else {
      setDataLocal('displayStyle', DashboardDisplayStyle.LIST_CARD)
    }
  }

  setDisplayStyle = style => {
    this.displayStyle = style
  }

  getMissionsByFilters(filterKeys) {
    const statuses = this.getStatusesByFilters(filterKeys)

    return this.missionsData.filter(mission => {
      return statuses.includes(mission['status.key'])
    })
  }

  get newMissions() {
    return this.statistics.newClaimFiles
  }

  getService = () => {
    if (UserStore.isExpert) {
      if (this.displayStyle === DashboardDisplayStyle.LIST_CARD) {
        return DashboardServices.SERVICE_EXPERT_LIST_CARD
      } else {
        return DashboardServices.SERVICE_EXPERT_LIST_TABLE
      }
    }

    if (UserStore.isClaimManager) {
      if (this.displayStyle === DashboardDisplayStyle.LIST_CARD) {
        return DashboardServices.SERVICE_GESTIONNAIRE_LIST_CARD
      } else {
        return DashboardServices.SERVICE_GESTIONNAIRE_LIST_TABLE
      }
    }

    if (UserStore.isInsurer) {
      if (this.displayStyle === DashboardDisplayStyle.LIST_CARD) {
        return DashboardServices.SERVICE_MANAGER_LIST_CARD
      } else {
        return DashboardServices.SERVICE_MANAGER_LIST_TABLE
      }
    }
    return null
  }

  setProperty = (key, value) => {
    if (key === 'displayStyle') setDataLocal('displayStyle', value)
    this[key] = value
    if (key === 'displayStyle') this.fetchDashboard()
  }

  fetchDashboard = async () => {
    try {
      const parameters = {
        service: this.getService(),
        locale: getLocale(),
        dashboardId: this.dashboardId,
        userId: UserStore.id,
      }
      const { dashboardData } = await getDashboard(parameters)
      runInAction(() => {
        if (dashboardData) {
          this.widgets = dashboardData.widgets
          this.dashboardId = dashboardData.id
          this.statistics = dashboardData.statistics
        }
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  retrieveNewClaimFiles = () => {
    if (!UiCtrl.isOffLine && UserStore.token) {
      this.fetchDashboard()
    }
  }
}

const DecoratedDashboardCtrl = decorate(DashboardCtrl, {
  dashboardId: observable,
  widgets: observable,
  loading: observable,
  displayStyle: observable,
  statistics: observable,

  newMissions: computed,

  setDisplayStyle: action,
  fetchDashboard: action,
  setProperty: action,
  setPageNumber: action,
})

export default new DecoratedDashboardCtrl()
