import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import DashboardWidgetCtrl, {
  DashboardWidgetFilterKeyConditions,
  DashboardWidgetFilterTypeConditions,
  DashboardWidgetFilterValues,
} from 'stores/Common/view/DashboardWidgetCtrl'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'

class FilterMissionStatus extends React.Component {
  onChangeStatusCondition = e => {
    const statusCondition = new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_STATUS,
      value: e.target.value,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
    DashboardWidgetCtrl.addCurrentFilterCondition(statusCondition)
  }

  render() {
    const { t } = this.props
    const { currentFilter, availableFiltersByKey } = DashboardWidgetCtrl

    const availableStatusFilters = availableFiltersByKey(
      DashboardWidgetFilterKeyConditions.KEY_STATUS,
    )

    const allOpen = {
      value: DashboardWidgetFilterValues.ALL_OPEN,
      label: t('dashboard.filter.allMissions'),
    }

    const filterOptions = availableStatusFilters.map(filter => {
      const condition = filter.getFilterConditionByKey(
        DashboardWidgetFilterKeyConditions.KEY_STATUS,
      )
      return { value: condition.value, label: t(`dashboard.filter.${condition.value}`) }
    })

    filterOptions.push(allOpen)

    return (
      <ResponsiveSelect
        className="select-input"
        name="status"
        isSearchable
        onChange={this.onChangeStatusCondition}
        value={currentFilter.status}
        options={filterOptions}
      />
    )
  }
}

export default withTranslation()(observer(FilterMissionStatus))
