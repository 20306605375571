import request from 'services/httpRequest'

export const fetchDashboardWidget = async (widgetId, params) => {
  return await request({
    method: 'GET',
    url: `/dashboards/v1/widget/${widgetId}`,
    params,
  })
    .then(res => {
      const { data, meta, links } = res
      return {
        dashboardWidgetData: {
          id: data.id,
          meta,
          links,
          ...data.attributes,
        },
        meta,
        links,
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const postDashboardWidgetFilter = async (widgetId, params) => {
  return await request({
    method: 'POST',
    url: `/dashboards/v1/widget/${widgetId}`,
    data: {
      data: {
        type: 'DashboardWidgetFilter',
        attributes: params,
      },
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const deleteDashboardWidgetFilter = async (widgetId, params) => {
  return await request({
    method: 'DELETE',
    url: `/dashboards/v1/widget/${widgetId}`,
    data: {
      data: {
        type: 'DashboardWidgetFilter',
        attributes: params,
      },
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
