import React, { Fragment, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { NavLink } from 'react-router-dom'

import QlickLink from './QlikLink'

const MissionLinks = props => {
  const { t } = useTranslation()

  const [missionNotification, setMissionNotification] = useState('')

  useEffect(() => {
    setMissionNotification(
      props.DashboardCtrl.newMissions > 9 ? '9+' : props.DashboardCtrl.newMissions,
    )
  }, [props.DashboardCtrl.newMissions])

  const drawTabs = (tab, i) => {
    tab = tab.toString().toLowerCase()
    let link
    switch (tab) {
      case 'user':
        link = (
          <li className="nav-item dropdown" key={tab}>
            {props.user}
          </li>
        )
        break
      default:
        link = (
          <li className="nav-item" key={tab}>
            <NavLink
              exact
              className="nav-link text-primary"
              activeClassName="active"
              to={`/${tab}`}
              key={tab}
            >
              {t(`header.navbar.links.${tab}`)}
              {tab === 'missions' || tab === 'claims'
                ? props.DashboardCtrl.newMissions > 0 && (
                    <span className="badge badge-danger ml-1" key={i}>
                      {missionNotification}
                    </span>
                  )
                : tab === 'messages'
                ? props.InboxStore.notifications > 0 && (
                    <span className="badge badge-danger ml-1">
                      {props.InboxStore.notifications}
                    </span>
                  )
                : ''}
            </NavLink>
          </li>
        )
        break
    }
    return link
  }

  return (
    <Fragment>
      {props.tabs.map((tab, i) => [
        drawTabs(tab),
        i !== props.tabs.length - 1 ? <span className="divider" key={i} /> : '',
      ])}
      <QlickLink />
    </Fragment>
  )
}

export default compose(inject('DashboardCtrl', 'InboxStore'), observer)(MissionLinks)
