import React, { Fragment } from 'react'
import { inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { DynamicMenuUrlConverter } from 'components/SideNav/Converters/DynamicMenuUrlConverter'

import CancelMissionButtons from 'pages/Mission/components/CancelMissionButtons'
import SmartLink from 'components/SmartLink/SmartLink'
import GenerateReportLink from './GenerateReportLink'
import ConfigurationDesLieux from './ConfigurationDesLieux'
import AutreMotif from './AutreMotif'
import Corporel from './Corporel'
import CommunicationIncendie from './CommunicationIncendie'
import ChocVTAM from './ChocVTAM'
import VolDeContenu from './VolDeContenu'
import Temoignage from './Temoignage'

class MissionDesktop extends React.Component {
  render() {
    const {
      menu,
      t,
      MissionStore: {
        isHorsDarva,
        id = useParams(),
        configurationDesLieuxMenu,
        autreMotifMenu,
        missionDivers,
        missionCorporel,
        communicationIncendieMenu,
        chocVTAMMenu,
        volDeCONTENUMenu,
        temoignageMenu,
      },
      MessagingStore: { notifications },
    } = this.props

    const drawGroup = group => {
      let title = ''
      title = (
        <h5 className="mt-4 mb-4" key={group}>
          {t('mission.sideNav.title' + group)}
        </h5>
      )
      return title
    }

    const drawOption = (group, option) => {
      let link = ''
      if (group === 'Report') {
        !isHorsDarva
          ? (link = (
              <div className="nav flex-column nav-pills" key={option}>
                <GenerateReportLink />
              </div>
            ))
          : (link = (
              <div className="nav flex-column nav-pills" key={option}>
                <SmartLink page={DynamicMenuUrlConverter.toLowerCaseOption(option)}>
                  {t('mission.sideNav.' + option)}
                </SmartLink>
              </div>
            ))
        return link
      }

      link = (
        <div className="nav flex-column nav-pills" key={option}>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={DynamicMenuUrlConverter.Mission[group][option](id)}
            key={option}
          >
            {t(option === 'Messaging' ? 'messaging.title' : 'mission.sideNav.' + option)}
            {option === 'Messaging' && notifications > 0 ? (
              <span className="badge badge-danger ml-1" key={group}>
                {notifications}
              </span>
            ) : (
              ''
            )}
          </NavLink>
        </div>
      )
      return link
    }
    return (
      <Fragment>
        {Object.keys(menu).map((group, i) => {
          if (group === 'Evaluation') {
            if (isHorsDarva && temoignageMenu && missionDivers) {
              return <Temoignage type="D" key={i} />
            }
            if (isHorsDarva && volDeCONTENUMenu && missionDivers) {
              return <VolDeContenu type="D" key={i} />
            }
            if (isHorsDarva && chocVTAMMenu && missionDivers) {
              return <ChocVTAM type="D" key={i} />
            }
            if (isHorsDarva && configurationDesLieuxMenu && missionDivers) {
              return <ConfigurationDesLieux type="D" key={i} />
            }
            if (isHorsDarva && autreMotifMenu && missionDivers) {
              return <AutreMotif type="D" key={i} />
            }
            if (isHorsDarva && missionCorporel) {
              return <Corporel type="D" key={i} />
            }
            if (isHorsDarva && communicationIncendieMenu && missionDivers) {
              return <CommunicationIncendie type="D" key={i} />
            }

            return [
              drawGroup(group),
              <Fragment key={i}>
                {menu[group].map(option => drawOption(group, option, i))}
              </Fragment>,
            ]
          } else {
            return [
              drawGroup(group),
              <Fragment key={i}>
                {menu[group].map(option => drawOption(group, option, i))}
              </Fragment>,
            ]
          }
        })}
        <div className="mt-4 justify-content-between">
          <CancelMissionButtons />
        </div>
      </Fragment>
    )
  }
}

export default compose(
  inject('MissionStore', 'MessagingStore', 'PrejudiceStore', 'UserStore'),
  withTranslation(),
)(MissionDesktop)
