import { action, decorate } from 'mobx'

class DashboardWidgetFilterCondition {
  key = null
  value = null
  type = null

  constructor(data = null) {
    if (data) {
      this.key = data.key
      this.value = data.value
      this.type = data.type
    }
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedDashboardFilterCondition = decorate(DashboardWidgetFilterCondition, {
  setProperty: action,
})

export default DecoratedDashboardFilterCondition
