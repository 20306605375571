import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import request from 'services/httpRequest'
import '../components/ExternalExpertiseFirm.scss'

const DetailsFirm = () => {
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const [externalExpertise, setExternalExpertise] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getExternalExpertiseFirm = async () => {
      try {
        const { data } = await request({
          method: 'get',
          url: `external-expertise-firm/${id}`,
        })
        setExternalExpertise(data)
      } catch (error) {
        setError(error)
      }
    }
    getExternalExpertiseFirm()
  }, [id])

  const backExternalExpertiseFirmsList = () => {
    history.push('/parameters/ExternalExpertiseFirm')
  }

  return (
    <div className="border border-dark rounded mb-5 customer-cl label-form-cl">
      <div className="d-flexr">
        <h3>
          {error ? (
            <p>An error ocurred: {error.message}</p>
          ) : externalExpertise ? (
            `${t('externalExpertiseFirm.table.firmName')}: ${externalExpertise.attributes.firmName}`
          ) : (
            <p>{t('externalExpertiseFirm.loading')}</p>
          )}
        </h3>
      </div>
      <div className="d-flex justify-content-center">
        <form className="col-md-8">
          {error ? (
            <p>An error ocurred: {error.message}</p>
          ) : externalExpertise ? (
            <>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputID">{t('externalExpertiseFirm.table.id')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputID"
                    value={externalExpertise.id}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputCoutry">{t('externalExpertiseFirm.table.country')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCoutry"
                    value={externalExpertise.attributes.address.country}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputCustomer">{t('externalExpertiseFirm.table.customer')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCustomer"
                    value={externalExpertise.attributes.customer.theme.toUpperCase()}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputCity">{t('externalExpertiseFirm.table.city')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCity"
                    value={externalExpertise.attributes.address.city}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6"></div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputAddress">{t('externalExpertiseFirm.table.zipCode')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    value={externalExpertise.attributes.address.zipCode}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6"></div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputAddress">{t('externalExpertiseFirm.table.address')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    value={externalExpertise.attributes.address.addressLine1}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="form-group col-md-6">
                  <label htmlFor="inputDarvaCode">
                    {t('externalExpertiseFirm.table.darvaCode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputDarvaCode"
                    value={externalExpertise.attributes.darvaCode}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPhone">{t('externalExpertiseFirm.table.phoneNumber')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPhone"
                    value={externalExpertise.attributes.phoneNumber}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputCollabCode">
                    {t('externalExpertiseFirm.table.collaboratorCode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCollabCode"
                    value={externalExpertise.attributes.irdCode}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputDptoInterv">
                    {t('externalExpertiseFirm.table.dptoIntervention')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputDptoInterv"
                    value={externalExpertise.attributes.departmentOfIntervention}
                    disabled
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEntity">{t('externalExpertiseFirm.table.entity')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEntity"
                    value={externalExpertise.attributes.gta}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputSpecialty">
                    {t('externalExpertiseFirm.table.specialty')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputSpecialty"
                    value={externalExpertise.attributes.specialty}
                    disabled
                  />
                </div>
              </div>
            </>
          ) : (
            <p>...</p>
          )}
          <div className="send-form-cl mt-5">
            <button
              className="btn-primary"
              type="button"
              onClick={() => backExternalExpertiseFirmsList()}
            >
              {t('externalExpertiseFirm.button.back')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DetailsFirm
