import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../components/ExternalExpertiseFirm.scss'
import UserStore from 'stores/Common/domain/UserStore'
import request from 'services/httpRequest'

const EditFirm = () => {
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const [error, setError] = useState(null)

  const [idFirm, setIdFirm] = useState('')
  const [firmName, setFirmName] = useState('')
  const [customer, setCustomer] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [phone, setPhone] = useState('')
  const [darvaCode, setDarvaCode] = useState('')
  const [irdCode, setIrdCode] = useState('')
  const [specialty, setSpecialty] = useState('')
  const [gtaCode, setGtaCode] = useState('')
  const [dptoIntervention, setDptoIntervention] = useState('')

  const customerName =
    customer === 'FR00003609A' ? 'MATMUT' : customer === 'LX00000107I' ? 'BALOISE' : customer

  const token = UserStore.token

  const editFirmBody = {
    data: {
      type: 'ExternalExpertiseFirm',
      attributes: {
        darvaCode: darvaCode,
        firmName: firmName,
        irdCode: irdCode,
        specialty: specialty,
        gta: gtaCode,
        departmentOfIntervention: dptoIntervention,
        phoneNumber: phone,
        allowedClaimIdentifierTerminations: [],
        customer: customer,
        linkedCustomer: null,
        address: {
          addressLine1: address,
          addressLine2: null,
          addressLine3: null,
          zipCode: zipCode,
          city: city,
          region: null,
          country: country,
          phoneNumber: null,
          streetNumber: null,
          fax: null,
          creationDate: null,
          lastUpdatedAt: null,
          geometry: { long: null, lat: null },
        },
      },
    },
  }

  useEffect(() => {
    const EditExternalExpertiseFirm = async () => {
      try {
        const { data } = await request({
          method: 'get',
          url: `external-expertise-firm/${id}`,
        })
        setIdFirm(data.id)
        setFirmName(data.attributes.firmName)
        setCustomer(data.attributes.customer.id)
        setCountry(data.attributes.address.country)
        setSpecialty(data.attributes.specialty)
        setDarvaCode(data.attributes.darvaCode)
        setIrdCode(data.attributes.irdCode)
        setGtaCode(data.attributes.gta)
        setCity(data.attributes.address.city)
        setZipCode(data.attributes.address.zipCode)
        setAddress(data.attributes.address.addressLine1)
        setPhone(data.attributes.phoneNumber)
        setDptoIntervention(data.attributes.departmentOfIntervention)
      } catch (error) {
        setError(error)
      }
    }
    EditExternalExpertiseFirm()
  }, [id])

  const handleEdit = async e => {
    e.preventDefault()
    if (
      [
        idFirm,
        customer,
        firmName,
        country,
        darvaCode,
        irdCode,
        gtaCode,
        phone,
        city,
        address,
        zipCode,
        specialty,
        dptoIntervention,
      ].includes('')
    ) {
      alert(`${t('externalExpertiseFirm.fieldsRequired')}`)
      return
    }
    try {
      await request({
        method: 'post',
        url: `/external-expertise-firm/update/${id}`,
        headers: { 'Content-Type': 'application/json' },
        params: {
          access_token: token,
        },
        data: JSON.stringify(editFirmBody),
      })
      alert(`${t('externalExpertiseFirm.changesSaved')}`)
      history.push('/parameters/ExternalExpertiseFirm')
    } catch (error) {
      setError(error)
    }
  }
  const backExternalExpertiseFirmsList = () => {
    history.push('/parameters/ExternalExpertiseFirm')
  }

  return (
    <div className="border border-dark rounded mb-5 customer-cl label-form-cl">
      <div className="d-flexr">
        <h3>
          {error ? (
            <p>An error ocurred: {error.message}</p>
          ) : id ? (
            `${t('externalExpertiseFirm.editing')} ${firmName}`
          ) : (
            <p>{t('externalExpertiseFirm.noData')}</p>
          )}
        </h3>
      </div>
      <div className="d-flex justify-content-center">
        <form className="col-md-8" onSubmit={handleEdit}>
          {error ? (
            <p>An error ocurred: {error.message}</p>
          ) : idFirm ? (
            <>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputID">{t('externalExpertiseFirm.table.id')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputID"
                    value={idFirm}
                    onChange={e => setIdFirm(e.target.value.toUpperCase())}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputCoutry">{t('externalExpertiseFirm.table.country')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCoutry"
                    value={country}
                    onChange={e => setCountry(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputFirmName">{t('externalExpertiseFirm.table.firmName')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirmName"
                    value={firmName}
                    onChange={e => setFirmName(e.target.value.toUpperCase())}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputCity">{t('externalExpertiseFirm.table.city')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCity"
                    value={city}
                    onChange={e => setCity(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputCustomer">{t('externalExpertiseFirm.table.customer')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCustomer"
                    value={customerName}
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputZipCode">{t('externalExpertiseFirm.table.zipCode')}</label>
                  <input
                    type="number"
                    className="form-control"
                    id="inputZipCode"
                    value={zipCode}
                    onChange={e => setZipCode(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6"></div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputAddress">{t('externalExpertiseFirm.table.address')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    value={address}
                    onChange={e => setAddress(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="form-group col-md-6">
                  <label htmlFor="inputDarvaCode">
                    {t('externalExpertiseFirm.table.darvaCode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputDarvaCode"
                    pattern="[A-Z]{1}[0-9]{11}"
                    value={darvaCode}
                    onChange={e => setDarvaCode(e.target.value.toUpperCase())}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPhone">{t('externalExpertiseFirm.table.phoneNumber')}</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="inputPhone"
                    pattern="([0-9]{2} ){4}[0-9]{2}|[0-9]{8,}"
                    value={phone}
                    onChange={e => setPhone(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputCollabCode">
                    {t('externalExpertiseFirm.table.collaboratorCode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCollabCode"
                    value={irdCode}
                    onChange={e => setIrdCode(e.target.value.toUpperCase())}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputDptoInterv">
                    {t('externalExpertiseFirm.table.dptoIntervention')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputDptoInterv"
                    value={dptoIntervention}
                    onChange={e => setDptoIntervention(e.target.value.toUpperCase())}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEntity">{t('externalExpertiseFirm.table.entity')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEntity"
                    value={gtaCode}
                    onChange={e => setGtaCode(e.target.value.toUpperCase())}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputSpecialty">
                    {t('externalExpertiseFirm.table.specialty')}
                  </label>
                  <select
                    name="specialty"
                    id="inputSpecialty"
                    className="form-control"
                    value={specialty}
                    onChange={e => setSpecialty(e.target.value.toUpperCase())}
                  >
                    <option value="">{t('externalExpertiseFirm.specialty.select')}</option>
                    <option value="THFT">THFT</option>
                    <option value="PRJU">PRJU</option>
                    <option value="FIRE">FIRE</option>
                    <option value="OTHR">OTHR</option>
                  </select>
                </div>
              </div>
            </>
          ) : (
            <p>{t('externalExpertiseFirm.loading')}</p>
          )}
          <div className="send-form-cl mt-5">
            <button
              className="btn-primary"
              type="button"
              onClick={() => backExternalExpertiseFirmsList()}
            >
              {t('externalExpertiseFirm.button.back')}
            </button>
            <input
              className="btn-primary"
              type="submit"
              value={t('externalExpertiseFirm.button.save')}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditFirm
