import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import './Parameters.css'
import ErrorBoundary from 'components/ErrorBoundary'
import PageLayout from 'components/PageLayout/PageLayout'
import SideNav from 'components/SideNav/SideNav'
import Informations from 'pages/Parameters/Pages/Informations/Informations'
import UserManagement from 'pages/Parameters/Pages/UserManagement/UserManagement'
import TourConfiguration from 'pages/Parameters/Pages/TourConfiguration/TourConfiguration'
import PartyInvolvedDB from 'pages/Parameters/Pages/PartyInvolvedDB/PartyInvolvedDB'
import CoverageExecutionTime from 'pages/Parameters/Pages/Coverage/CoverageExecutionTime'
import Version from 'pages/Parameters/Pages/Version'
import Impersonate from 'pages/Parameters/Pages/Impersonation'
import Help from 'pages/Parameters/Pages/Help'
import ExternalExpertiseFirm from 'pages/ExternalExpertiseFirm/pages/ExternalExpertiseFirm'

const ProtectedRoute = observer(props => {
  const { condition } = props

  if (condition) return <Route {...props} />

  return <Redirect to="/parameters/generalInformation" />
})

class Parameters extends React.Component {
  render() {
    const {
      match: { path },
      UserStore: {
        isAllowedToPartyInvolvedDB,
        isAllowedToUserManagement,
        isAllowedToCoverageExecutionTime,
        isAllowedToTourConfiguration,
        isAllowedToImpersonate,
      },
    } = this.props

    return (
      <div className="parameters-page container">
        <div className="row align-items-center align-items-lg-start">
          <div className="col-3 mb-3 mb-lg-0">
            <SideNav />
          </div>
          <div className="col-12 col-lg-9">
            <ErrorBoundary>
              <Switch>
                <Route exact path={`${path}/generalInformation`} component={Informations} />
                <ProtectedRoute
                  exact
                  path={`${path}/partiesInvolved`}
                  condition={isAllowedToPartyInvolvedDB}
                  component={PartyInvolvedDB}
                />
                <ProtectedRoute
                  exact
                  path={`${path}/userManagement`}
                  condition={isAllowedToUserManagement}
                  component={UserManagement}
                />
                <ProtectedRoute
                  exact
                  path={`${path}/shiftManagement`}
                  condition={isAllowedToTourConfiguration}
                  component={TourConfiguration}
                />
                <Route path={`${path}/externalExpertiseFirm`} component={ExternalExpertiseFirm} />
                <ProtectedRoute
                  exact
                  path={`${path}/coverageExecutionTime`}
                  condition={isAllowedToCoverageExecutionTime}
                  component={CoverageExecutionTime}
                />
                <Route exact path={`${path}/applicationVersion`} component={Version} />
                <Route exact path={`${path}/downloadTeamViewer`} component={Help} />
                <ProtectedRoute
                  exact
                  path={`${path}/impersonate`}
                  condition={isAllowedToImpersonate}
                  component={Impersonate}
                />
              </Switch>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(
  compose(PageLayout)(inject('UserStore')(withRouter(observer(Parameters)))),
)
