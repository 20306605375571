import React from 'react'
import { inject } from 'mobx-react'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'
import { runInAction } from 'mobx'
import UserStore from 'stores/Common/domain/UserStore'
import MissionDesktop from './SideNavDesktop'
import MissionMobile from './SideNavMobile'
import { compose } from 'recompose'

class SideNavMissions extends React.Component {
  customerConfigKey = 'MissionMenuOptions'

  defaultMenuValues = {
    MissionData: ['MissionInformation', 'ClaimInformation', 'ContractInformation'],
    Annexes: ['PartiesInvolved', 'SupportingDocuments'],
    Evaluation: [
      'RiskConformity',
      'CausesAndCircumstances',
      'DamageDescription',
      'ConventionIRSI',
      'Calculation',
      'VentilationREN',
      'Injuries',
      'Conclusion',
      'Statement',
      'WorkRequest',
      'AgreementProtocol',
      'Rates',
      'Notepad',
      'StickyNote',
    ],
    Report: ['GenerateReport'],
    Communication: ['Messaging', 'Events'],
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      values: this.defaultMenuValues,
    }
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      this.setState({ loading: true })
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, key)

      runInAction(() => {
        const newValues = CustomerConfigStore.getValue(key)
        if (newValues !== undefined) this.setState({ values: newValues, loading: false })
      })
    } catch (err) {
      this.setState({ values: this.defaultMenuValues, loading: false })
    }
  }
  componentDidMount() {
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }
  render() {
    const {
      sideNavMobile,
      MissionStore: {
        policy,
        isWorkCFA,
        claim,
        isIRSI,
        pvRequired,
        workRequestRequired,
        agreementProtocolRequired,
      },
      PrejudiceStore: { payloads, ready },
    } = this.props
    const hasExpertiseSimplify = UserStore.customer.hasContract('ES')
    let menu = {}

    Object.keys(this.state.values).forEach(group => {
      menu[group] = []
      Object.keys(this.state.values[group]).forEach(i => {
        switch (this.state.values[group][i]) {
          case 'ClaimInformation':
            if (claim && !isWorkCFA) menu[group].push(this.state.values[group][i])
            break
          case 'ContractInformation':
            if (policy && !isWorkCFA) menu[group].push(this.state.values[group][i])
            break
          case 'PartiesInvolved':
          case 'RiskConformity':
          case 'CausesAndCircumstances':
          case 'DamageDescription':
          case 'VentilationREN':
            if (!hasExpertiseSimplify) menu[group].push(this.state.values[group][i])
            break
          case 'ConventionIRSI':
            if (!hasExpertiseSimplify && isIRSI) menu[group].push(this.state.values[group][i])
            break
          case 'Injuries':
            if (!hasExpertiseSimplify && ready && !!payloads.length)
              menu[group].push(this.state.values[group][i])
            break
          case 'Statement':
            if (!hasExpertiseSimplify && pvRequired) menu[group].push(this.state.values[group][i])
            break
          case 'WorkRequest':
            if (!hasExpertiseSimplify && workRequestRequired)
              menu[group].push(this.state.values[group][i])
            break
          case 'AgreementProtocol':
            if (agreementProtocolRequired) menu[group].push(this.state.values[group][i])
            break
          case 'Messaging':
            if (!isWorkCFA) menu[group].push(this.state.values[group][i])
            break
          default:
            menu[group].push(this.state.values[group][i])
            break
        }
      })
    })
    return sideNavMobile ? <MissionMobile menu={menu} /> : <MissionDesktop menu={menu} />
  }
}

export default compose(inject('MissionStore', 'UserStore', 'PrejudiceStore'))(SideNavMissions)
