import axios from 'axios'
import { isNil, path } from 'ramda'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import UiCtrl from 'stores/Common/view/UiCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import { getDataLocal, getLocale } from 'stores/localStorage'
import { baseUrl, baseURLQlik } from 'services/utils'
import customHttpCode from './customHttpCode'
import localeISO from './utils'

const createClient = () => {
  const baseURL = baseUrl()
  let client = axios.create({ baseURL })
  return client
}

const client = createClient()

const request = (options, detailResponse = false) => {
  // CFM
  if (UserStore.isClaimManager) {
    options.url = options.url.replace(/claimFileAssessor/, 'claimFileManager')
  }
  if (UiCtrl.isOffLine) console.warn('--- offline mode ---')
  const userInfo = getDataLocal('userInfo')
  const locale = getLocale()

  if (!options['headers']) {
    options['headers'] = {
      'Content-Type': 'application/json',
    }
  }
  if (options.params === undefined) options.params = {}
  const maintenanceByPassCode = localStorage.getItem('maintenanceByPassCode')
  if (maintenanceByPassCode) {
    options.params.maintenanceByPassCode = maintenanceByPassCode
  }

  if (userInfo) {
    options.params.access_token = userInfo.token
    options.params.x_query_token = userInfo.email
  }
  if (locale) options.params.locale = localeISO(locale)

  if (UserStore.impersonating !== null) {
    options.params._switch_user = UserStore.impersonating
  }

  const onSuccess = response => {
    const customCode = customHttpCode(response)

    if (!isNil(customCode)) {
      AlertCtrl.alert(customCode.type, `customHttpCode.${customCode.message}`)
    }

    if (detailResponse) {
      return response
    }

    return response.data
  }

  const onError = err => {
    console.log(err)
    const messages = path(['response', 'data', 'errors'], err) || []
    err.response.messages = messages.filter(msg => msg.detail).map(({ detail }) => detail)

    switch (err.response.status) {
      case 401:
        if (err.response.data.error_description === 'User account is disabled.') {
          AlertCtrl.alert('danger', 'loginPage.disabled')
        } else {
          AlertCtrl.alert('danger', 'loginPage.token')
          UserStore.logout(false)
        }
        return Promise.reject(err)
      case 503:
        UiCtrl.setProperty('apiStatus', 503)
        UserStore.logout(false)
        return Promise.reject(err)
      default:
        const errorClass = err.response.status.toString()[0]
        const errorData = path(['response', 'data', 'error_description'], err)
        const customCode = customHttpCode(err.response)

        if (errorData) {
          return Promise.reject(err.response)
        } else if (!isNil(customCode)) {
          AlertCtrl.alert(customCode.type, `customHttpCode.${customCode.message}`)
        } else if (errorClass === '4' || errorClass === '5') {
          AlertCtrl.alert(
            'danger',
            err.response.messages.length
              ? err.response.messages
              : `httpCode.${err.response.status}`,
          )
        }

        return Promise.reject(err.response)
    }
  }

  return client(options)
    .then(onSuccess)
    .catch(onError)
}

const createClientQlik = () => {
  const baseURL = baseURLQlik()
  let client = axios.create({ baseURL, withCredentials: true })
  return client
}

const clientQlik = createClientQlik()

export const requestQlik = options => {
  return clientQlik(options)
    .then(res => {
      return res
    })
    .catch(err => {
      return err
    })
}

export const getFullLinkFormShortenToken = async shortenToken => {
  try {
    const res = await request({
      method: 'get',
      url: `/shorten-url/${shortenToken}`,
    })
    return res.data.attributes.url
  } catch (err) {
    return null
  }
}

export default request
