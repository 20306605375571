import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { euro } from 'utils/currency'
import RecapRowLayout from './RecapRowLayout'
import {
  TotalWithoutVAT,
  LabelObsolescence,
  LabelObsolescenceReturn,
  LabelVAT,
  TotalWithVAT,
} from './RecapComponents'
import Formula from 'pages/Mission/components/Formula'

class Recap extends React.Component {
  render() {
    const {
      t,
      className,
      packageData,
      displayFormula,
      packageData: {
        packageType,
        totalObsolescence,
        totalVAT,
        totalObsolescenceReturn,
        totalPriceVAT,
        finalPrice,
        areaPresent,
        quantity,
        originalTotalPrice,
      },
      onChange,
      controller,
      packageLayout,
      furniture,
    } = this.props

    return (
      <div
        className={classNames(
          'recap align-items-center col-12',
          {
            'col-lg-7': packageLayout,
            'col-lg-6': !packageLayout,
          },
          className,
        )}
      >
        <RecapRowLayout
          labelComponent={
            <React.Fragment>
              <span className="recap-label mr-3">
                {t('mission.calculation.detailPackage.recapTitle')}
              </span>

              {displayFormula && (
                <Formula quantity={quantity} areaPresent={areaPresent} price={originalTotalPrice} />
              )}
            </React.Fragment>
          }
          priceComponent={<TotalWithoutVAT packageData={packageData} onChange={onChange} />}
          packageLayout={packageLayout}
        />

        <RecapRowLayout
          labelComponent={<LabelVAT packageData={packageData} onChange={onChange} />}
          priceComponent={euro(totalVAT)}
          withoutUnit
          packageLayout={packageLayout}
        />

        <RecapRowLayout
          labelComponent={
            <span className="recap-label">
              {t('mission.calculation.detailPackage.totalWithVAT')}
            </span>
          }
          priceComponent={
            furniture ? (
              <TotalWithVAT packageData={packageData} onChange={onChange} />
            ) : (
              euro(totalPriceVAT)
            )
          }
          withoutUnit={!furniture}
          packageLayout={packageLayout}
        />

        {packageType !== 'DAT_LEAK' && (
          <RecapRowLayout
            labelComponent={
              <LabelObsolescence
                packageData={packageData}
                onChange={onChange}
                controller={controller}
              />
            }
            priceComponent={<span>{euro(totalObsolescence)}</span>}
            withoutUnit
            packageLayout={packageLayout}
          />
        )}

        {packageType !== 'DAT_LEAK' && (
          <RecapRowLayout
            labelComponent={
              <LabelObsolescenceReturn packageData={packageData} onChange={onChange} />
            }
            priceComponent={euro(totalObsolescenceReturn)}
            withoutUnit
            packageLayout={packageLayout}
          />
        )}

        <RecapRowLayout
          labelComponent={
            <span className="recap-label">{t('mission.calculation.cart.totalWithVAT')}</span>
          }
          priceComponent={euro(finalPrice)}
          withoutUnit
          packageLayout={packageLayout}
          last
        />
      </div>
    )
  }
}

Recap.defaultProps = {
  packageLayout: false,
  furniture: false,
  displayFormula: true,
}

Recap.propTypes = {
  packageData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  controller: PropTypes.object.isRequired,
  packageLayout: PropTypes.bool,
  furniture: PropTypes.bool,
  displayFormula: PropTypes.bool,
}

export default compose(withTranslation(), inject('MissionStore'), observer)(Recap)
