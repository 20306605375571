import { environments } from './environment'

/** Log levels */
export const logLevels = {
  LOG: 'log',
  WARN: 'warn',
  ERROR: 'error',
}

export const environmentLogLevel = () => {
  if (process.env.REACT_APP_SOLERA_ENV === environments.PRODUCTION) {
    return logLevels.ERROR
  }

  return logLevels.LOG
}

const NO_OP = (message, ...optionalParams) => {}

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  log = null
  warn = null
  error = null

  constructor() {
    this.loadConsole(environmentLogLevel())
  }

  loadConsole(level) {
    this.error = console.error.bind(console)

    if (level === logLevels.ERROR) {
      this.warn = NO_OP
      this.log = NO_OP

      return
    }

    this.warn = console.warn.bind(console)

    if (level === logLevels.WARN) {
      this.log = NO_OP

      return
    }

    this.log = console.log.bind(console)
  }
}
export const logger = new ConsoleLogger()
