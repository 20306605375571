import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserStore from 'stores/Common/domain/UserStore'
import request from 'services/httpRequest'

import '../components/ExternalExpertiseFirm.scss'

const NewFirmForm = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [firmName, setFirmName] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [phone, setPhone] = useState('')
  const [darvaCode, setDarvaCode] = useState('')
  const [irdCode, setIrdCode] = useState('')
  const [specialty, setSpecialty] = useState('')
  const [gtaCode, setGtaCode] = useState('')
  const [dptoIntervention, setDptoIntervention] = useState('')

  const token = UserStore.token
  const customerName =
    UserStore.customer.id === 'FR00003609A'
      ? 'MATMUT'
      : UserStore.customer.id === 'LX00000107I'
      ? 'BALOISE'
      : UserStore.customer.id
  //setCustomer(UserStore.customer.id)

  const newFirmBody = {
    data: {
      type: 'ExternalExpertiseFirm',
      attributes: {
        darvaCode: darvaCode,
        firmName: firmName,
        irdCode: irdCode,
        specialty: specialty,
        gta: gtaCode,
        departmentOfIntervention: dptoIntervention,
        phoneNumber: phone,
        allowedClaimIdentifierTerminations: [],
        customer: UserStore.customer.id,
        linkedCustomer: null,
        address: {
          addressLine1: address,
          addressLine2: null,
          addressLine3: null,
          zipCode: zipCode,
          city: city,
          region: null,
          country: country,
          phoneNumber: null,
          streetNumber: null,
          fax: null,
          creationDate: null,
          lastUpdatedAt: null,
          geometry: { long: null, lat: null },
        },
      },
    },
  }
  const handleAddNewFirm = async e => {
    e.preventDefault()

    if (
      [
        firmName,
        country,
        darvaCode,
        irdCode,
        gtaCode,
        phone,
        city,
        address,
        zipCode,
        specialty,
        dptoIntervention,
      ].includes('')
    ) {
      alert(`${t('externalExpertiseFirm.fieldsRequired')}`)
      return
    }

    try {
      await request({
        method: 'put',
        url: `/external-expertise-firm/create`,
        headers: { 'Content-Type': 'application/json' },
        params: {
          access_token: token,
        },
        data: JSON.stringify(newFirmBody),
      })
    } catch (error) {
      console.warn(error)
    }
    alert(`${t('externalExpertiseFirm.createdFirm')}`)
    setFirmName('')
    setCountry('')
    setCity('')
    setAddress('')
    setPhone('')
    setDarvaCode('')
    setIrdCode('')
    setSpecialty('')
    setGtaCode('')
    setDptoIntervention('')
    history.push('/parameters/ExternalExpertiseFirm')
  }

  const backExternalExpertiseFirmsList = () => {
    history.push('/parameters/ExternalExpertiseFirm')
  }

  return (
    <div className="border border-dark rounded mb-5 label-form-cl customer-cl">
      <h3>{t('externalExpertiseFirm.newFirm')}</h3>
      <div className="d-flex justify-content-center">
        <form
          className="col-md-8"
          onSubmit={handleAddNewFirm}
          /* action={"newexternalexpertisefirm"} */
        >
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputFirmName">{t('externalExpertiseFirm.table.firmName')}</label>
              <input
                type="text"
                className="form-control"
                id="inputFirmName"
                value={firmName}
                onChange={e => setFirmName(e.target.value.toUpperCase())}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputCoutry">{t('externalExpertiseFirm.table.country')}</label>
              <input
                type="text"
                className="form-control"
                id="inputCoutry"
                value={country}
                onChange={e => setCountry(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputCustomer">{t('externalExpertiseFirm.table.customer')}</label>
              <input
                type="text"
                className="form-control"
                id="inputCustomer"
                value={customerName}
                disabled
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputCity">{t('externalExpertiseFirm.table.city')}</label>
              <input
                type="text"
                className="form-control"
                id="inputCity"
                value={city}
                onChange={e => setCity(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6"></div>
            <div className="form-group col-md-6">
              <label htmlFor="inputZipCode">{t('externalExpertiseFirm.table.zipCode')}</label>
              <input
                type="number"
                className="form-control"
                id="inputZipCode"
                value={zipCode}
                onChange={e => setZipCode(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6"></div>
            <div className="form-group col-md-6">
              <label htmlFor="inputAddress">{t('externalExpertiseFirm.table.address')}</label>
              <input
                type="text"
                className="form-control"
                id="inputAddress"
                value={address}
                onChange={e => setAddress(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-group col-md-6">
              <label htmlFor="inputDarvaCode">{t('externalExpertiseFirm.table.darvaCode')}</label>
              <input
                type="text"
                className="form-control"
                id="inputDarvaCode"
                pattern="[A-Z]{1}[0-9]{11}"
                value={darvaCode}
                onChange={e => setDarvaCode(e.target.value.toUpperCase())}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputPhone">{t('externalExpertiseFirm.table.phoneNumber')}</label>
              <input
                type="tel"
                className="form-control"
                id="inputPhone"
                pattern="([0-9]{2} ){4}[0-9]{2}|[0-9]{8,}"
                value={phone}
                onChange={e => setPhone(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputCollabCode">
                {t('externalExpertiseFirm.table.collaboratorCode')}
              </label>
              <input
                type="text"
                className="form-control"
                id="inputCollabCode"
                value={irdCode}
                onChange={e => setIrdCode(e.target.value.toUpperCase())}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputDptoInterv">
                {t('externalExpertiseFirm.table.dptoIntervention')}
              </label>
              <input
                type="text"
                className="form-control"
                id="inputDptoInterv"
                value={dptoIntervention}
                onChange={e => setDptoIntervention(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEntity">{t('externalExpertiseFirm.table.entity')}</label>
              <input
                type="text"
                className="form-control"
                id="inputEntity"
                value={gtaCode}
                onChange={e => setGtaCode(e.target.value.toUpperCase())}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputSpecialty">{t('externalExpertiseFirm.table.specialty')}</label>
              <select
                name="specialty"
                id="inputSpecialty"
                className="form-control"
                value={specialty}
                onChange={e => setSpecialty(e.target.value.toUpperCase())}
              >
                <option value="">{t('externalExpertiseFirm.specialty.select')}</option>
                <option value="THFT">THFT</option>
                <option value="PRJU">PRJU</option>
                <option value="FIRE">FIRE</option>
                <option value="OTHR">OTHR</option>
              </select>
            </div>
          </div>
          <div className="send-form-cl mt-5">
            <button
              className="btn-primary"
              type="button"
              onClick={() => backExternalExpertiseFirmsList()}
            >
              {t('externalExpertiseFirm.button.back')}
            </button>
            <input
              className="btn-primary"
              type="submit"
              value={t('externalExpertiseFirm.button.create')}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewFirmForm
