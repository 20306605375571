import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import DashboardCtrl, { DashboardDisplayStyle } from 'stores/Common/view/DashboardCtrl'
import Card from './Card'
import Loader from 'components/Loader/Loader'
import DashboardWidgetCtrl from 'stores/Common/view/DashboardWidgetCtrl'

class ListCards extends React.Component {
  render() {
    const { t } = this.props
    const { displayStyle } = DashboardCtrl
    const { loading, headers, data, currentFilter } = DashboardWidgetCtrl
    const show = displayStyle === DashboardDisplayStyle.LIST_CARD ? 'd-block' : 'd-none'

    if (loading) {
      return (
        <div className={classNames('card-mission text-center mt-2 p-5', show)}>
          <Loader />
        </div>
      )
    }

    if (data.length === 0) {
      return (
        <div className={classNames('card-mission text-center mt-2 p-5', show)}>
          <span>{t(`dashboard.filter.noResult${currentFilter.status}`)}</span>
        </div>
      )
    }

    return (
      <div className={classNames('dashboard-list-card', show)}>
        {data.map(widgetData => (
          <Card key={`card-${widgetData.wan}`} headers={headers} data={widgetData} />
        ))}
      </div>
    )
  }
}

export default withTranslation()(observer(ListCards))
