import React from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'

import PageLayout from 'components/PageLayout/PageLayout'
import DetailsFirm from '../components/DetailsFirm'

const DetailExternalExpertiseFirm = () => {
  return (
    <div>
      <DetailsFirm />
    </div>
  )
}

export default compose(PageLayout, observer)(DetailExternalExpertiseFirm)
