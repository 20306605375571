import React from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { path } from 'ramda'

import MissionStore from 'stores/Mission/domain/MissionStore'
import { environmentLogLevel } from 'services/consoleLogger'

const ToolBarDiv = styled.div`
  position: fixed;
  top: 78px;
  right: 12px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
`

const MissionInfo = observer(({ currentPage, missionKey }) => {
  if (
    currentPage === 'Dashboard' ||
    currentPage === 'Login' ||
    path(['information', missionKey])(MissionStore) === undefined
  ) {
    return null
  }

  const label = missionKey === 'missionId' ? 'WAN' : 'Status'

  return (
    <div>
      {label} : {MissionStore.information[missionKey]}
    </div>
  )
})

class ToolBar extends React.Component {
  getCurrentPage = pathname => {
    if (pathname) {
      if (pathname === '/') {
        return this.props.UserStore.token ? 'Dashboard' : 'Login'
      }
      return pathname
    }
    return ''
  }

  close() {
    this.props.UiCtrl.setProperty('displayDebugToolbar', false)
  }

  render() {
    const { pathname } = this.props.location
    const { hasDebugToolbar, displayDebugToolbar } = this.props.UiCtrl

    if (!hasDebugToolbar || !displayDebugToolbar) return null

    const currentPage = this.getCurrentPage(pathname)

    return (
      <ToolBarDiv>
        <div className="pt-2 pl-2 pr-2 d-flex justify-content-between align-items-center">
          <span className="font-weight-bold">Debug</span>
          <i className="fa fa-close clickable font-weight-bold" onClick={this.close.bind(this)} />
        </div>

        <div className="pb-2 pl-2 pr-2">
          <div>Version : {process.env.REACT_APP_BUILD || ''}</div>
          <div>Network : {this.props.UiCtrl.isOffLine ? 'Offline' : 'Online'}</div>
          <div>Page : {currentPage}</div>
          <MissionInfo currentPage={currentPage} missionKey="missionId" />
          <MissionInfo currentPage={currentPage} missionKey="missionStatus" />
          <div>Current log level: {environmentLogLevel()}</div>
        </div>
      </ToolBarDiv>
    )
  }
}

export default inject('UiCtrl', 'UserStore')(withRouter(observer(ToolBar)))
