import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import CanvasZone from './CanvasZone'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'
import Input from 'components/Forms/Input'
import forceLandscape from 'assets/switch-iphone.png'
import { isRequiredValid } from 'utils/supportingDocumentsForm'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import ToolBar from './ToolBar'

class DrawModalMobile extends React.Component {
  setSchemaName = e => DrawCtrl.setSchemaName(e.target.value)
  createSchema = () => DrawCtrl.createSchema(this.props.match.params.id)
  toggleDrawModal = () => DrawCtrl.toggleDrawModal()
  undo = () => DrawCtrl.undo()
  toggleStrokeWidthSelect = () => DrawCtrl.toggleStrokeWidthSelect()
  toggleColorPicker = () => DrawCtrl.toggleColorPicker()
  setType = type => DrawCtrl.setType(type)

  render() {
    const {
      t,
      UiCtrl: { isLandscape },
    } = this.props

    if (!DrawCtrl.drawModal) return null

    if (!isLandscape) {
      return (
        <div className="modal drawModalMobile">
          <div className="modal-dialog">
            <div className="modal-content">
              <img
                height="176"
                width="151"
                src={forceLandscape}
                alt="Landscape orientation needed"
                className="mx-auto d-block mt-5"
              />
              <p className="text-center">{t('mission.supportingDocuments.draw.forcePortrait')}</p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <ModalWithBackdrop
        className="drawModal modal modal-fullscreen-xl"
        show={DrawCtrl.drawModal}
        size="modal-sm"
        close={this.toggleDrawModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {DrawCtrl.photoEdit
              ? t('mission.supportingDocuments.draw.modifyImage')
              : t('mission.supportingDocuments.draw.title')}
          </h5>
          <button type="button" className="close" onClick={this.toggleDrawModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <ToolBar />
                <div className="col-11">
                  <CanvasZone />
                </div>
              </div>
              <form className="form-inline">
                <Input
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  placeholder={t('mission.supportingDocuments.draw.name')}
                  value={DrawCtrl.schemaName}
                  onChange={this.setSchemaName}
                  required
                  errors={[]}
                  maxLength={64}
                />
                <Button
                  className="btn btn-primary mb-2"
                  onClick={this.createSchema}
                  disabled={!isRequiredValid(DrawCtrl.schemaName) || DrawCtrl.elements.length === 0}
                >
                  {!DrawCtrl.photoEdit ? t('common.validate') : t('common.modify')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default inject('UiCtrl')(withRouter(withTranslation()(observer(DrawModalMobile))))
