import React from 'react'
import TableRow from './TableRow'
import TableHeadItem from './TableHeadItem'

import '../../components/ExternalExpertiseFirm.scss'

const Table = ({
  theadData,
  tbodyData,
  handleDeleteFirm,
  handleViewFirm,
  handleEditFirm,
  handleNweFirm,
  filterOn,
  t,
}) => {
  return (
    <table className="table table-striped">
      <thead>
        <tr className="bg-info">
          {theadData.map(h => {
            return <TableHeadItem key={h} item={h} />
          })}
          <button
            type="button"
            className="btn btn-outline-dark btn-sm mt-2 ml-3"
            onClick={() => handleNweFirm()}
          >
            +
          </button>
        </tr>
      </thead>
      <tbody>
        <TableRow
          tbodyData={tbodyData}
          handleDeleteFirm={handleDeleteFirm}
          handleViewFirm={handleViewFirm}
          handleEditFirm={handleEditFirm}
          handleNweFirm={handleNweFirm}
          filterOn={filterOn}
          t={t}
        />
      </tbody>
    </table>
  )
}

export default Table
