import React from 'react'
import { compose } from 'recompose'
import Tooltip from 'components/Tooltip/Tooltip'
import { observer, inject } from 'mobx-react'
import { formatDateHour, removeHtmlFormat, trimString } from 'utils'

const Row = ({ data }) => {
  let value = ''
  let maxLength = 50

  value = data.value

  if (data.type === 'date' && data.value !== '-') {
    // HOTFIX back end is not consistant with date
    if (typeof data.value === 'object') {
      value = formatDateHour({ date: data.value.date })
    } else {
      value = formatDateHour({ date: data.value })
    }
  }

  if (data.type === 'html') {
    value = removeHtmlFormat(trimString(data.value, maxLength))
  }

  const labelClass =
    data.key === 'stickyNote.content' ? 'label text-danger font-weight-bold' : 'label text-primary'
  const valueClass = data.key === 'stickyNote.content' ? 'value text-danger' : 'value'

  if (data.visible === '-') {
    return (
      <div className="no-gutters col-12 col-md-6 col-lg-3">
        <span className={labelClass}>{data.label}</span>
        <br />
        <span className={valueClass} name={data.key}>
          {value}
        </span>
      </div>
    )
  }

  if (data.visible === 'CORPORAL' && data.value !== '-') {
    return (
      <div className="no-gutters col-12 col-md-6 col-lg-3">
        <span className={labelClass}>{data.label}</span>
        <br />
        <span className={valueClass} name={data.key}>
          {value}
        </span>
      </div>
    )
  }

  if (data.visible === 'POPUP' && data.value !== '-') {
    let text = removeHtmlFormat(data.value)
    return (
      <div className="no-gutters col-12 col-md-6 col-lg-3">
        <span className={labelClass}>{data.label}</span>
        <br />
        <span className={valueClass} name={data.key}>
          <Tooltip text={text}>{value}</Tooltip>
        </span>
      </div>
    )
  }

  return null
}

export default compose(inject(), observer)(Row)
