import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import { noop } from 'utils'
import ReadAt from './ReadAt'
import BadinterDisableAlert from './BadinterDisableAlert'
import Attachments from './Attachments'
import Tooltip from 'components/Tooltip/Tooltip'

import ConfirmBoxMessage from 'components/ConfirmBox/ConfirmBoxMessage'
import { useState } from 'react'

function NewlineText(props) {
  const text = props.text
  const newText = text.split('\n').map((str, idx) => <p key={idx}>{str}</p>)

  return newText
}

function Message({
  t,
  match: {
    params: { id: wan },
  },
  data: {
    isSelf,
    text,
    formatCreatedDate,
    formatReadAt,
    markAsRead,
    markingAsRead,
    canMarkAsRead,
    from,
    at,
    type,
    documents,
    isUrgent,
    saveAttachments,
    savingAttachments,
    senderUser,
    sendSDR63,
    trackingType,
    canDisableBadinterAlert,
    disableBadinterAlert,
    disablingBadinterAlert,
  },
}) {
  const save = wan => {
    saveAttachments(wan)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const badinterTypes = ['BDNT_PRV', 'BDNT_EXP']
  const isBadinter = badinterTypes.includes(type)
  return (
    <div
      className={classNames('message d-inline-block text-left mb-3 border rounded p-2', {
        'bg-other': !isSelf,
        'bg-self': isSelf,
        'bg-badinter': isBadinter,
      })}
    >
      <div className="row">
        <div className="col-md-9">
          <div className="info mb-2">
            {from}{' '}
            {isUrgent && (
              <Tooltip className="d-inline" text={t('messaging.urgentTooltip')}>
                <i className="fa fa-warning text-primary" />
              </Tooltip>
            )}
            {at ? (
              <React.Fragment>
                <br />
                {at}
                <br />
              </React.Fragment>
            ) : (
              <br />
            )}
            {t('messaging.message.date')} : {formatCreatedDate}
          </div>
        </div>
        <div className="col-md-3 text-right">
          {trackingType === 'SD25' && (
            <Tooltip className="d-inline" text={t('messaging.sdAnnulationMissionTooltip')}>
              <i className="fa fa-calendar-times-o text-primary" />
            </Tooltip>
          )}
          {trackingType === 'SD20' && (
            <Tooltip className="d-inline" text={t('messaging.sdRelanceExpertTooltip')}>
              <i className="fa fa-sticky-note text-primary" />
            </Tooltip>
          )}
          {trackingType === 'SD99' && (
            <Tooltip className="d-inline" text={t('messaging.sdCommentaire')}>
              <i className="fa fa-comment text-primary" />
            </Tooltip>
          )}
        </div>
      </div>

      <NewlineText text={text} />

      {documents.length > 0 && (
        <Attachments
          documents={documents}
          onSelectItem={noop}
          canDelete={false}
          showInfo={false}
          thumbnailSize="small"
        />
      )}

      {!isSelf && trackingType === 'SDR62' && (
        <div className="d-flex justify-content-end info">
          {savingAttachments ? (
            <i className="fa fa-spinner fa-spin text-primary" />
          ) : (
            <button
              className="btn btn-link p-0"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              {t('messaging.SD63.approval')}
            </button>
          )}

          {modalOpen && (
            <ConfirmBoxMessage setOpenModal={setModalOpen} wan={wan} sendSDR63={sendSDR63} />
          )}
        </div>
      )}

      {!isSelf && documents.length > 0 && (
        <div className="d-flex justify-content-end info">
          {savingAttachments ? (
            <i className="fa fa-spinner fa-spin text-primary" />
          ) : (
            <button className="btn btn-link p-0" onClick={save.bind(this, wan)}>
              {t('messaging.message.saveDocument', { count: documents.length })}
            </button>
          )}
        </div>
      )}

      <div className="d-flex justify-content-between align-items-end info w-100">
        <BadinterDisableAlert
          type={type}
          canDisableBadinterAlert={canDisableBadinterAlert}
          disableBadinterAlert={disableBadinterAlert}
          disablingBadinterAlert={disablingBadinterAlert}
        />
        <ReadAt
          isSelf={isSelf}
          type={type}
          from={from}
          formatReadAt={formatReadAt}
          markAsRead={markAsRead}
          markingAsRead={markingAsRead}
          canMarkAsRead={canMarkAsRead}
          senderUser={senderUser}
        />
      </div>
    </div>
  )
}

export default compose(inject('MessagingStore'), withRouter, withTranslation(), observer)(Message)
