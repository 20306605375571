import React from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from 'pages/Login/Login'
import ResetPassword from 'pages/Login/ResetPassword'
import Dashboard from 'pages/Dashboard/Dashboard'
import Mission from 'pages/Mission/Mission'
import Claim from 'pages/Claim/Claim'
import ManagerClaim from 'pages/ManagerClaim/ManagerClaim'
import NotFound from 'pages/NotFound/NotFound'
import Parameters from 'pages/Parameters/Parameters'
import Maintenance from 'pages/Maintenance/Maintenance'
import Inbox from 'pages/Inbox'
import Qlik from 'pages/Qlik'
import Calendar from 'pages/Calendar/Calendar'
import NewExternalExpertiseFirm from 'pages/ExternalExpertiseFirm/pages/NewExternalExpertiseFirm'
import EditExternalExpertiseFirm from 'pages/ExternalExpertiseFirm/pages/EditExternalExpertiseFirm'
import DetailExternalExpertiseFirm from 'pages/ExternalExpertiseFirm/pages/DetailExternalExpertiseFirm'
import { environments } from 'services/environment'

const PrivateRoute = inject(
  'UiCtrl',
  'UserStore',
)(
  observer(props => {
    const {
      UiCtrl: { apiStatus },
      UserStore: { token },
    } = props
    if (apiStatus !== 200) return <Maintenance />
    if (token) return <Route {...props} />
    return <Redirect to="/" />
  }),
)

const Main = ({ UiCtrl: { apiStatus }, UserStore: { token, passwordChangedAt } }) => {
  return (
    <main>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (apiStatus !== 200) return <Maintenance />
            return token &&
              (process.env.REACT_APP_SOLERA_ENV !== environments.PRODUCTION ||
                passwordChangedAt) ? (
              <Dashboard />
            ) : (
              <Login />
            )
          }}
        />
        <PrivateRoute
          path="/missions"
          render={() => {
            if (apiStatus !== 200) return <Maintenance />
            return token &&
              (process.env.REACT_APP_SOLERA_ENV !== environments.PRODUCTION ||
                passwordChangedAt) ? (
              <Dashboard />
            ) : (
              <Login />
            )
          }}
        />
        <PrivateRoute
          path="/claims"
          render={() => {
            if (apiStatus !== 200) return <Maintenance />
            return token &&
              (process.env.REACT_APP_SOLERA_ENV !== environments.PRODUCTION ||
                passwordChangedAt) ? (
              <Dashboard />
            ) : (
              <Login />
            )
          }}
        />
        <Route path="/resetPassword/:token" component={ResetPassword} />
        <Route path="/newexternalexpertisefirm" component={NewExternalExpertiseFirm} />
        <PrivateRoute path="/externalexpertisefirm/:id" component={DetailExternalExpertiseFirm} />
        <PrivateRoute path="/editexternalexpertisefirm/:id" component={EditExternalExpertiseFirm} />
        <PrivateRoute path="/parameters" component={Parameters} />
        <PrivateRoute path="/mission/:id" component={Mission} />
        <PrivateRoute path="/claim/:id" component={Claim} />
        <PrivateRoute path="/managerClaim/:id" component={ManagerClaim} />
        <PrivateRoute path="/messages" component={Inbox} />
        <PrivateRoute path="/agenda" component={Calendar} />
        <PrivateRoute path="/qlik" component={Qlik} />
        <Route
          render={() => {
            if (apiStatus !== 200) return <Maintenance />
            return token ? <NotFound /> : <Login />
          }}
        />
      </Switch>
    </main>
  )
}

export default inject('UiCtrl', 'UserStore')(observer(Main))
