import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import DashboardWidgetCtrl, {
  DashboardWidgetFilterTypeConditions,
} from 'stores/Common/view/DashboardWidgetCtrl'

import Input from 'components/Forms/Input'

const QuickDates = withTranslation()(
  observer(({ t, onClick }) => {
    const dates = [
      { label: t('dashboard.filter.date.today'), key: 'today' },
      { label: t('dashboard.filter.date.todayMorning'), key: 'todayMorning' },
      { label: t('dashboard.filter.date.todayAfternoon'), key: 'todayAfternoon' },
      { label: t('dashboard.filter.date.tomorrow'), key: 'tomorrow' },
      { label: t('dashboard.filter.date.tomorrowMorning'), key: 'tomorrowMorning' },
      { label: t('dashboard.filter.date.tomorrowAfternoon'), key: 'tomorrowAfternoon' },
      { label: t('dashboard.filter.date.all'), key: null },
    ]

    return (
      <div className="quick-dates">
        {dates.map(date => (
          <div className="date" key={`date-${date.key}`} onClick={onClick.bind(this, date)}>
            {date.label}
          </div>
        ))}
      </div>
    )
  }),
)

class DateFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
      display: false,
      stateValue: {
        startDate: props.value && props.value.startDate ? props.value.startDate : null,
        endDate: props.value && props.value.endDate ? props.value.endDate : null,
        key: props.value && props.value.key ? props.value.key : null,
        label: props.value && props.value.label ? props.value.label : null,
      },
    }
  }

  onFocusRangePick = focusedInput => {
    this.setState({ focusedInput })
  }

  isOutsideRange = () => false
  onClick = date => {
    const { currentFilter, setCurrentFilter } = DashboardWidgetCtrl
    const condition = new DashboardWidgetFilterCondition({
      key: this.props.name,
      value: date.key,
      type: DashboardWidgetFilterTypeConditions.DATE,
    })
    currentFilter.addFilterCondition(condition)
    setCurrentFilter(currentFilter, false)

    const stateValue = {
      key: date.key,
      label: date.label,
    }

    this.setState({ display: false, stateValue })
  }

  rangePick = ({ startDate, endDate }) => {
    const date = {}
    date.startDate = startDate ? moment(startDate).startOf('day') : null
    date.endDate = endDate ? moment(endDate).endOf('day') : null

    const { currentFilter } = DashboardWidgetCtrl
    const condition = new DashboardWidgetFilterCondition({
      key: this.props.name,
      value: JSON.stringify(date),
      type: DashboardWidgetFilterTypeConditions.DATE_RANGE,
    })
    currentFilter.addFilterCondition(condition)

    const stateValue = {
      startDate,
      endDate,
    }
    this.setState({
      stateValue,
    })

    if (startDate && endDate) {
      this.closeDateFilter()
    }
  }

  onClearDates = () => {
    this.setState({
      stateValue: {
        startDate: null,
        endDate: null,
        key: this.getCurrentDateKey(),
        label: this.getCurrentDateLabel(),
      },
    })
  }

  closeDateFilter = () => {
    const { setCurrentFilter, currentFilter } = DashboardWidgetCtrl
    this.setState({ display: false })
    setCurrentFilter(currentFilter, false)
  }
  onFocus = () => {
    this.setState({ display: true })
  }

  getCurrentStartDate = currentFocusedInput => {
    const { stateValue, focusedInput } = this.state
    const { value } = this.props

    if (
      (stateValue && stateValue.startDate) ||
      (currentFocusedInput === focusedInput && focusedInput)
    ) {
      return stateValue && stateValue.startDate ? moment(stateValue.startDate) : null
    }
    return value && value.startDate ? moment(value.startDate) : null
  }

  getCurrentEndDate = currentFocusedInput => {
    const { stateValue, focusedInput } = this.state
    const { value } = this.props

    if (
      (stateValue && stateValue.endDate) ||
      (currentFocusedInput === focusedInput && focusedInput)
    ) {
      return stateValue && stateValue.endDate ? moment(stateValue.endDate) : null
    }
    return value && value.endDate ? moment(value.endDate) : null
  }

  getCurrentDateLabel = () => {
    const { value } = this.props
    const { stateValue } = this.state

    if (stateValue && stateValue.label) {
      return stateValue.label
    }

    if (value && typeof value === 'object') {
      if (value.label) {
        return value.label
      }
    }

    return null
  }

  getCurrentDateKey = () => {
    const { t, value } = this.props
    const { stateValue } = this.state

    if (stateValue && stateValue.key) {
      return t(`dashboard.filter.date.${stateValue.key}`)
    }

    if (value && typeof value === 'object') {
      if (value.key) {
        return t(`dashboard.filter.date.${value.key}`)
      }
    }

    return null
  }

  getCurrentDateText = () => {
    const currentStartDate = this.getCurrentStartDate()
    const currentEndDate = this.getCurrentEndDate()
    const currentLabel = this.getCurrentDateLabel()
    const currentKey = this.getCurrentDateKey()

    let dateText = null

    if (currentLabel) {
      dateText = currentLabel
    }

    if (currentKey && dateText === null) {
      dateText = currentKey
    }

    if (currentStartDate && dateText === null) {
      dateText = `${currentStartDate.format('DD/MM/YYYY')}`
      if (currentEndDate) {
        dateText += ' -> '
        dateText += `${currentEndDate.format('DD/MM/YYYY')}`
      }
    } else {
      if (currentEndDate) {
        dateText = '... -> '
        dateText += `${currentEndDate.format('DD/MM/YYYY')}`
      }
    }
    return dateText
  }

  render() {
    const { focusedInput, display } = this.state
    const {
      t,
      UiCtrl: { isMobile },
    } = this.props

    return (
      <React.Fragment>
        <Input
          className="form-control"
          style={{ height: '38px' }}
          name="date-filter"
          onFocus={this.onFocus.bind(this)}
          value={this.getCurrentDateText()}
        />
        {display && (
          <React.Fragment>
            <div className="date-filter card">
              <div className="card-body p-1 ">
                <DateRangePicker
                  startDatePlaceholderText={t('dashboard.filter.date.startDate')}
                  endDatePlaceholderText={t('dashboard.filter.date.endDate')}
                  monthFormat="MMMM YYYY"
                  startDate={this.getCurrentStartDate(focusedInput)}
                  startDateId="start-id"
                  endDate={this.getCurrentEndDate(focusedInput)}
                  endDateId="end-id"
                  block={true}
                  onDatesChange={this.rangePick}
                  focusedInput={focusedInput}
                  onFocusChange={this.onFocusRangePick}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={this.isOutsideRange}
                  withFullScreenPortal={isMobile}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  minimumNights={0}
                />
              </div>
              <QuickDates onClick={this.onClick.bind(this)} />
            </div>
            <div className="date-filter-overlay" onClick={this.closeDateFilter} />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default inject('UiCtrl')(withTranslation()(observer(DateFilter)))
