import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import InputPrice from 'components/InputPrice/InputPrice'
import { euro } from 'utils/currency'
import AlertCompensatory from './AlertCompensatory'

const threshold = total => {
  if (total < 0) return 0
  return total
}

const Franchise = ({
  totalColumnImmediate,
  totalColumnDeffered,
  totalColumnTotal,
  totalColumnPresented,
  CartStore: { franchiseImmediate, franchiseDeffered, franchiseTotal },
  InstructionCtrl: { isFranchiseMustBeDeducted },
  isCardifUser,
}) => {
  const { t } = useTranslation()

  if (isFranchiseMustBeDeducted) {
    totalColumnImmediate = totalColumnImmediate - franchiseImmediate
    totalColumnDeffered = totalColumnDeffered - franchiseDeffered
    totalColumnTotal = totalColumnTotal - franchiseTotal
    totalColumnPresented = totalColumnPresented - franchiseDeffered
  }

  return (
    <React.Fragment>
      <tr>
        <td className="text-center align-middle">
          {t('mission.conclusion.settlement.deductible')}
        </td>
        {!isCardifUser && (
          <>
            <td
              className={classNames('text-center align-middle', {
                'table-active': !isFranchiseMustBeDeducted,
              })}
            >
              {isFranchiseMustBeDeducted && (
                <span>
                  -
                  <InputPrice
                    name="franchiseImmediate"
                    price={franchiseImmediate}
                    width={80}
                    disabled={true}
                  />
                </span>
              )}
            </td>
            <td
              className={classNames('text-center align-middle', {
                'table-active': !isFranchiseMustBeDeducted,
              })}
            >
              {isFranchiseMustBeDeducted && (
                <span>
                  -
                  <InputPrice
                    name="franchiseDeffered"
                    price={franchiseDeffered}
                    width={80}
                    disabled={true}
                  />
                </span>
              )}
            </td>
          </>
        )}
        <td
          className={classNames('text-center align-middle', {
            'table-active': !isFranchiseMustBeDeducted,
          })}
        >
          {isFranchiseMustBeDeducted && (
            <span>
              -
              <InputPrice name="franchiseTotal" price={franchiseTotal} width={80} disabled={true} />
            </span>
          )}
        </td>
      </tr>

      <tr>
        <td className="text-center align-middle font-weight-bold">
          {t('mission.conclusion.settlement.totalNonWaranty')}
        </td>
        {!isCardifUser && (
          <>
            <td className="text-center align-middle font-weight-bold">
              {euro(threshold(totalColumnImmediate))}
            </td>
            <td className="text-center align-middle font-weight-bold">
              {euro(totalColumnDeffered)}
            </td>
          </>
        )}
        <td className="text-center align-middle font-weight-bold">
          <AlertCompensatory small={true} />
          {euro(threshold(totalColumnTotal))}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default inject('CartStore', 'InstructionCtrl')(observer(Franchise))
