import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { withRouter } from 'react-router-dom'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import TextArea from 'components/Forms/TextArea'
import { concatAddress } from 'utils'

const ConfirmFirm = ({
  t,
  ClaimStore: { id: cfiWan, claim, loadData },
  OverrunStore: {
    showConfirmFirmModal,
    setProperty,
    selectedFirmObj,
    assigning,
    assign,
    assignationComment,
  },
  match,
  history,
}) => {
  const closeModal = () => {
    setProperty('showConfirmFirmModal', false)
    setProperty('assignationComment', '')
  }
  const confirmAssignation = async () => {
    try {
      await assign(cfiWan)
      await loadData(match.params.id)
      history.push(`/claim/${match.params.id}/missionInformation`)
    } catch (error) {
      console.warn(error)
    }
  }
  const onChange = e => {
    setProperty('assignationComment', e.target.value)
  }

  return (
    <ModalWithBackdrop size="modal-md" show={showConfirmFirmModal} close={closeModal}>
      <div className="modal-header">
        <h5 className="modal-title">{t('claim.overrun.confirmFirmTitle')}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <p className="text-primary font-weight-bold">{path(['name'], selectedFirmObj) || ''}</p>

        <p className="font-weight-bold">
          {t('claim.planner.confirmExpert.missionType')} :{' '}
          {path(['mission', 'missionInsurerInformation', 'coverage', 'value'], claim)}
        </p>

        <div className="font-weight-bold mb-2">
          {t('claim.planner.confirmExpert.address')} :{' '}
          {concatAddress(path(['mission', 'missionInsurerInformation', 'address'], claim) || null)}
        </div>

        <div>
          <Label required>{t('claim.overrun.assignationComment')}</Label>
          <TextArea
            className="form-control"
            name="assignationComment"
            value={assignationComment}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <Button
          className="btn btn-secondary"
          data-cy="cancel"
          onClick={closeModal}
          disabled={assigning}
        >
          {t('common.cancel')}
        </Button>
        <Button
          className="btn btn-primary"
          data-cy="submit"
          onClick={confirmAssignation}
          disabled={assigning || assignationComment.length === 0}
          loading={assigning}
        >
          {t('claim.planner.confirmExpert.confirm')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('ClaimStore', 'OverrunStore'),
  withRouter,
  withTranslation(),
  observer,
)(ConfirmFirm)
