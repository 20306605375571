import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import SelectInvolvedPartyModal from 'pages/Mission/SelectInvolvedPartyModal'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import Button from 'components/Button/Button'

const RemoteAssessment = ({
  RemoteAssessmentCtrl: {
    showRemoteAssessmentForm,
    phoneNumber,
    email,
    startSession,
    checkInvitationStatus,
    getRemoteDocuments,
    setProperty,
    involvedParty,
    sending,
    resetForm,
  },
  PartyInvolvedStore: { asSelectOptions, findEmail, findMobilePhone },
  UserStore: { isClaimManager },
  ManagerClaimStore,
  MissionStore,
  t,
}) => {
  const id = isClaimManager ? ManagerClaimStore.id : MissionStore.id
  const [remoteAssessment, setRemoteAssessment] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(true)
  const [retrieveDocuments, setRetrieveDocuments] = useState(false)
  const [grabbing, setGrabbing] = useState(false)

  const onClose = () => {
    setProperty('showRemoteAssessmentForm', false)
    resetForm()
  }
  const onChange = e => {
    let inputType = 'phoneNumber'
    let value = e
    if (e !== undefined && e.target !== undefined) {
      inputType = e.target.name
      value = e.target.value
    }
    setProperty(inputType, value)
  }
  const onChangeIP = e => {
    const email = findEmail(e.target.value)
    const phoneNumber = findMobilePhone(e.target.value)
    setProperty('email', email ? email : '')
    setProperty('phoneNumber', phoneNumber ? phoneNumber : '')
    setProperty('involvedParty', e.target.value)
  }

  const onSetTermsAndConditions = state => {
    setTermsAndConditions(state)
  }

  const onRemoteAssessment = state => {
    setRemoteAssessment(state)
  }

  const onSetRetrieveDocuments = state => {
    setRetrieveDocuments(state)
  }

  const onRetrieveDocuments = async () => {
    const res = await getRemoteDocuments(id)
    console.log(res)
    if (res.data.message !== null) {
      alert(res.data.message)
    } else {
      onSetRetrieveDocuments(false)
    }
  }

  const onValid = async () => {
    if ((await startSession(id)) !== 400) onRemoteAssessment(true)
  }

  const onTermsAndConditions = async () => {
    const res = await checkInvitationStatus(id)
    if (res.data.attributes.expertSessionUrl !== null) {
      window.open(res.data.attributes.expertSessionUrl, '_blank').focus()
    } else {
      onSetTermsAndConditions(false)
    }
  }

  if (!termsAndConditions)
    return (
      <ModalWithBackdrop
        size="modal-md"
        show={showRemoteAssessmentForm}
        onClose={onClose}
        draggable
        close={onClose}
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="mb-0">{t('remoteAssessment.waitingForClient.title')}</h5>
          <button type="button" className="close" onClick={onClose}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <p>{t('remoteAssessment.waitingForClient.description')}</p>
            <p>{t('remoteAssessment.waitingForClient.information')}</p>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-end pb-2 pt-2">
          <Button className="btn btn-secondary" onClick={() => onSetTermsAndConditions(true)}>
            {t('common.close')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )

  if (remoteAssessment)
    return (
      <ModalWithBackdrop
        size="modal-md"
        show={showRemoteAssessmentForm}
        onClose={onClose}
        draggable
        close={onClose}
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="mb-0">{t('remoteAssessment.invitationSent.title')}</h5>
          <button type="button" className="close" onClick={onClose}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <p>{t('remoteAssessment.invitationSent.description')}</p>
            <p>{t('remoteAssessment.invitationSent.information')}</p>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-end pb-2 pt-2">
          <Button
            className="btn btn-secondary"
            onClick={() => onRemoteAssessment(false)}
            loading={sending}
          >
            {t('remoteAssessment.invitationSent.resend')}
          </Button>
          <Button className="btn btn-primary" onClick={onTermsAndConditions} loading={sending}>
            {t('remoteAssessment.invitationSent.start')}
          </Button>
          <Button 
            className="btn btn-primary" 
            onClick={onRetrieveDocuments}
            Loading={sending}
          >
            <i>Retrieve documents</i>
          </Button>
        </div>
      </ModalWithBackdrop>
    )

  return (
    <SelectInvolvedPartyModal
      showModal={showRemoteAssessmentForm}
      onClose={onClose}
      onChange={onChange}
      onValid={onValid}
      onChangeIP={onChangeIP}
      optionsIP={asSelectOptions}
      selectedIP={involvedParty}
      email={email}
      phoneNumber={phoneNumber}
      loading={sending}
      retrieveDocuments={retrieveDocuments}
      onRetrieveDocuments={onRetrieveDocuments}
      onRemoteAssessment={onRemoteAssessment}
      remoteAssessment={remoteAssessment}
      onTermsAndConditions={onTermsAndConditions}
      termsAndConditions={termsAndConditions}
      setTermsAndConditions={onSetTermsAndConditions}
    />
  )
}

export default compose(
  withTranslation(),
  inject(
    'MissionStore',
    'UserStore',
    'RemoteAssessmentCtrl',
    'PartyInvolvedStore',
    'ManagerClaimStore',
  ),
  observer,
)(RemoteAssessment)
