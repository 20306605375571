export const DynamicMenuUrlConverter = {
  Claim: {
    MissionData: {
      MissionInformation: id => `/claim/${id}/missionInformation`,
      ClaimInformation: id => `/claim/${id}/claimInformation`,
      ContractInformation: id => `/claim/${id}/contractInformation`,
    },
    Annexes: {
      PartiesInvolved: id => `/claim/${id}/partiesInvolved`,
      SupportingDocuments: id => `/claim/${id}/supportingDocuments`,
    },
    Mission: {
      Notepad: id => `/claim/${id}/notepad`,
      Qualification: id => `/claim/${id}/qualification`,
      Planner: id => `/claim/${id}/planner`,
      RequestMissionCreation: id => `/claim/${id}/requestMissionCreationClaim`,
      Overran: id => `/claim/${id}/overrun`,
    },
    Communication: {
      Messaging: id => `/claim/${id}/messaging`,
      Events: id => `/claim/${id}/events`,
    },
  },
  Mission: {
    MissionData: {
      MissionInformation: id => `/mission/${id}/missionInformation`,
      ClaimInformation: id => `/mission/${id}/claimInformation`,
      ContractInformation: id => `/mission/${id}/contractInformation`,
    },
    Annexes: {
      PartiesInvolved: id => `/mission/${id}/partiesInvolved`,
      SupportingDocuments: id => `/mission/${id}/supportingDocuments`,
    },
    Evaluation: {
      RiskConformity: id => `/mission/${id}/riskConformity`,
      CausesAndCircumstances: id => `/mission/${id}/causesAndCircumstances`,
      DamageDescription: id => `/mission/${id}/damageDescription`,
      ConventionIRSI: id => `/mission/${id}/irsi`,
      Calculation: id => `/mission/${id}/calculation`,
      VentilationREN: id => `/mission/${id}/ventilationREN`,
      Injuries: id => `/mission/${id}/injuries`,
      Conclusion: id => `/mission/${id}/conclusion`,
      Statement: id => `/mission/${id}/statement`,
      WorkRequest: id => `/mission/${id}/workRequest`,
      AgreementProtocol: id => `/mission/${id}/agreementProtocol`,
      Rates: id => `/mission/${id}/rates`,
      Notepad: id => `/mission/${id}/notepad`,
      StickyNote: id => `/mission/${id}/stickyNote`,
    },
    Report: {
      GenerateReport: id => `/mission/${id}/generateReport`,
    },
    Communication: {
      Messaging: id => `/mission/${id}/messaging`,
      Events: id => `/mission/${id}/events`,
    },
  },
  Parameters: {
    Settings: {
      GeneralInformation: () => `/parameters/generalInformation`,
      PartiesInvolved: () => `/parameters/partiesInvolved`,
      UserManagement: () => `/parameters/userManagement`,
      ShiftManagement: () => `/parameters/shiftManagement`,
      ExternalExpertiseFirm: () => `/parameters/externalExpertiseFirm`,
    },
    Network: {
      NetworkManagement: () => `/parameters/networkManagement`,
    },
    Application: {
      ApplicationVersion: () => `/parameters/applicationVersion`,
    },
    Help: {
      DownloadTeamViewer: () => `/parameters/downloadTeamViewer`,
    },
  },
  toLowerCaseOption: option => {
    return option.charAt(0).toLowerCase() + option.slice(1)
  },
}
