import React from 'react'
import { inject, observer } from 'mobx-react'

import UserDropdown from './UserDropdown'
import LanguageSection from './LanguageSection'
import MissionLinks from './MissionLinks'

class DesktopMenu extends React.Component {
  render() {
    const {
      onChangeLanguage,
      menu,
      UserStore: { fullName, mode, modes },
      UiCtrl: { language },
    } = this.props
    return (
      <div>
        {fullName ? (
          <ul className="navbar-nav navigation ml-auto">
            <MissionLinks
              tabs={menu}
              user={<UserDropdown {...{ fullName, onChangeLanguage, mode, modes }} />}
            />
          </ul>
        ) : (
          <LanguageSection onChangeLanguage={onChangeLanguage} language={language} />
        )}
      </div>
    )
  }
}

export default inject('UiCtrl', 'UserStore')(observer(DesktopMenu))
