import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SmartLink from 'components/SmartLink/SmartLink'

const Corporel = ({ type }) => {
  const { t } = useTranslation()
  if (type === 'D') {
    return (
      <Fragment>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          <SmartLink page="requestMissionCreation">
            {t('mission.sideNav.missionCreation')}
          </SmartLink>
          <SmartLink page="notepad">{t('mission.sideNav.Notepad')}</SmartLink>
        </div>
      </Fragment>
    )
  }
  if (type === 'M') {
    return (
      <Fragment>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          <SmartLink page="requestMissionCreation">
            {t('mission.sideNav.missionCreation')}
          </SmartLink>
          <SmartLink page="notepad">{t('mission.sideNav.Notepad')}</SmartLink>
        </div>
      </Fragment>
    )
  }
}

export default observer(Corporel)
