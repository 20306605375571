import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import { euro } from 'utils/currency'
import CartPackage from './CartPackage'
import { packagesPrice } from 'utils/cart'
import { isNil } from 'ramda'

const GridCapsule = inject(
  'MissionStore',
  'ManagerClaimStore',
)(
  withRouter(
    observer(
      class GridCapsule extends React.Component {
        setRoomContext = (roomType, packageType) => {
          SearchPackageCtrl.setProperty(
            'roomType',
            roomType.override && roomType.override.key ? roomType.override.key : roomType.key,
          )
          SearchPackageCtrl.setProperty('packageType', packageType)
          SearchPackageCtrl.setProperty('search', '')
          SearchPackageCtrl.setProperty('searching', false)
          SearchPackageCtrl.setProperty('activeSearch', false)
        }

        render() {
          const {
            open,
            room,
            packageType,
            match: {
              params: { id, type },
            },
            MissionStore: { isMissionClosed },
            ManagerClaimStore: { isClaimClosed },
          } = this.props
          const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

          if (!open) return null

          return (
            <div className="room">
              <div className="row no-gutters align-items-center mt-2 mb-2">
                <div className="col-7 pl-4 font-weight-bold">
                  {room.cartRoomType.override && room.cartRoomType.override.key
                    ? room.cartRoomType.override.display
                    : room.cartRoomType.label}
                  :
                </div>
                <div className="col-2" />
                <div className="col-2" />
                <div className="col-1 text-right">
                  {!isClosed && (
                    <NavLink
                      className="btn btn-outline-primary rounded-circle"
                      to={`/${type}/${id}/calculation/searchPackage`}
                      onClick={this.setRoomContext.bind(this, room.cartRoomType, packageType)}
                    >
                      <i className="fa fa-plus" />
                    </NavLink>
                  )}
                </div>
              </div>
              {room.packages.map(packageData => {
                return <CartPackage data={packageData} key={`cart-package-${packageData.id}`} />
              })}
            </div>
          )
        }
      },
    ),
  ),
)

const Price = observer(({ rooms, type, selfRepair = false, canSelfRepair = true }) => {
  if (type === 'DAT_LEAK' && !canSelfRepair) return null

  return <React.Fragment>{euro(packagesPrice(rooms, selfRepair))}</React.Fragment>
})

class CollapseList extends React.Component {
  state = { open: true }
  open = () => {
    const { rooms } = this.props

    if (rooms.length > 0) this.setState({ open: !this.state.open })
  }

  allowedSelfRepair(gtas, type, claim, cfa) {
    if (type !== 'DAT_LEAK') {
      return true
    }
    //CFA & CFI
    if (
      type === 'DAT_LEAK' &&
      !isNil(cfa) &&
      !isNil(cfa.cfi) &&
      !isNil(cfa.cfi.gta) &&
      gtas.includes(cfa.cfi.gta)
    ) {
      return true
    }

    //CFM
    if (type === 'DAT_LEAK' && !isNil(claim) && !isNil(claim.gta) && gtas.includes(claim.gta)) {
      return true
    }
    return false
  }

  render() {
    const { open } = this.state
    const {
      title,
      children,
      type,
      rooms,
      UserStore: { isExpert },
      MissionStore: { isMissionClosed, cfa },
      ManagerClaimStore: { isClaimClosed, claim },
    } = this.props
    const isClosed = isExpert ? isMissionClosed : isClaimClosed
    const MATMUT_GTAS = ['J01', 'J02', '418', '418E', '1311']
    let canSelfRepair = this.allowedSelfRepair(MATMUT_GTAS, type, claim, cfa)

    return (
      <div className="mt-2 mb-2">
        <div className="section-title row no-gutters align-items-center">
          <div className="col-7" onClick={this.open}>
            <span className="p-1 mr-2">
              <i className={classNames('fa', { 'fa-angle-down': !open, 'fa-angle-up': open })} />
            </span>
            <span className="font-weight-bold">{title}</span>
          </div>

          <div className="col-2 text-right d-none d-lg-block" onClick={this.open}>
            <Price rooms={rooms} type={type} />
          </div>

          <div className="col-2 text-right d-none d-lg-block" onClick={this.open}>
            <Price rooms={rooms} type={type} selfRepair canSelfRepair={canSelfRepair} />
          </div>

          <div className="col-4 d-inline-block d-lg-none" />

          <div className="col-1 text-right">{isClosed ? null : children}</div>
        </div>

        {rooms.map((room, index) => {
          return (
            <GridCapsule open={open} room={room} key={`cart-room-${index}`} packageType={type} />
          )
        })}
      </div>
    )
  }
}

export default inject('MissionStore', 'ManagerClaimStore', 'UserStore')(observer(CollapseList))
