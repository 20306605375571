import React from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'

import PageLayout from 'components/PageLayout/PageLayout'
import EditFirm from '../components/EditFirm'

const EditExternalExpertiseFirm = () => {
  return (
    <div>
      <EditFirm />
    </div>
  )
}

export default compose(PageLayout, observer)(EditExternalExpertiseFirm)
