import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SmartLink from 'components/SmartLink/SmartLink'

const CommunicationIncendie = ({ UserStore: { customer }, type }) => {
  const { t } = useTranslation()
  const hasExpertiseSimplify = customer.hasContract('ES')
  if (type === 'D') {
    return (
      <Fragment>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="RiskConformity">{t('mission.sideNav.RiskConformity')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="CausesAndCircumstances">
              {t('mission.sideNav.CausesAndCircumstances')}
            </SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="DamageDescription">{t('mission.sideNav.DamageDescription')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="damageDescriptionVehicle">
              {t('mission.sideNav.damageDescriptionVehicle')}
            </SmartLink>
          )}
          <SmartLink page="calculation">{t('mission.sideNav.Calculation')}</SmartLink>
          <SmartLink page="conclusion">{t('mission.sideNav.Conclusion')}</SmartLink>
        </div>
      </Fragment>
    )
  }
  if (type === 'M') {
    return (
      <Fragment>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="RiskConformity">{t('mission.sideNav.RiskConformity')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="causesAndCircumstances">
              {t('mission.sideNav.CausesAndCircumstances')}
            </SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="DamageDescription">{t('mission.sideNav.DamageDescription')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="damageDescriptionVehicle">
              {t('mission.sideNav.damageDescriptionVehicle')}
            </SmartLink>
          )}
          <SmartLink page="calculation">{t('mission.sideNav.Calculation')}</SmartLink>
          <SmartLink page="conclusion">{t('mission.sideNav.Conclusion')}</SmartLink>
        </div>
      </Fragment>
    )
  }
}

export default inject('UserStore', 'MissionStore')(observer(CommunicationIncendie))
