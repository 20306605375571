import React from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { DynamicMenuUrlConverter } from 'components/SideNav/Converters/DynamicMenuUrlConverter'

import ProtectedLink from 'components/ProtectedLink/ProtectedLink'

class ParametersMobile extends React.Component {
  render() {
    const {
      t,
      menu,
      userStore,
      UiCtrl: { toggleSideNav },
    } = this.props
    const onClick = () => toggleSideNav(false)

    const drawTab = (group, i) => {
      return (
        <h5 className={`pt-3 ${i === 0 ? '' : 'border-top mt-2'}`} key={i}>
          {this.props.t(`parameters.sideNav.${group}`)}
        </h5>
      )
    }

    const drawOption = (group, option, j) => {
      const lowerCaseOption = DynamicMenuUrlConverter.toLowerCaseOption(menu[group][option])
      let link
      switch (lowerCaseOption) {
        case 'generalInformation':
        case 'applicationVersion':
        case 'downloadTeamViewer':
        case 'externalExpertiseFirm':
          link = (
            <div className="nav flex-column nav-pills" key={j}>
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/parameters/${lowerCaseOption}`}
                key={option}
                onClick={onClick}
              >
                {t(`parameters.sideNav.${menu[group][option]}`)}
              </NavLink>
            </div>
          )
          break
        default:
          link = (
            <div className="nav flex-column nav-pills" key={j}>
              <ProtectedLink
                to={`/parameters/${lowerCaseOption}`}
                condition={!userStore[0]}
                key={option}
                onClick={onClick}
              >
                {[t(`parameters.sideNav.${menu[group][option]}`), userStore.shift()]}
              </ProtectedLink>
            </div>
          )
          break
      }
      return link
    }

    return (
      <div className="side-nav">
        {Object.keys(menu).map((group, i) => [
          drawTab(group, i),
          Object.keys(menu[group]).map((option, j) => drawOption(group, option, j)),
        ])}
      </div>
    )
  }
}

export default compose(inject('UiCtrl'), withTranslation(), observer)(ParametersMobile)
