import React, { Fragment, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import useOutsideAlerter from 'hooks/useOutsideAlerter'
import DashboardWidgetCtrl from 'stores/Common/view/DashboardWidgetCtrl'

const DropdownFilters = observer(() => {
  const {
    currentFilter,
    defaultAvailableFilters,
    customAvailableFilters,
    setDefaultCurrentFilter,
    setCurrentFilter,
  } = DashboardWidgetCtrl

  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const outSideRef = useRef(null)
  useOutsideAlerter(outSideRef, () => setShow(false))

  const openDropdown = () => setShow(prevShow => !prevShow)

  const changeCurrentFilter = filter => {
    setCurrentFilter(filter, true)
    setShow(prevShow => !prevShow)
  }

  const setDefaultFilter = () => {
    setDefaultCurrentFilter()
    setShow(prevShow => !prevShow)
  }

  return (
    <div ref={outSideRef} className="flex-grow-1">
      <input
        type="text"
        name="dropdown-select-filter"
        className="form-control clickable"
        onClick={openDropdown}
        value={currentFilter.nameDisplayed}
        onChange={() => {}}
        autoComplete="off"
      />

      <div className={classNames('dropdown-menu w-100', { 'zindex-modal show': show })}>
        {defaultAvailableFilters !== undefined &&
          defaultAvailableFilters.length > 0 &&
          defaultAvailableFilters.map(filter => (
            <div
              key={filter.id}
              className={`dropdown-item clickable mission-${filter.name}`}
              onClick={() => changeCurrentFilter(filter)}
            >
              <div>{t(`dashboard.filter.${filter.name}`)}</div>
            </div>
          ))}
        <div className="dropdown-item clickable" onClick={() => setDefaultFilter()}>
          <div>{t('dashboard.filter.allMissions')}</div>
        </div>
        {customAvailableFilters !== undefined && customAvailableFilters.length > 0 && (
          <Fragment>
            <div className="dropdown-divider" />
            {customAvailableFilters.map(filter => (
              <div
                className="dropdown-item clickable"
                onClick={() => changeCurrentFilter(filter)}
                key={filter.id}
              >
                <div>{filter.name}</div>
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  )
})

const DropdownSelectFilters = () => {
  const { t } = useTranslation()
  return (
    <div className="input-group">
      <div className="input-group-prepend d-flex">
        <span className="input-group-text bg-primary text-white">
          {t('dashboard.filter.form.filters')}
        </span>
      </div>
      <DropdownFilters t={t} />
    </div>
  )
}

export default observer(DropdownSelectFilters)
