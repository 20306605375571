import { observable, action, decorate, computed } from 'mobx'
import i18next from 'i18next'

import { uid } from 'utils'
import {
  optionsAnnex,
  optionsPrejudices,
} from 'pages/Mission/pages/Prejudice/PrejudiceOptionsDatas'

const mapProps = ({ id, cde, totalWithoutVAT, vatRate, obsolescenceRate, obsolescenceReturn }) => ({
  id,
  cde,
  totalWithoutVAT,
  vatRate,
  obsolescenceRate,
  obsolescenceReturn,
})

class PrejudiceTable {
  prejudices = []
  annexes = []
  isExpanded = false

  cde = ''
  id = null

  constructor(data) {
    this.prejudices = data.prejudices ? data.prejudices.map(mapProps) : []
    this.annexes = data.annexes ? data.annexes.map(mapProps) : []

    this.cde = data.cde || ''
    this.id = data.id || uid()
  }

  get annexOptions() {
    // TODO hard data
    return optionsAnnex
      .map(value => ({ value, label: i18next.t(`mission.prejudice.optionsAnnex.${value}`) }))
      .filter(({ value }) => !this.annexes.map(({ cde }) => cde).includes(value))
  }

  get prejudicesOptions() {
    // TODO hard data
    return (
      optionsPrejudices[this.cde] &&
      optionsPrejudices[this.cde]
        .map(value => ({ value, label: i18next.t(`mission.prejudice.options.${value}`) }))
        .filter(({ value }) => !this.prejudices.map(({ cde }) => cde).includes(value))
    )
  }

  get prejudicesComputed() {
    return this.prejudices.map(row => ({
      ...row,
      totalWithVAT: Number(row.totalWithoutVAT) * (1 + Number(row.vatRate) / 100),
      obsolescenceWithVAT:
        (Number(row.totalWithoutVAT) *
          (1 + Number(row.vatRate) / 100) *
          Number(row.obsolescenceRate)) /
        100,
      obsolescenceWithoutVAT: (Number(row.totalWithoutVAT) * Number(row.obsolescenceRate)) / 100,
      immediateCompensation: Number(row.totalWithoutVAT) * (1 - Number(row.obsolescenceRate) / 100),
    }))
  }
  get annexComputed() {
    return this.annexes.map(row => ({
      ...row,
      totalWithVAT: Number(row.totalWithoutVAT) * (1 + Number(row.vatRate) / 100),
      obsolescenceWithVAT:
        (Number(row.totalWithoutVAT) *
          (1 + Number(row.vatRate) / 100) *
          Number(row.obsolescenceRate)) /
        100,
      obsolescenceWithoutVAT: (Number(row.totalWithoutVAT) * Number(row.obsolescenceRate)) / 100,
      immediateCompensation: Number(row.totalWithoutVAT) * (1 - Number(row.obsolescenceRate) / 100),
    }))
  }

  get totalWithVAT() {
    return (
      this.prejudices.reduce(
        (acc, cur) => acc + Number(cur.totalWithoutVAT) * (1 + Number(cur.vatRate) / 100),
        0,
      ) +
      this.annexes.reduce(
        (acc, cur) => acc + Number(cur.totalWithoutVAT) * (1 + Number(cur.vatRate) / 100),
        0,
      )
    )
  }

  get totalWithoutVAT() {
    return (
      this.prejudices.reduce((acc, cur) => acc + Number(cur.totalWithoutVAT), 0) +
      this.annexes.reduce((acc, cur) => acc + Number(cur.totalWithoutVAT), 0)
    )
  }

  get totalObsolescenceWithoutVAT() {
    return (
      this.prejudices.reduce(
        (acc, cur) => acc + (Number(cur.totalWithoutVAT) * Number(cur.obsolescenceRate)) / 100,
        0,
      ) +
      this.annexes.reduce(
        (acc, cur) => acc + (Number(cur.totalWithoutVAT) * Number(cur.obsolescenceRate)) / 100,
        0,
      )
    )
  }

  get totalObsolescenceWithVAT() {
    return (
      this.prejudices.reduce(
        (acc, cur) =>
          acc +
          (Number(cur.totalWithoutVAT) *
            (1 + Number(cur.vatRate) / 100) *
            Number(cur.obsolescenceRate)) /
            100,
        0,
      ) +
      this.annexes.reduce(
        (acc, cur) =>
          acc +
          (Number(cur.totalWithoutVAT) *
            (1 + Number(cur.vatRate) / 100) *
            Number(cur.obsolescenceRate)) /
            100,
        0,
      )
    )
  }

  get immediateCompensationTotal() {
    return (
      this.prejudices.reduce(
        (acc, cur) => acc + Number(cur.totalWithoutVAT) * (1 - Number(cur.obsolescenceRate) / 100),
        0,
      ) +
      this.annexes.reduce(
        (acc, cur) => acc + Number(cur.totalWithoutVAT) * (1 - Number(cur.obsolescenceRate) / 100),
        0,
      )
    )
  }

  get totalORWithoutVAT() {
    return (
      this.prejudices.reduce((acc, cur) => acc + Number(cur.obsolescenceReturn), 0) +
      this.annexes.reduce((acc, cur) => acc + Number(cur.obsolescenceReturn), 0)
    )
  }

  get totalORWithVAT() {
    return (
      this.prejudices.reduce(
        (acc, cur) => acc + Number(cur.obsolescenceReturn) * (1 + Number(cur.vatRate) / 100),
        0,
      ) +
      this.annexes.reduce(
        (acc, cur) => acc + Number(cur.obsolescenceReturn) * (1 + Number(cur.vatRate) / 100),
        0,
      )
    )
  }

  get asJson() {
    return {
      id: this.id,
      prejudices: this.prejudicesComputed,
      annexes: this.annexComputed,
      cde: this.cde,
      totals: {
        totalWithoutVAT: this.totalWithoutVAT,
        totalWithVAT: this.totalWithVAT,
        totalObsolescenceWithVAT: this.totalObsolescenceWithVAT,
        totalObsolescenceWithoutVAT: this.totalObsolescenceWithoutVAT,
        immediateCompensationTotal: this.immediateCompensationTotal,
        label: i18next.t(`mission.prejudice.options.${this.cde}`),
        totalORWithoutVAT: this.totalORWithoutVAT,
        totalORWithVAT: this.totalORWithVAT,
      },
    }
  }

  addPrejudice = cde => {
    this.prejudices.push({
      id: uid(),
      cde,
      totalWithoutVAT: 0,
      vatRate: 20,
      obsolescenceRate: 0,
      obsolescenceReturn: 0,
    })
  }

  addAnnex = cde => {
    this.annexes.push({
      id: uid(),
      cde,
      totalWithoutVAT: 0,
      vatRate: 20,
      obsolescenceRate: 0,
      obsolescenceReturn: 0,
    })
  }

  editDammages = (id, newValue, isPrejudice = true) => {
    const row = isPrejudice
      ? this.prejudices.find(row => row.id === id)
      : this.annexes.find(row => row.id === id)
    if (row) row.totalWithoutVAT = newValue
  }

  editRateObsolescence = (id, newValue, isPrejudice = true) => {
    const row = isPrejudice
      ? this.prejudices.find(row => row.id === id)
      : this.annexes.find(row => row.id === id)
    if (row) row.obsolescenceRate = Number(newValue)
  }

  editTVA = (id, newValue, isPrejudice = true) => {
    const row = isPrejudice
      ? this.prejudices.find(row => row.id === id)
      : this.annexes.find(row => row.id === id)
    if (row) row.vatRate = newValue
  }

  editObsolescenceReturn = (id, newValue, isPrejudice = true) => {
    const row = isPrejudice
      ? this.prejudices.find(row => row.id === id)
      : this.annexes.find(row => row.id === id)
    if (row) row.obsolescenceReturn = Number(newValue)
  }

  delete = (id, isPrejudice = true) => {
    if (isPrejudice) {
      this.prejudices = this.prejudices.filter(row => row.id !== id)
    } else {
      this.annexes = this.annexes.filter(row => row.id !== id)
    }
  }

  toggleAccordion = () => {
    this.isExpanded = !this.isExpanded
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedPrejudiceTable = decorate(PrejudiceTable, {
  prejudices: observable,
  annexes: observable,
  isExpanded: observable,

  prejudicesComputed: computed,
  annexComputed: computed,
  annexOptions: computed,
  prejudicesOptions: computed,
  totalWithVAT: computed,
  totalWithoutVAT: computed,
  totalObsolescenceWithoutVAT: computed,
  totalObsolescenceWithVAT: computed,
  immediateCompensationTotal: computed,
  totalORWithoutVAT: computed,
  totalORWithVAT: computed,
  asJson: computed,

  setProperty: action,
  addPrejudice: action,
  addAnnex: action,
  editDammages: action,
  editTVA: action,
  editRateObsolescence: action,
  editObsolescenceReturn: action,
  delete: action,
  toggleAccordion: action,
})

export default DecoratedPrejudiceTable
