import { observable, action, decorate, runInAction, computed } from 'mobx'
import { path } from 'ramda'
import {
  getLastBillFirms,
  sendJob,
  getLastBillLink,
  getLastBillCancelReasons,
  cancelJob,
} from 'services/claimManagerForm'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import ManagerClaimStore from '../domain/ManagerClaimStore'
import { fetchEvents } from 'services/events'

class LastBillCtrl {
  currentJob = null
  firms = []
  selectedFirm = ''
  assignationComment = ''
  dueDate = null
  insuredPhoneNumber = ''
  events = []

  showConfirmFirmModal = false
  assigning = false
  loading = false
  loadingLastBillLink = false

  cancelReasons = {}
  showCancelModal = false
  cancelReasonId = ''
  cancelComment = ''
  canceling = false

  resetCtrl = () => {
    this.currentJob = null
    this.firms = []
    this.selectedFirm = ''
    this.assignationComment = ''
    this.insuredPhoneNumber = ''
    this.events = []
    this.dueDate = null
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  selectFirm = firmId => {
    this.selectedFirm = firmId
  }

  setCurrentJob = job => {
    this.currentJob = job
    if (!this.currentJob) return

    if (this.currentJob.statusId === 'draft') {
      this.loadFirm()
    } else {
      this.loadJobEvents()
      this.loadCancelReasons()
    }
  }

  loadFirm = async () => {
    if (!this.currentJob) return

    this.loading = true

    try {
      const { id } = ManagerClaimStore
      const firms = await getLastBillFirms(id, this.currentJob.forQualificationCode)
      runInAction(() => {
        this.loading = false
        this.firms = firms
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
      })
      console.error(error)
      AlertCtrl.alert('danger', 'lastbill.loadFirmError')
    }
  }

  loadJobEvents = async () => {
    if (!this.currentJob) return

    this.loading = true

    try {
      const { id } = ManagerClaimStore
      const jobId = this.currentJob.id
      const res = await fetchEvents(id, true, jobId)
      runInAction(() => {
        this.events = res.map(event => event.attributes)
        this.loading = false
      })
    } catch (err) {
      runInAction(() => {
        this.loading = false
      })
      console.error(err)
      AlertCtrl.alert('danger', 'lastbill.loadFirmEvent')
    }
  }

  loadCancelReasons = async () => {
    if (!this.currentJob) return

    this.loading = true
    const cancelReasons = { '': 'Veillez sélectionner une raison' }

    try {
      const { id } = ManagerClaimStore
      const res = await getLastBillCancelReasons(id, this.currentJob.id)
      runInAction(() => {
        res.forEach(reason => {
          cancelReasons[reason.id] = reason.title
        })
        this.cancelReasons = cancelReasons
        this.loading = false
      })
    } catch (err) {
      runInAction(() => {
        this.cancelReasons = cancelReasons
        this.loading = false
      })
      console.error(err)
    }
  }

  sendJob = async () => {
    if (!this.currentJob) return

    this.assigning = true
    try {
      const { id, reloadClaim, getLastBillJobById } = ManagerClaimStore
      const jobId = this.currentJob.id

      await sendJob({
        wan: id,
        jobId: jobId,
        comment: this.assignationComment,
        dueDate: this.dueDate,
        insuredPhoneNumber: this.insuredPhoneNumber,
        selectedFirm: this.selectedFirm,
      })

      await reloadClaim()
      this.setCurrentJob(getLastBillJobById(jobId))

      runInAction(() => {
        this.assigning = false
        this.showConfirmFirmModal = false
        this.assignationComment = ''
      })
    } catch (error) {
      runInAction(() => {
        this.assigning = false
      })
      console.warn(error)
      AlertCtrl.alert('danger', 'lastbill.sendJobError')
    }
  }

  cancelJob = async () => {
    if (!this.currentJob) return

    this.canceling = true
    try {
      const { id, reloadClaim, getLastBillJobById } = ManagerClaimStore
      const jobId = this.currentJob.id

      await cancelJob({
        wan: id,
        jobId: jobId,
        cancelReasonId: this.cancelReasonId,
        cancelComment: this.cancelComment,
      })

      await reloadClaim()
      this.setCurrentJob(getLastBillJobById(jobId))

      runInAction(() => {
        this.canceling = false
        this.showCancelModal = false
        this.cancelReasonId = ''
        this.cancelComment = ''
      })
    } catch (error) {
      runInAction(() => {
        this.canceling = false
      })
      console.warn(error)
      AlertCtrl.alert('danger', 'lastbill.cancelJobError')
    }
  }

  goToLastBill = async () => {
    try {
      this.loadingLastBillLink = true
      const { id } = ManagerClaimStore

      const res = await getLastBillLink(id, this.currentJob.id)
      runInAction(() => {
        this.loadingLastBillLink = false
      })
      window.open(res.link, '_blank')
    } catch (err) {
      console.log(err)
    }
  }

  get markers() {
    if (!this.firms) return []

    const markers = this.firms.map(firm => ({
      id: firm.id,
      name: firm.name,
      position: {
        lat: path(['address', 'geometry', 'lat'], firm),
        lng: path(['address', 'geometry', 'long'], firm),
      },
    }))

    return markers.filter(firm => firm.position.lat && firm.position.lng)
  }
}

const DecoratedLastBill = decorate(LastBillCtrl, {
  currentJob: observable,
  firms: observable,
  events: observable,
  selectedFirm: observable,
  showConfirmFirmModal: observable,
  assignationComment: observable,
  dueDate: observable,
  insuredPhoneNumber: observable,
  assigning: observable,
  loading: observable,
  loadingLastBillLink: observable,

  canceling: observable,
  showCancelModal: observable,
  cancelReasonId: observable,
  cancelComment: observable,

  markers: computed,

  setProperty: action,
  resetCtrl: action,
  loadFirm: action,
  loadJobEvents: action,
  sendJob: action,
  selectFirm: action,
  setCurrentJob: action,
  goToLastBill: action,
  cancelJob: action,
})

export default new DecoratedLastBill()
