import request from 'services/httpRequest'

export const getDashboard = params => {
  return request({
    method: 'GET',
    url: '/dashboards/v1/dashboard',
    params,
  })
    .then(res => {
      const { data, meta, links } = res
      return { dashboardData: data.attributes, meta, links }
    })
    .catch(err => {
      if (err) return Promise.reject(err.data)
    })
}
