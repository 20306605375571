import UiCtrl from 'stores/Common/view/UiCtrl'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import DashboardWidgetCtrl from 'stores/Common/view/DashboardWidgetCtrl'
import DashboardWidgetFilter from 'stores/Common/domain/DashboardWidgetFilter'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import DashboardWidgetSortingCondition from 'stores/Common/domain/DashboardWidgetSortingCondition'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import CommonStore from 'stores/Common/domain/CommonStore'
import UserStore from 'stores/Common/domain/UserStore'
import MessagingStore from 'stores/Messaging/MessagingStore'
import BlocNoteStore from 'stores/BlocNote/BlocNoteStore'
import PlannerStore from 'stores/Claim/PlannerStore'
import OverrunStore from 'stores/Claim/OverrunStore'
import CreateMissionCtrl from 'stores/Claim/view/CreateMissionCtrl'
import CreateMissionFormCtrl from 'stores/Claim/view/CreateMissionFormCtrl'
import CalendarExpertStore from 'stores/Calendar/CalendarExpertStore'
import EventCtrl from 'stores/Common/view/EventCtrl'
import AppointmentCtrl from 'stores/Calendar/AppointmentCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import InboxStore from 'stores/Messaging/InboxStore'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import DamageDescriptionCtrl from 'stores/Mission/view/DamageDescriptionCtrl'
import VehicleDamageDescriptionCtrl from './Mission/view/VehicleDamageDescriptionCtrl'
import PrejudiceStore from 'stores/Mission/domain/PrejudiceStore'
import RiskConformityCtrl from 'stores/Mission/view/RiskConformityCtrl'
import WorkRequestCtrl from 'stores/Mission/view/WorkRequestCtrl'
import NewMissionCtrl from './Mission/view/NewMissionCtrl'
import NewMissionClaimCtrl from './Claim/view/NewMissionClaimCtrl'
import AgreementProtocolCtrl from 'stores/Mission/view/AgreementProtocolCtrl'
import CausesAndCircumstancesCtrl from 'stores/Mission/view/CausesAndCircumstancesCtrl'
import IrsiCtrl from 'stores/Mission/view/IrsiCtrl'
import PvCtrl from 'stores/Mission/view/PvCtrl'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import SinisterCtrl from 'stores/ManagerClaim/view/SinisterCtrl'
import OrientationCtrl from 'stores/ManagerClaim/view/OrientationCtrl'
import LastBillCtrl from 'stores/ManagerClaim/view/LastBillCtrl'
import OrientationReportCtrl from 'stores/ManagerClaim/view/OrientationReportCtrl'
import InstructionCtrl from 'stores/ManagerClaim/view/InstructionCtrl'
import RemoteAssessmentCtrl from './RemoteAssessment/RemoteAssessmentCtrl'

export default {
  UiCtrl,
  AlertCtrl,
  DashboardCtrl,
  DashboardWidgetCtrl,
  DashboardWidgetFilter,
  DashboardWidgetFilterCondition,
  DashboardWidgetSortingCondition,
  MissionStore,
  ClaimStore,
  ManagerClaimStore,
  CommonStore,
  UserStore,
  PlannerStore,
  OverrunStore,
  InboxStore,
  MessagingStore,
  BlocNoteStore,
  CreateMissionCtrl,
  CreateMissionFormCtrl,
  CalendarExpertStore,
  EventCtrl,
  AppointmentCtrl,
  CartStore,
  ConclusionCtrl,
  ReportCtrl,
  DamageDescriptionCtrl,
  VehicleDamageDescriptionCtrl,
  PrejudiceStore,
  RiskConformityCtrl,
  WorkRequestCtrl,
  NewMissionCtrl,
  NewMissionClaimCtrl,
  AgreementProtocolCtrl,
  CausesAndCircumstancesCtrl,
  IrsiCtrl,
  PvCtrl,
  PartyInvolvedStore,
  PartyInvolvedCtrl,
  SinisterCtrl,
  OrientationCtrl,
  LastBillCtrl,
  OrientationReportCtrl,
  InstructionCtrl,
  RemoteAssessmentCtrl,
}
