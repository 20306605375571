import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import ClaimButtons from 'pages/Claim/components/ClaimButtons'
import { formatWan } from 'utils'
import { DynamicMenuUrlConverter } from 'components/SideNav/Converters/DynamicMenuUrlConverter'

class ClaimMobile extends React.Component {
  onClick = () => this.props.UiCtrl.toggleSideNav(false)

  render() {
    const {
      t,
      menu,
      match: {
        params: { id },
      },
      ClaimStore: { isClaimOverran, validMissions, isQualified, claimCorporel },
      MessagingStore: { notifications },
    } = this.props
    return (
      <Fragment>
        {Object.keys(menu).map(
          category =>
            (category === 'Annexes' ||
              category === 'Mission' ||
              category === 'MissionData' ||
              category === 'Communication') && (
              <React.Fragment key={category}>
                <h5 className="pt-3 nav-border-bottom">{t(`claim.sideNav.${category}`)}</h5>
                {menu[category].map(path => (
                  <React.Fragment key={path}>
                    {path === 'Planner' ? (
                      <ProtectedLink
                        className="nav-link"
                        activeClassName="active"
                        to={DynamicMenuUrlConverter.Claim.Mission.Planner(id)}
                        onClick={this.onClick}
                        condition={!isQualified}
                      >
                        {t('claim.sideNav.missions.planner')}
                      </ProtectedLink>
                    ) : path === 'RequestMissionCreationClaim' ? (
                      <React.Fragment>
                        {claimCorporel && (
                          <ProtectedLink
                            className="nav-link"
                            activeClassName="active"
                            to={DynamicMenuUrlConverter.Claim.Mission.RequestMissionCreation(id)}
                            onClick={this.onClick}
                          >
                            {t('claim.sideNav.missions.missionCreation')}
                          </ProtectedLink>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {path === 'missions' && isClaimOverran ? (
                          <div className="nav-link protected">
                            {t('claim.sideNav.missions.overran')}
                          </div>
                        ) : (
                          <NavLink
                            className="nav-link"
                            activeClassName="active"
                            to={DynamicMenuUrlConverter.Claim[category][path](id)}
                            onClick={this.onClick}
                            key={path}
                          >
                            {t(`claim.sideNav.missions.${path}`)}
                            {path === 'Messaging' && notifications > 0 && (
                              <span className="badge badge-danger ml-1" key={path}>
                                {notifications}
                              </span>
                            )}
                          </NavLink>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
                {category === 'Mission' &&
                  validMissions.map(mission => (
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/claim/${id}/currentMission/${mission.cfaWan}`}
                      key={mission.cfaWan}
                    >
                      {formatWan(mission.cfaWan)}
                    </NavLink>
                  ))}
              </React.Fragment>
            ),
        )}
        <div className="mt-4 justify-content-between">
          <ClaimButtons />
        </div>
      </Fragment>
    )
  }
}
export default compose(
  inject('UiCtrl', 'MessagingStore', 'ClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(ClaimMobile)
