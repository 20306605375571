import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'

import Loader from 'components/Loader/Loader'
import CardLayout from 'components/CardLayout/CardLayout'
import Button from 'components/Button/Button'
import Simplified from './Simplified'
import Multiple from './Multiple'

const Ren = ({
  CartStore: {
    conclusionViewSimplified,
    changeAllWithoutSelfRepairPackages,
    changedREN,
    loading,
    saving,
    save,
    setPackagesOriginalData,
    resetPackagesToOriginalREN,
    setProperty: setPropertyCart,
    totalsFromCart: { catalog },
  },
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      resetPackagesToOriginalREN()
    }
    // eslint-disable-next-line
  }, [])

  const changeView = () => {
    if (!conclusionViewSimplified) {
      changeAllWithoutSelfRepairPackages('ren', true)
      changeAllWithoutSelfRepairPackages('companyName', '')
    }
    setPropertyCart('conclusionViewSimplified', !conclusionViewSimplified)
  }
  const saveCart = async () => {
    try {
      await save('cart', true)
      setPackagesOriginalData()
    } catch (error) {
      console.warn(error)
    }
  }

  const options = [
    {
      value: 'true',
      label: t('mission.conclusion.ren.ren'),
    },
    {
      value: 'false',
      label: t('mission.conclusion.ren.externalCompany'),
    },
  ]

  if (loading) return <Loader />
  else if (
    catalog.totalEmbellishmentWithVAT +
      catalog.totalPropertyWithVAT +
      catalog.totalLeakWithVAT +
      catalog.totalOutdoorWithVAT ===
    0
  ) {
    return (
      <CardLayout>
        <div className="text-center">{t('mission.conclusion.ren.empty')}</div>
      </CardLayout>
    )
  }

  return (
    <CardLayout>
      <Prompt when={changedREN} message={t('common.quitWithoutSave')} />

      <strong>{t('mission.conclusion.ren.ventilation')}</strong>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button className="btn btn-light" onClick={changeView}>
          {t(
            conclusionViewSimplified
              ? 'mission.conclusion.ren.multiple'
              : 'mission.conclusion.ren.simplified',
          )}
        </Button>
      </div>

      {conclusionViewSimplified ? <Simplified options={options} /> : <Multiple options={options} />}

      <div className="d-flex flex-row-reverse">
        <Button
          className="btn btn-primary"
          onClick={saveCart}
          disabled={!changedREN}
          loading={saving}
        >
          {t('common.save')}
        </Button>
      </div>
    </CardLayout>
  )
}

export default inject('CartStore')(observer(Ren))
