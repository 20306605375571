import React from 'react'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import classNames from 'classnames'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'

class SelectVAT extends React.PureComponent {
  // Customer config key to look for on the api
  customerConfigKey = 'VATRatesPicker'
  mounted = false

  // In case that the api returns an error, the display will be done with default values
  defaultVatValues = [
    { value: '0', text: '0' },
    { value: '5.5', text: '5,5' },
    { value: '10', text: '10' },
    { value: '20', text: '20' },
  ]

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      values: this.defaultVatValues,
    }
  }

  onChange = e => {
    const vat = parseFloat(e.target.value)
    this.props.onChange({ target: { name: 'vat', value: vat } })
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      this.setState({ loading: true })
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, this.customerConfigKey)

      runInAction(() => {
        if (this.mounted) {
          const arrayValues = CustomerConfigStore.getValue(key)
          const newValues = []
          arrayValues.map(vatTax => newValues.push({ value: vatTax, text: vatTax }))
          this.setState({ values: newValues, loading: false })
        }
      })
    } catch (err) {
      this.setState({ values: this.defaultVatValues, loading: false })
    }
  }

  componentDidMount() {
    this.mounted = true
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { vat, style, selectWidth, disabled } = this.props
    const loadingSpinner = <span className={classNames('fa fa-spinner fa-spin')} />

    if (this.state.loading === false) {
      return (
        <span style={style}>
          <select
            name="vat"
            className="form-control d-inline mr-1 text-right"
            value={vat}
            onChange={this.onChange}
            style={{ width: selectWidth }}
            disabled={disabled}
          >
            {this.state.values.map((option, index) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          %
        </span>
      )
    }

    return loadingSpinner
  }
}

SelectVAT.defaultProps = {
  disabled: false,
  selectWidth: '63px',
  vat: 0,
  style: {},
  onChange: () => {},
}

SelectVAT.propTypes = {
  disabled: PropTypes.bool,
  selectWidth: PropTypes.string,
  onChange: PropTypes.func,
  vat: PropTypes.number,
  style: PropTypes.object,
}

export default compose(inject('UserStore'), observer)(SelectVAT)
