import { observable, action, decorate, runInAction } from 'mobx'

import MissionStore from 'stores/Mission/domain/MissionStore'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import { fetchRiskConformity, saveRiskConformity } from 'services/missionForm'
import Form from 'utils/dynform/Form'

class RiskConformity {
  loading = false
  form = null

  loadData = async (wan, riskConformityData) => {
    this.loading = true
    try {
      const form = await fetchRiskConformity(wan)
      form.name = 'riskConformity'
      runInAction(() => {
        this.form = new Form({ form, data: riskConformityData })
      })
    } catch (err) {
      console.warn(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    const res = await saveRiskConformity(wan, formData)
    MissionStore.setProperty('policy', res.cfi.contract)
    MissionStore.updateRiskConformirty(res.riskConformity)
    ConclusionCtrl.loadData(res.wan, res)
  }
}

const DecoratedRiskConformity = decorate(RiskConformity, {
  loading: observable,
  form: observable,

  loadData: action,
  save: action,
})

export default new DecoratedRiskConformity()
