import React from 'react'

const TableHeadItem = ({ item }) => {
  return (
    <th scope="col" title={item} className="long-field">
      {item}
    </th>
  )
}

export default TableHeadItem
