import { observable, action, computed, runInAction, decorate, toJS } from 'mobx'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import {
  fetchDashboardWidget,
  postDashboardWidgetFilter,
  deleteDashboardWidgetFilter,
} from 'services/dashboardWidget'
import { filter } from 'ramda'
import UiCtrl from 'stores/Common/view/UiCtrl'
import { BreakPointsWidth } from 'stores/Common/view/DashboardCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import DashboardWidgetFilter from 'stores/Common/domain/DashboardWidgetFilter'
import DashboardWidgetFilterCondition from 'stores/Common/domain/DashboardWidgetFilterCondition'
import DashboardWidgetSortingCondition from 'stores/Common/domain/DashboardWidgetSortingCondition'
import { getDataLocal, setDataLocal, deleteDataLocal } from 'stores/localStorage'

const hiddenHeaders = Object.freeze(['status.value'])

export const DashboardWidgetFilterType = Object.freeze({
  TYPE_CUSTOM: 'custom',
  TYPE_DEFAULT: 'default',
})

export const DashboardWidgetFilterKeyConditions = Object.freeze({
  KEY_SEARCH: 'search',
  KEY_STATUS: 'status',
  KEY_ASSIGNEE_USER_ID: 'mission.assigneeUserId',
  KEY_MISSION_TYPE: 'mission.missionInsurerInformation.missionTypeKey',
})

export const DashboardWidgetFilterTypeConditions = Object.freeze({
  STRING: 'string',
  DATE: 'date',
  DATE_RANGE: 'dateRange',
  NUMBER: 'number',
})

export const DashboardWidgetFilterNames = Object.freeze({
  ALL_MISSIONS: 'allMissions',
})

export const DashboardWidgetFilterValues = Object.freeze({
  ALL_OPEN: 'allOpen',
  ALL: 'all',
})

export const DashboardWidgetSortingKeyConditions = Object.freeze({
  KEY_DEFAULT: 'default',
})

export const DashboardWidgetSortingValues = Object.freeze({
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
})

export const DashboardWidgetSortingTypeConditions = Object.freeze({
  STRING: 'string',
  DATE: 'date',
  NUMBER: 'number',
})

class DashboardWidgetCtrl {
  loading = true

  dashboardId = null
  widgetId = null
  data = null
  headers = null
  pagination = null
  type = null
  configuration = null

  availableFilters = null
  currentFilter = null

  meta = null
  links = null

  pageNumber = 1
  pageSize = 10

  missionsToPrint = []

  displayFilterForm = false

  constructor() {
    this.currentFilter = this.defaultFilter
  }

  get defaultFilter() {
    return new DashboardWidgetFilter({
      filterConditions: this.defaultFilterConditions,
      sortingConditions: this.defaultSortingConditions,
      type: DashboardWidgetFilterType.TYPE_DEFAULT,
      name: DashboardWidgetFilterNames.ALL_MISSIONS,
    })
  }

  get defaultFilterConditions() {
    return [
      this.currentFilterSearchCondition,
      this.currentFilterStatusCondition,
      this.currentFilterMissionTypeCondition,
      this.currentFilterAssigneeUserIdTypeCondition,
    ]
  }

  get defaultSortingConditions() {
    return [this.currentFilterSortedByCondition]
  }

  currentFilterFilterCondition = key => {
    if (this.currentFilter === null || this.currentFilter === undefined) {
      return null
    }

    return this.currentFilter.filterConditions.find(condition => condition.key === key)
  }

  currentFilterSortingCondition = key => {
    if (this.currentFilter === null || this.currentFilter === undefined) {
      return null
    }
    return this.currentFilter.sortingConditions.find(condition => condition.key === key)
  }

  get currentFilterSearchCondition() {
    if (this.currentFilter === null) {
      return this.defaultSearchFilterCondition
    }

    const condition = this.currentFilter.searchCondition
    if (condition === null) {
      return this.defaultSearchFilterCondition
    }
    return condition
  }

  get currentFilterStatusCondition() {
    if (this.currentFilter === null) {
      return this.defaultStatusFilterCondition
    }

    const condition = this.currentFilter.statusCondition
    if (condition === null) {
      return this.defaultStatusFilterCondition
    }
    return condition
  }

  get currentFilterMissionTypeCondition() {
    if (this.currentFilter === null) {
      return this.defaultMissionTypeFilterCondition
    }

    const condition = this.currentFilter.missionTypeCondition
    if (condition === null) {
      return this.defaultMissionTypeFilterCondition
    }
    return condition
  }

  get currentFilterAssigneeUserIdTypeCondition() {
    if (this.currentFilter === null) {
      return this.defaultAssigneeUserIdFilterCondition
    }

    const condition = this.currentFilter.assigneeUserIdCondition
    if (condition === null) {
      return this.defaultAssigneeUserIdFilterCondition
    }
    return condition
  }

  get currentFilterSortedByCondition() {
    if (this.currentFilter === null) {
      return this.defaultSortedByCondition
    }

    const condition = this.currentFilter.sortedByCondition()
    if (condition === null) {
      return this.defaultSortedByCondition
    }
    return condition
  }

  get defaultStatusFilterCondition() {
    return new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_STATUS,
      value: DashboardWidgetFilterValues.ALL_OPEN,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
  }

  get defaultSearchFilterCondition() {
    return new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_SEARCH,
      value: null,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
  }

  get defaultMissionTypeFilterCondition() {
    return new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_MISSION_TYPE,
      value: DashboardWidgetFilterValues.ALL,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
  }

  get defaultAssigneeUserIdFilterCondition() {
    return new DashboardWidgetFilterCondition({
      key: DashboardWidgetFilterKeyConditions.KEY_ASSIGNEE_USER_ID,
      value: DashboardWidgetFilterValues.ALL,
      type: DashboardWidgetFilterTypeConditions.STRING,
    })
  }

  get defaultSortedByCondition() {
    return new DashboardWidgetSortingCondition({
      key: DashboardWidgetSortingKeyConditions.KEY_DEFAULT,
      type: DashboardWidgetSortingTypeConditions.DATE,
      value: DashboardWidgetSortingValues.DESCENDING,
    })
  }

  get defaultAvailableFilters() {
    if (this.availableFilters === undefined || this.availableFilters === null) {
      return null
    }

    return this.availableFilters.filter(availableFilter => availableFilter.isDefaultFilter)
  }

  get customAvailableFilters() {
    if (this.availableFilters === undefined || this.availableFilters === null) {
      return null
    }

    return this.availableFilters.filter(availableFilter => availableFilter.isCustomFilter)
  }

  isCustomFilter = filter => {
    if (filter === undefined || filter === null) {
      return false
    }

    return filter.type === DashboardWidgetFilterType.TYPE_CUSTOM
  }

  availableFiltersByKey = conditionKey => {
    return this.availableFilters.filter(availableFilter => {
      return availableFilter.isDefaultFilter && availableFilter.hasFilterCondition(conditionKey)
    })
  }

  get canDeleteCurrentFilter() {
    return this.currentFilter.isCustomFilter
  }

  localStorageKey = widgetId => {
    return `dashboardWidgetFilter_${widgetId}_${UserStore.id}`
  }

  getDashboardWidget = async (widgetId, parameters) => {
    try {
      const currentLocalFilter = getDataLocal(this.localStorageKey(widgetId))
      const newParameters = {
        filter: currentLocalFilter || this.currentFilter,
        ...parameters,
      }
      const { dashboardWidgetData } = await fetchDashboardWidget(widgetId, newParameters)
      runInAction(() => {
        const dashboardWidgetFilter = getDataLocal(this.localStorageKey(widgetId))

        if (dashboardWidgetData) {
          this.data = dashboardWidgetData.data
          this.widgetId = dashboardWidgetData.id
          this.headers = dashboardWidgetData.headers
          this.pagination = dashboardWidgetData.pagination
          this.meta = dashboardWidgetData.meta
          this.links = dashboardWidgetData.links
          this.type = dashboardWidgetData.type
          this.configuration = dashboardWidgetData.configuration

          this.currentFilter = new DashboardWidgetFilter(dashboardWidgetData.filter.currentFilter)

          this.availableFilters = dashboardWidgetData.filter.filtersAvailable.map(filterData => {
            // Set filter from local storage
            const availableFilter = new DashboardWidgetFilter(filterData)
            if (dashboardWidgetFilter && availableFilter.name === dashboardWidgetFilter.name) {
              this.currentFilter = new DashboardWidgetFilter(dashboardWidgetFilter)
            }
            return availableFilter
          })
        }
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  navigatePage = async link => {
    try {
      const extractPageNumber = link => {
        const regex = /page\[number\]=(\d+)/
        const matches = link.match(regex)
        return matches ? parseInt(matches[1], 10) : null
      }

      const pageNumber = extractPageNumber(link)
      const parameters = {
        'page[number]': pageNumber,
      }
      await this.getDashboardWidget(this.widgetId, parameters)

      runInAction(() => {
        this.pageNumber = pageNumber
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    }
  }

  setPageNumber = async newPageNumber => {
    this.pageNumber = newPageNumber
    const parameters = {
      'page[number]': newPageNumber,
    }
    await this.getDashboardWidget(this.widgetId, parameters)
  }

  //Missions to print

  resetMissionsToPrint = () => {
    this.missionsToPrint = []
  }

  addRemoveMissionPrint = (wan, isAdd = true) => {
    if (isAdd) {
      this.missionsToPrint.push(wan)
    } else {
      this.missionsToPrint = this.missionsToPrint.filter(id => id !== wan)
    }
  }

  isSelectedMission = wan => {
    if (this.missionsToPrint && this.missionsToPrint.length > 0) {
      return this.missionsToPrint.some(id => id === wan)
    }
    return false
  }

  get formatMissionsPrint() {
    return this.missionsToPrint.map(wan => `wans[]=${wan}`).join('&')
  }

  get visibleHeaders() {
    return this.headers.filter(
      header =>
        (header.hidden === undefined || header.hidden === false) && header.field !== 'priority',
    )
  }

  get headersField() {
    return this.visibleHeaders.map(header => {
      return header.field
    })
  }

  get headersInfo() {
    const headers = {}
    this.visibleHeaders.forEach(header => {
      headers[header.field] = header
    })
    return headers
  }

  get headersForSortList() {
    // Remove mission type because it's a select box and not a input string anymorea
    const isSortable = header =>
      header.sortable && header.field !== 'mission.missionInsurerInformations.missionType.value'

    const headers = filter(isSortable, this.headers)
    const isColumnVisible = header => {
      if (header['choosable'] === true && header['choices'].length === 0) {
        return false
      }

      if (header['disable']) {
        const headerFiltered = header.disable.filter(breakpoint => {
          return (
            UiCtrl.windowWidth > BreakPointsWidth[breakpoint]['min'] &&
            UiCtrl.windowWidth < BreakPointsWidth[breakpoint]['max']
          )
        })
        return headerFiltered.length <= 0
      }
      return true
    }

    const headersFiltered = headers.filter(header => hiddenHeaders.indexOf(header.field) === -1)

    return filter(isColumnVisible, headersFiltered)
  }

  getfilterByName = name => {
    return this.availableFilters.filter(filter => {
      return filter.name === name
    })
  }

  addCurrentFilterCondition = condition => {
    this.currentFilter.addFilterCondition(condition)
    this.getDashboardWidget(this.widgetId)
  }

  addCurrentFilterSortingCondition = condition => {
    this.currentFilter.addSortingCondition(condition)
    this.getDashboardWidget(this.widgetId)
  }

  removeCurrentFilterCondition = condition => {
    this.currentFilter.removeFilterCondition(condition.key)
    this.getDashboardWidget(this.widgetId)
  }

  removeCurrentFilterSortingCondition = condition => {
    this.currentFilter.removeSortingCondition(condition.key)
    this.getDashboardWidget(this.widgetId)
  }

  setCurrentFilter = (filter, hideDisplayForm) => {
    this.displayFilterForm = !hideDisplayForm
    this.currentFilter = filter

    setDataLocal(this.localStorageKey(this.widgetId), toJS(filter))
    this.getDashboardWidget(this.widgetId)
  }

  setDefaultCurrentFilter = () => {
    this.currentFilter = null
    this.currentFilter = this.defaultFilter

    this.displayFilterForm = false

    deleteDataLocal(this.localStorageKey(this.widgetId))
    this.getDashboardWidget(this.widgetId)
  }

  setProperty(key, value) {
    this[key] = value
  }

  saveDashboardWidgetFilter = async (filterData, dashboardId) => {
    try {
      const params = {
        filter: filterData,
        dashboardId,
      }
      await postDashboardWidgetFilter(this.widgetId, params)
      runInAction(() => {
        const filter = new DashboardWidgetFilter(filterData)
        this.setCurrentFilter(filter)
        setDataLocal(this.localStorageKey(this.widgetId), toJS(filter))
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    } finally {
      runInAction(() => this.getDashboardWidget(this.widgetId))
    }
  }

  removeDashboardWidgetFilter = async (filterData, dashboardId) => {
    try {
      const params = {
        filter: filterData,
        dashboardId,
      }
      await deleteDashboardWidgetFilter(this.widgetId, params)
      runInAction(() => {
        this.setDefaultCurrentFilter()
        deleteDataLocal(this.localStorageKey(this.widgetId))
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    } finally {
      runInAction(() => this.getDashboardWidget(this.widgetId))
    }
  }

  cleanCurrentFilter = () => {
    this.currentFilter = null
    this.currentFilter = this.defaultFilter
    this.displayFilterForm = false
  }
}

const DecoratedDashboardWidgetCtrl = decorate(DashboardWidgetCtrl, {
  loading: observable,
  widgetId: observable,
  data: observable,
  sortings: observable,
  headers: observable,
  pagination: observable,
  type: observable,
  configuration: observable,
  availableFilters: observable,
  currentFilter: observable,

  meta: observable,
  links: observable,

  missionsToPrint: observable,
  displayFilterForm: observable,

  defaultFilter: computed,
  visibleHeaders: computed,
  headersField: computed,
  headersInfo: computed,
  headersForSortList: computed,
  formatMissionsPrint: computed,

  canDeleteCurrentFilter: computed,
  defaultAvailableFilters: computed,
  customAvailableFilters: computed,

  availableFiltersByKey: action,

  currentFilterFilterCondition: action,
  currentFilterSortingCondition: action,

  getDashboardWidget: action,
  saveDashboardWidgetFilter: action,
  navigatePage: action,
  setProperty: action,
  setPageNumber: action,

  addRemoveMissionPrint: action,
  resetMissionsToPrint: action,
  isSelectedMission: action,

  addCurrentFilterCondition: action,
  removeCurrentFilterCondition: action,
  addCurrentFilterSortingCondition: action,
  removeCurrentFilterSortingCondition: action,
  setCurrentFilter: action,
  setDefaultCurrentFilter: action,
  getfilterByName: action,
  cleanCurrentFilter: action,
})

export default new DecoratedDashboardWidgetCtrl()
