import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import ReactDrawer from 'react-drawer'
import { compose } from 'recompose'

import './SideNav.css'
import 'react-drawer/lib/react-drawer.css'
import ManagerClaimDesktop from './ManagerClaim/SideNavDesktop'
import ManagerClaimMobile from './ManagerClaim/SideNavMobile'

import SideNavParameters from './Parameters/SideNavParameters'
import SideNavMissions from './Mission/SideNavMissions'
import SideNavClaims from './Claim/SideNavClaims'

const WhichComponent = ({ path, sideNavMobile }) => {
  switch (path) {
    case '/parameters':
      return <SideNavParameters sideNavMobile={sideNavMobile} />
    case '/mission/:id':
      return <SideNavMissions sideNavMobile={sideNavMobile} />
    case '/claim/:id':
      return <SideNavClaims sideNavMobile={sideNavMobile} />
    case '/managerClaim/:id':
      return sideNavMobile ? <ManagerClaimMobile /> : <ManagerClaimDesktop />
    default:
      console.warn('Sidenav could not find your path')
      return null
  }
}

const SideNav = ({ UiCtrl: { drawerOpen, toggleSideNav, sideNavMobile }, match: { path } }) => {
  const openSideNav = () => toggleSideNav(!drawerOpen)
  const closeSideNav = () => toggleSideNav(false)

  if (sideNavMobile) {
    return (
      <React.Fragment>
        <button className="btn btn-primary btn-open-nav" onClick={openSideNav}>
          <i className="fa fa-bars" />
        </button>
        <ReactDrawer open={drawerOpen} position="left" onClose={closeSideNav} noOverlay={false}>
          <div className="close-action-drawer">
            <i className="fa fa-times" onClick={closeSideNav} />
          </div>
          <WhichComponent path={path} sideNavMobile={sideNavMobile} />
        </ReactDrawer>
      </React.Fragment>
    )
  }

  return (
    <div className="side-nav">
      <WhichComponent path={path} sideNavMobile={sideNavMobile} />
    </div>
  )
}

export default compose(inject('UiCtrl', 'UserStore'), withRouter, observer)(SideNav)
