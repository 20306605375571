import React, { useRef, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Stage, Layer, Line } from 'react-konva'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { action } from 'mobx'

let useClickOutside = handlerMouseUp => {
  let domNode = useRef()

  useEffect(() => {
    let maybeHandlerMouseUp = event => {
      if (!domNode.current.contains(event.target)) {
        handlerMouseUp()
      }
    }

    document.addEventListener('mouseup', maybeHandlerMouseUp)

    return () => {
      document.removeEventListener('mouseup', maybeHandlerMouseUp)
    }
  })

  return domNode
}

const ModalToSign = ({ modalTitle, onClose, onValidate }) => {
  const [grabbing, setGrabbing] = useState(false)
  const [lines, setLines] = useState([])
  const isDrawing = useRef(false)
  const modalBodyRef = useRef(null)
  const { t } = useTranslation()

  const handleTouchStart = e => {
    isDrawing.current = true
    if (e) {
      const pos = e.target.getStage().getPointerPosition()
      setLines([...lines, { tool: 'pen', points: [pos.x, pos.y, pos.x, pos.y] }])
    }
  }

  const handleTouchMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleTouchEnd = e => {
    isDrawing.current = false
  }

  const handleMouseDown = e => {
    isDrawing.current = true
    if (e) {
      const pos = e.target.getStage().getPointerPosition()
      setLines([...lines, { tool: 'pen', points: [pos.x, pos.y, pos.x, pos.y] }])
    }
  }

  const handleMouseMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    isDrawing.current = false
  }

  const validate = () => {
    const canvasTag = document.getElementsByTagName('canvas')
    const context = canvasTag[0].getContext('2d')
    const canvas = context.canvas
    const imageBase64 = canvas.toDataURL()

    onValidate(imageBase64)
    onClose()
  }

  let domNode = useClickOutside(handleMouseUp)

  return (
    <ModalWithBackdrop
      show={true}
      size="modal-xl"
      close={onClose}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{modalTitle}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body" ref={domNode}>
        <div ref={modalBodyRef} className="border border-primary">
          <Stage
            width={
              modalBodyRef.current ? modalBodyRef.current.clientWidth : window.innerWidth * 0.77
            }
            height={window.innerHeight * 0.7}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            <Layer>
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke="#000000"
                  strokeWidth={8}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation="source-over"
                />
              ))}
            </Layer>
          </Stage>
        </div>
      </div>
      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-warning" onClick={() => setLines([])}>
          <i className="fa fa-eraser" aria-hidden="true"></i> {t('common.erase')}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.close')}
        </button>
        <button className="btn btn-primary" onClick={validate}>
          {t('common.validate')}
        </button>
      </div>
    </ModalWithBackdrop>
  )
}

const Signature = ({ inputData, formInputs, PartyInvolvedStore: { findName } }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(t('mission.dynform.signature.sign'))

  let involvedPartyField = null
  formInputs.forEach(input => {
    if (input.name === inputData.signForField) {
      involvedPartyField = input
    }
  })

  useEffect(() => {
    if (involvedPartyField.value) {
      setModalTitle(findName(involvedPartyField.value) || t('mission.dynform.signature.sign'))
    }
    // eslint-disable-next-line
  }, [involvedPartyField.value, findName])

  return (
    <div className="form-group row no-gutters">
      <div className="col">
        {!showModal && (
          <button className="btn btn-primary" onClick={() => setShowModal(true)}>
            {inputData.value
              ? t('mission.dynform.signature.modifySign')
              : t('mission.dynform.signature.sign')}
          </button>
        )}
        {showModal && (
          <ModalToSign
            modalTitle={modalTitle}
            onClose={() => setShowModal(false)}
            onValidate={value => inputData.setProperty('value', value)}
          />
        )}
        {inputData.value && (
          <img className="signature-thumbnail ml-4" alt="signature" src={inputData.value} />
        )}
        {inputData.value && (
          <button
            name="resetButton"
            style={{ display: 'none' }}
            onClick={action(() => {
              inputData.value = null
            })}
          ></button>
        )}
      </div>
    </div>
  )
}

export default inject('PartyInvolvedStore')(observer(Signature))
